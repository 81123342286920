import { useEffect,useState } from "react";
import Navigation from "../../components/navigation/navigation";
import './pay.css';

const ChargeModal= (props)=>{
    const [ payUrl,setPayUrl] = useState();
    useEffect(()=>{
        console.log('/=/JOSN出错定位25，:',localStorage.getItem("profile"));
        const appInfo = JSON.parse(localStorage.getItem('appStart'));
        const profile = JSON.parse(localStorage.getItem('profile'));
        //userId=%d&agent=%@&env=PROD&scene=IOS&version=%@&tag=%@&userLevel=%@"
        let tmpUrl = appInfo.payUrl+'?userId='+profile.userId+'&agent=(null)&env=PROD&scene=IOS&version=1.0.2&tag='+appInfo.tag+'&userLevel=50';
        
        //let tmpUrl = 'https://pssr-t01.douquwl88.cn/?userId='+profile.userId+'&agent=(null)&env=PROD&scene=IOS&version=1.0.2&tag='+appInfo.tag+'&userLevel=50';
        //tmpUrl=tmpUrl.replace('http:','https:');
        setPayUrl(tmpUrl)
    },[setPayUrl]);
    return (
        <div className="pay-container">
            <div className="fx-1 pay-modal">
            <Navigation
            onBack = {()=>{
                console.log('clicked');
                props.onClose()
            }}
            theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} style={{top:'0.5rem'}} title={'充值中心'}></Navigation>
                <iframe src={payUrl} className='pay-web-container' sandbox>

                </iframe>
            </div>
        </div>
    )
}

export default ChargeModal;