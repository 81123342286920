import Navigation from "../../components/navigation/navigation";
import './AboutUs.css';

import {CONST_TOP_ADJUST_HEIGHT,appVersion} from '../../service/config'
const AboutUs=()=>{
    return (
        <div className="about-page hide-x" style={{height:'100%',display:'flex',paddingTop:50}}>
            <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} style={{top:(CONST_TOP_ADJUST_HEIGHT+15)}} title={'关于我们'}></Navigation>
            <div className="full-r-w about-cxt fx-box fx-jc-c layout-mt70">
                <div className="about-us theme-font font-size17 font-w5 font-color8 fx-box fx-ai-c fx-jc-c fx-fd-c">
                    <img src="/logo512.png" style={{width:100,height:100,objectFit:'contain'}}></img>
                    <div style={{marginTop:'auto',marginBottom:'0.5rem'}}>

                        FU {appVersion}

                    </div>
                </div>
            </div>
        </div>
    );        
}

export default AboutUs;