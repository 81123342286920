import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./assets/iconfont/iconfont.css";
import "./global.css";
// import reportWebVitals from './reportWebVitals';
import Router from "./routers";
import { Provider } from "react-redux";
import store from "./redux/store";
import VConsole from "vconsole";

import { appStart, requestLocation } from "./service";

import { initAll } from "./init";

// import * as service from './service';

// import {ConnectionContext} from './ConnectionContext';
// import WebIM, { initConn } from './components/easemob/WebIM';
// import { rtm } from './components/agora/rtm';

window.futrue = window.futrue || {};

// const vConsole = new VConsole({ theme: 'dark' });

const initCallbackHander = () => {
  try {
    !window.webkit && (window.webkit = {});
    !window.webkit.messageHandlers && (window.webkit.messageHandlers = {});
    !window.webkit.messageHandlers.callbackHandler &&
      (window.webkit.messageHandlers.callbackHandler = {});
    !window.webkit.messageHandlers.callbackHandler.postMessage &&
      (window.webkit.messageHandlers.callbackHandler.postMessage = () => {});
  } catch (err) {
    console.log(err);
  }
};

initCallbackHander();

requestLocation(store.dispatch);

const profile = localStorage.getItem("profile");
const appStart000 = localStorage.getItem("appStart");
if (!profile || !appStart000) store.dispatch({ type: "NEED_LOGIN" });

appStart().then((appInfo) => {
  if (appInfo.code != 0) {
    store.dispatch({ type: "NEED_LOGIN" });
    throw new Error("需要登录-自定义错误");
  } else {
    console.log("/=/JOSN出错定位2，:", profile);
    store.dispatch({
      type: "INIT_SERVICE",
      promise: initAll(JSON.parse(profile), appInfo, store),
    });
  }
});

window.libpag
  .PAGInit()
  .then((ret) => {
    window.PAG = ret;
  })
  .then(() => {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <Provider store={store}>
        {/* <ConnectionContext.Provider value={cloudInit}> */}
        <Router />
        {/* </ConnectionContext.Provider> */}
      </Provider>
    );
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    // reportWebVitals();
  });
