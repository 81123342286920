import { useEffect, useState, useContext } from "react";
import Navigation from "../../components/navigation/navigation";
import ChatMsgList from "./chat-msg-list";
import { connect } from "react-redux";
import { Popup, Toast, Image } from "react-vant";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import "./Chat.css";
import { sendIcon } from "./icon.js";
import WebIM from "../../components/easemob/WebIM";
import AppDB from "../../components/easemob/db";
import * as service from "../../service";
import { DefaultHeadUrl } from "../../service/images";
import GiftDialog from "../../components/gift-dialog/gift-dialog";
import { EmojiDialog } from "../../components/emoji-dialog";
import GiftTip from "../../components/gift-dialog/gift-tip";
import { giftSend } from "../../service/im";
import { CONST_TOP_ADJUST_HEIGHT } from "../../service/config";
import VConsole from "vconsole";
import MediaAuthDialog from "../../components/meida-auth-dialog/MediaAuthDialog";
import { ChargeContext } from "../Person/ChargeContext.js";
import { DepositDialog } from "../../components/deposit-dialg";

var tipTimer;
const Chat = (props) => {
  // console.log('/=/测试spatch:',props.dispatch);
  const param = useParams();
  const [keytext, setkeytext] = useState("");
  const [animate, setanimate] = useState(true);

  const [message, setMessage] = useState("");
  const [profile, setProfile] = useState();
  const location = useLocation();
  const [target, setTarget] = useState(null); // 目标信息
  const [price, setPrice] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [showGiftDialog, setShowGiftDialog] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [showTip, setShowTip] = useState(true);
  const [tip, setTip] = useState("");
  const [tipAction, setTipAction] = useState("");
  const [showEditNoteName, setShowEditNoteName] = useState(false);
  const [editNoteName, setEditNoteName] = useState("");
  const [showMediaDialog, setShowMAD] = useState(false); // 是否开启权限
  const chargeContext = useContext(ChargeContext);
  const navigate = useNavigate();
  const [person, setPerson] = useState(null); // 模特视频信息
  const [showDeposit, setShowDeposit] = useState(false); // 礼物余额不足

  const conversation =
    (props.conversations &&
      props.conversations.find((e) => {
        return e.name == param.id;
      })) ||
    {};
  // console.log('%c [DEBUG]conversation:%o','color:cyan',conversation);

  const imSend = (fuMsgId, message) => {
    var id = WebIM.conn.getUniqueId();
    console.log("%c webIM id:%d", "color:green", id, target);
    var msg = new WebIM.message("txt", id);
    const ext = {
      time: new Date(),
      postHeader: target.headUrl,
      postName: target.nickName,
      sendHeader: profile.headUrl,
      sendName: profile.nickname,
      messageId: fuMsgId,
    };

    msg.set({
      msg: message,
      data: message,
      from: profile.hxUserName,
      to: param?.id,

      ext,
      success: function (id, serverMsgId) {
        console.log("发送文本消息成功", id, serverMsgId, msg.body);
        AppDB.addMessage(
          Object.assign({}, msg.body, {
            isUnread: 0,
            id: serverMsgId,
            fail: 0,
            success: 0,
            type: "chat",
          })
        )
          .then((ret) => {
            console.log("%c ADD TO DB RETUERN:", "color:green", ret);
          })
          .catch((err) => {
            console.error(err);
          });
        props.dispatch({
          type: "sendMessage",
          payload: {
            body: msg.body,
            meta: {
              type: "text",
              name: param?.id,
              id: param?.id,
              msg: message,
              isUnread: 0,
              serverMsgId,
            },
          },
        });
        setUserBalance(userBalance - price);
      },
      fail: function (err) {
        console.log("发送文本消息失败", err);
      },
    });
    // console.log('%c 调用IM发送.','color:blue');
    WebIM.conn.send(msg.body);
    setMessage("");
  };

  // 点击拨打视频
  const sendVideoFunc = async () => {
    console.log("/=/父组件方法");

    // const allowed = await service
    //   .checkWebMediaPermission()
    //   .then(() => {
    //     return true;
    //   })
    //   .catch(() => {
    //     setShowMAD(true);
    //     return false;
    //   });
    // console.log("/=/湿粉扑：", allowed, target);
    // if (!allowed) return;
    // // onlineStatus在详情时数字，在这儿是字符串
    // if (target?.onlineStatus == "1") {
    //   const liveData = await service.checkLive(
    //     target?.userId + "",
    //     profile?.userId + ""
    //   );
    //   console.log("%c [DEBUG]check live return:%o", "color:orange", liveData);

    //   if (liveData.code == "707" || liveData.code == "709") {
    //     //setNeedDeposit(true);
    //     chargeContext.setPay(true);
    //   } else if (!liveData.conversationId) {
    //     // setFailed(true);
    //     Toast({
    //       message: liveData?.errMsg || "主播正在忙，请稍后尝试",
    //       icon: <Image width={50} src="/static/images/toast-icon.png" />,
    //     });
    //   } else {
    //     // liveData：conversationId-需要传，但是没用。channelName-房间编号

    //     navigate("/one2one", {
    //       replace: true,
    //       state: { person, liveData },
    //     });
    //   }
    // } else {
    //   Toast({
    //     message: "当前用户未在线哦",
    //     icon: <Image width={50} src="/static/images/toast-icon.png" />,
    //   });
    // }
    // -----------------

    const allowed = await service
      .checkWebMediaPermission()
      .then(() => {
        return true;
      })
      .catch(() => {
        setShowMAD(true);
        return false;
      });
    if (!allowed) return;
    console.log("/=/person:", person, target);
    // 首次判断模特是否在线
    if (target?.userStatus == "1") {
      // 后台代码已写死，无法调通
      // const liveData2 = await service.checkLive23(
      //   profile?.userId + "",
      //   person?.userId + "",
      //   "VIDEO_CHAT"
      // );
      // console.log("、=、测试：", liveData2);
      // 创建房间
      const liveData = await service.checkLive2(
        profile?.userId + "",
        person?.userId + "",
        "VIDEO_CHAT"
      );
      console.log("%c [DEBUG]check live return:%o", "color:orange", liveData);

      if (liveData.code == "707" || liveData.code == "709") {
        chargeContext.setPay(true);
      } else if (liveData.code !== 0) {
        Toast({
          message: liveData?.errMsg || "主播正在忙，请稍后尝试",
          icon: <Image width={50} src="/static/images/toast-icon.png" />,
        });
      } else {
        navigate("/one2one", {
          replace: true,
          state: { person, liveData },
        });
      }
    } else {
      Toast({
        message: "当前用户未在线哦",
        icon: <Image width={50} src="/static/images/toast-icon.png" />,
      });
    }
  };

  useEffect(() => {
    console.log("%c [DEBUG]conversation:%o", "color:green", conversation);

    if (!target) {
      // if(location?.state?.target){
      //     console.log('%c model info target:%o','color:green',location?.state?.target);
      //     setTarget(location.state.target);
      //     if(!conversation.image){
      //         conversation.image = location.state.target.headUrl;
      //         conversation.nickName=location.state.target.nickName;
      //     }
      // }
      // else{
      const userId = param.id.split("_").pop();
      service.getModelDetail(userId + "").then((data) => {
        console.log("%c model info:%o", "color:green", data);
        setTarget(data.user);
        if (!conversation.image) {
          conversation.image = data.user.headUrl;
          conversation.nickName = data.user.nickName;
        }
      });
    }

    if (!profile) {
      console.log("chat with:", param);
      console.log('/=/JOSN出错定位17，:',localStorage.getItem("profile"));
      setProfile(JSON.parse(localStorage.getItem("profile")));
    }

    if (conversation && conversation.msgnum > 0) {
      AppDB.readMessage("chat", param.id);
      AppDB.readMessage("to", param.id);
      props.dispatch({ type: "READ_ALL_MESSAGE", payload: { name: param.id } });
    }
    if (!price && profile) {
      service
        .kqueryChatPrice("socialfu_" + profile.userId, param.id)
        .then((data) => {
          setPrice(data.chatPrice);
        });
    }
    if (!userBalance) {
      service.queryMyselfInfo().then((data) => {
        // if(data.code==21){
        //   navigate('/login');
        // }
        setUserBalance(data.userInfo.wealthXz);
      });
    }

    // 从详情页拷贝过来的
    if (!person) {
      const userId = param.id.split("_").pop();
      Promise.resolve()
        .then(() => {
          return service.getPersonDetail(userId);
        })
        .then((data) => {
          if (data?.user?.userType == 0) {
            return service.queryUserInfo(userId);
          } else return data;
        })
        .then((data) => {
          console.log("%c person:%o", "color:blue", data);
          if (data.code == 0) {
            setPerson(data.user);
          } else if (data.code == 21) {
            throw new Error(data.errMsg);
          }
        })
        .catch((err) => {
          console.error(err);
          navigate("/login");
        });
    }
  }, [
    target,
    profile,
    price,
    userBalance,
    setTarget,
    setProfile,
    setPrice,
    setUserBalance,
  ]);

  return (
    <div className="chat-page hide-x fx-box fx-fd-c full-r-h">
      <Navigation
        style={{ top: CONST_TOP_ADJUST_HEIGHT }}
        theme={{ color: "#000", bg: "#fff" }}
        height={"0.5rem"}
        title={target?.nickName ?? "消息"}
      >
        {/* <div onClick={()=>{
                      setShowEditNoteName(true);
                  }} className="font-size13 fx-box fx-ai-c chat-remark font-color2">备注</div> */}
      </Navigation>

      <div
        className="money-hint"
        style={{ marginTop: CONST_TOP_ADJUST_HEIGHT }}
      >
        {param.id != "socialfu_138002" ? "私信:" + price + "金币/条" : ""}
      </div>

      {/* <div className="full-r-w chat-msg-cxt fx-box fx-jc-c layout-mt70 fx-fd-c">
                 <ChatMsgList messages={conversation?.messages} me={profile?.hxUserName}/>
                 <div style={{height:100}}></div>
            </div> */}
      <ChatMsgList
        targetId={target?.userId}
        targetName={target?.nickName}
        myName={profile?.nickname}
        targetHeadUrl={target?.headUrl || DefaultHeadUrl}
        myHeadUrl={profile?.headUrl || DefaultHeadUrl}
        messages={conversation?.messages}
        me={profile?.hxUserName}
        sendVideoFunc={() => {
          sendVideoFunc();
        }}
      />
      {/* 输入框 */}
      <div className="chat-send-msg full-r-w fx-box fx-jc-sb fx-ai-c">
        <div className={"fx-box fx-ai-c " + (animate ? "animate-up" : "")}>
          <input
            className="theme-font font-size13"
            placeholder="输入新消息"
            value={message}
            onChange={(e) => {
              const msg = e.target.value;
              if (msg == "\\opendebug") {
                window.futrueConsole = new VConsole({ theme: "dark" });
                props.dispatch({ type: "OPENDEBUG" });
              }

              setMessage(e.target.value);
            }}
          />
        </div>
        {/* 笑脸 */}
        {/* <div className="msg-icon-touch fx-box fx-jc-c fx-ai-c" onClick = {()=>{
                    setShowEmoji(!showEmoji);
                 }}>
                    <img src="/static/images/emoji.png" className="msg-gift-icon"/>
                 </div> */}
        {/* 礼物 */}
        {/* <div className="msg-icon-touch fx-box fx-jc-c fx-ai-c" onClick = {()=>{
                    setShowGiftDialog(!showGiftDialog);
                 }}>
                    <img src="/static/images/gift-icon.png" className="msg-gift-icon"/>
                 </div> */}
        {/* 发送消息 */}
        {/* <img  onClick={()=>{
                    if(!message)return;
                        service.addMessageByUser(target.userId,message).then(data=>{
                            console.log('%c addMessageByUser return:%o','color:green',data);
                            if(data.code==0) imSend(data.messageId,message)
                            else Toast(data.errMsg);
                        })
                    }} className="send-msg-icon" 
                    src={sendIcon}/> */}
        <div
          className="chat-send-msg_send font-size13"
          onClick={() => {
            if (!message) return;
            service.addMessageByUser(target.userId, message).then((data) => {
              console.log("%c addMessageByUser return:%o", "color:green", data);
              if (data.code == 0) imSend(data.messageId, message);
              else Toast(data.errMsg);
            });
          }}
        >
          发送
        </div>
      </div>
      <div className="chatSendMsgLine2 fx-box fx-jc-sb">
        {/* 笑脸 */}
        <div
          className="msg-icon-touch fx-box fx-jc-c fx-ai-c"
          onClick={() => {
            setShowEmoji(!showEmoji);
          }}
        >
          <img src="/static/images/emoji.png" className="msg-gift-icon" />
        </div>
        {/* 发送视频 */}
        <div
          className="msg-icon-touch fx-box fx-jc-c fx-ai-c"
          onClick={async () => {
            sendVideoFunc();
          }}
        >
          <img src="/static/images/video-icon-chat.png" />
        </div>
        {/* 礼物 */}
        <div
          className="msg-icon-touch fx-box fx-jc-c fx-ai-c"
          onClick={() => {
            setShowGiftDialog(!showGiftDialog);
          }}
        >
          <img src="/static/images/gift-icon.png" className="msg-gift-icon" />
        </div>
      </div>
      {showGiftDialog ? (
        <GiftDialog
          balance={userBalance}
          onGiveGift={(giftId, nmbs, giftName, icon) => {
            // console.log('onGiveGift');

            const userId = param.id.split("_").pop();
            service.giveGift(userId, giftId, nmbs).then((ret) => {
              console.log("[DEBUG]ret:", ret);

              if (ret.code == 709 || ret.code == 701) {
                setShowDeposit(true);
                setShowGiftDialog(!showGiftDialog);
                return;
              }
              setUserBalance(ret.userBalance);
              // addMessageByUser文字消息、giftSend---IM消息
              service
                .addMessageByUser(target.userId, "一条礼物消息")
                .then((data) => {
                  console.log(
                    "%c addMessageByUser return:%o %o",
                    "color:green",
                    data,
                    conversation
                  );
                  if (data.code == 0)
                    giftSend({
                      messageId: data.messageId,
                      giftId,
                      nmbs,
                      postHeader: conversation?.image ?? DefaultHeadUrl,
                      postName: conversation?.nickname ?? "",
                      sendHeader: profile?.headUrl,
                      sendNickname: profile?.nickname,
                      to: param?.id,
                      from: profile?.hxUserName,
                      dispatch: props.dispatch,
                      icon,
                      giftName,
                      price: ret.price,
                    });
                  else Toast(data.errMsg);
                });

              clearTimeout(tipTimer);
              console.log("/=/获得数据:", nmbs, tip);
              // (nmbs || 0) * 1 + (tip.count || 0) * 1,
              const countCount =
                tip && tip.name === giftName ? tip.count + nmbs : nmbs;

              setTip({
                name: giftName,
                count: countCount,
                icon: icon,
              });

              setShowTip(true);
              setTipAction("open");

              tipTimer = setTimeout(() => {
                setTipAction("close");
              }, 3500);
            });
          }}
          onClose={() => {
            setShowGiftDialog(false);
          }}
        />
      ) : (
        <div></div>
      )}
      {showTip ? <GiftTip openTip={tipAction} tip={tip} /> : null}
      {showEmoji ? (
        <EmojiDialog
          message={message}
          onChange={(v) => {
            setMessage(v);
          }}
          onClick={(codePoint) => {
            let msg = String.fromCodePoint(codePoint);
            setMessage(message + msg);
          }}
          onSend={() => {
            service.addMessageByUser(target.userId, message).then((data) => {
              console.log("%c addMessageByUser return:%o", "color:green", data);
              if (data.code == 0) imSend(data.messageId, message);
              else Toast(data.errMsg);
            });
          }}
          onClose={() => {
            setShowEmoji(false);
          }}
        />
      ) : null}
      <Popup
        visible={showEditNoteName}
        onClose={() => {
          setShowEditNoteName(false);
        }}
        style={{ padding: 25, width: "60%", borderRadius: 15 }}
      >
        <div className="font-size13">
          <div className="edit-note-title font-size15 font-w5">
            <div>修改备注</div>
            <div
              style={{ position: "absolute", top: 0, right: 0, padding: 8 }}
              onClick={() => {
                setShowEditNoteName(false);
              }}
            >
              <img
                style={{ width: 24 }}
                src="/static/images/msg-dialog-close.png"
              />
            </div>
          </div>
          <div style={{ marginTop: 15 }} className="fx-box">
            <input
              className="edit-note-name-input"
              type="text"
              placeholder="输入备注名字"
              value={editNoteName}
              onChange={(e) => {
                setEditNoteName(e.target.value);
              }}
            ></input>
          </div>
          <div className="fx-box fx-ai-c fx-jc-c" style={{ marginTop: 15 }}>
            <div
              className="font-size13 font-w5 btn-bg1 font-color3 edit-note-submit fx-box fx-jc-c"
              onClick={() => {
                //props.onSubmit&&props.onSubmit(noteName)
                const modelId = param.id.split("_").pop();
                service.editNoteName(modelId, editNoteName).then((data) => {
                  console.log(data);
                  setShowEditNoteName(false);
                });
              }}
            >
              确定
            </div>
          </div>
        </div>
      </Popup>

      <MediaAuthDialog
        title="必须开启相机/麦克风权限才能进行通话"
        visible={showMediaDialog}
        onClose={() => {
          window?.webkit?.messageHandlers?.callbackHandler?.postMessage(
            "openAudio"
          );
          window?.webkit?.messageHandlers?.callbackHandler?.postMessage(
            "openCamera"
          );
          setShowMAD(false);
        }}
      />
      <Popup
        visible={chargeContext.pay}
        style={{ borderRadius: 10, padding: "0.2rem" }}
        onClose={() => {
          chargeContext.setPay(false);
        }}
      >
        <div className="font-size13">
          <div style={{ padding: "0.1rem" }} className="font-size15">
            余额不足2分钟
          </div>
          <div style={{ padding: "0.1rem" }}>
            <Link
              to="/pay"
              onClick={() => {
                chargeContext.setPay(false);
              }}
            >
              <span>去充值</span>
            </Link>
          </div>
        </div>
      </Popup>
      <DepositDialog
        onClose={() => {
          setShowDeposit(false);
        }}
        visible={showDeposit}
      />
    </div>
  );
};

export default connect(
  (state) => {
    return {
      conversations: state.app.conversations,
    };
  },
  (dispatch) => {
    return { dispatch };
  }
)(Chat);
