
import { useState } from "react";
import './gift-tip.css';
const GiftTip=(props)=>{
    console.log('/=/送出礼物：',props);
      const getAnimate=(selectGift)=>{
            if(selectGift===''){
                return 'gift-shower-bg fx-box fx-ai-c layout-rel';
            }
            if(selectGift==='open'){
                return 'gift-shower-bg fx-box fx-ai-c layout-rel gift-shower-fadein'
            }
            if(selectGift==='close'){
                return 'gift-shower-bg fx-box fx-ai-c layout-rel gift-shower-fadeout';
            }
      }
      const getNumClass=(selectGift)=>{
        if(selectGift===''){
            return 'gift-number fx-box fx-ai-c gift-number-hide';
        }
        if(selectGift==='open'){
            return 'gift-number fx-box fx-ai-c gift-number-show';
        }
        if(selectGift==='close'){
            return 'gift-number fx-box fx-ai-c gift-number-hide';
        }
      }
      const createNumber=(num)=>{
            let jsx=[];
            if(num<10){
                return (<img className='gift-number-img gift-shower-shake' src={'/static/images/number/'+num+'.png'}/>)
            }
            if(num>=10){
                num=num.toString();
                let size=num.split('');
                jsx=size.map((item,index)=>{
                    return (<img 
                        key={'gift-number-id-'+index} 
                        className='gift-number-img gift-shower-shake' 
                        src={'/static/images/number/'+item+'.png'}/>);
                })
                return jsx;
            }
      }
      return(<>
         {
         /**展示赠送礼物jsx**/
        <div className='gift-shower full-r-w layout-fx fx-box fx-ai-c'>
            <div className={getAnimate(props.openTip)}>
              <img className='send-gift-img' src='/static/images/send-gift-bg.png'/>
              <img className='send-gift-icon' src='/static/images/head.png'/>
              <div className='theme-font font-size13 fx-box fx-ai-c send-gift-text font-color3'>
                   您送出了 {props.tip.name}
                   {/* <img className='send-gift-text-i' src={'/static/images/gift/'+(props?.tip?.name??'么么哒')+'.png'}/> */}
                   <img className='send-gift-text-i' src={props.tip.icon} />
              </div>
            </div>
            <div className={getNumClass(props.openTip)}>
                <img className='gift-unit-img' src='/static/images/number/unit.png'/>
                {
                    createNumber(props.tip.count)
                }
            </div>
            </div>
        }
      </>);
}

export default GiftTip;