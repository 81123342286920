import { useEffect, useState, useRef } from "react";
import Navigation from "../../components/navigation/navigation";
import "./MinePayVip.css";
import * as service from "../../service";
import { CONST_TOP_ADJUST_HEIGHT } from '../../service/config';

const MinePayVipList = () => {
  const [blist, setList] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, settotalPage] = useState(null);
  const listRef = useRef();
  useEffect(() => {
    if (!blist) {
      service.getPayVipList(page).then((data) => {
        console.log("%c o2o list:%o", "color:green", data);
        setList(data.data.dataList);
        settotalPage(data.data.totalPage);
      });
    }

    const handleScroll = () => {
      const scrollTop = document.getElementById("MinePayVipList").scrollTop;
      const screenHeight = window.screen.height;
      const offsetHeight =
        document.getElementById("MinePayVipList").scrollHeight;

      console.log(
        "%c scroll event adjust:%d,%d,%d",
        "color:green",
        scrollTop,
        screenHeight,
        offsetHeight
      );

      if (scrollTop >= offsetHeight - screenHeight * 1.5 && page < totalPage) {
        setPage(page++);
        service.getPayVipList(page).then((data) => {
          setList([...blist, ...data.data.dataList]);
        });
      }
    };
    const list = listRef.current;
    list.addEventListener("scroll", handleScroll);
    return () => {
      // list.removeEventListener('touchmove', handleScroll);
      list.removeEventListener("scroll", handleScroll);
    };
  }, [setList, blist]);
  return (
    <div className="pay-container">
      <div className="fx-1">
        <Navigation
          theme={{ color: "#000", bg: "#fff" }}
          height={"0.5rem"}
          style={{top:CONST_TOP_ADJUST_HEIGHT}}
          title={"开通记录"}
        ></Navigation>
        <div
          className="MinePayVipList full-r-w fx-box fx-1 fx-fd-c "
          id="MinePayVipList"
          ref={listRef}
        >
          {blist?.map((item, index) => {
            return (
              <div key={index} className="  MinePayVipListBox font-size15">
                <div className="fx-box fx-jc-sb">
                  <div>
                    <div className="font-size13 mb10">{item.createTime}</div>
                    <div className="mb10">
                      <img
                        className="layout-mr8"
                        src="/static/images/mine-payVip.png"
                      />
                      {item.productName}
                    </div>
                  </div>
                  <div>
                    {item.transactionType === "PRESENT" ? (
                      <div className="font-color2 mb10">系统赠送</div>
                    ) : (
                      <div className="MinePayVipListBoxGreen mb10">
                        交易成功
                      </div>
                    )}

                    <div className="mb10">￥{item.amount}</div>
                  </div>
                </div>
                <div className="MinePayVipListBoxBorder"></div>
                <div className="fx-box fx-jc-sb">
                  <div className="font-size13 font-color5">
                    共1件总计:¥{item.amount}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MinePayVipList;
