import React, { useEffect ,useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import Navigation from "../../../components/navigation/navigation";
import * as service from '../../../service';
import './Notice.css';
const NoticeOne=()=>{
    // const profile = JSON.parse(localStorage.getItem('profile'));
    const params = useParams();
    const [item,setItem] = useState(null);
    useEffect(()=>{
        if(!item){
            service.getNotice(params.id).then(data=>{
                console.log(data);
                setItem(data.noticeInfo);
            })
        }
    })
    return (
        <div className="comment-page hide-x fx-box fx-fd-c">
           <Navigation style={{paddingTop:'0.3rem'}} theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'官方公告'}></Navigation>
           <div className='full-r-w fx-box fx-1 fx-fd-c' style={{paddingTop:'0.8rem'}}>
                <div className='commentItem fx-box fx-jc-sb fx-ai-c'>
                    <div className='fx-box fx-1 fx-fd-c'>
                        <div className='black-userid fx-box fx-fd-r  fx-jc-sb'>
                            <div className='font-size15 fx-1'>{item?.title}</div>
                            
                        </div>
                        
                        <div className='font-size13'>
                                {item?.content}
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default NoticeOne;