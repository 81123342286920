import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SEARCH_ICON } from "../../service/images";
import "./nav-bar.css";

const NavBar = function (props) {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(props.activeTab || "0");

  //   let {bars,setbars}=useState(props.activity);
  const handleClick=()=>{
    console.log('/=/点击切换');
    const res=!props.showLiveList
    props.setshowLiveList(res)
    props.getModelList(res)
  }
  return (
    <div className="nav-bar fx-box" style={{ ...props.style }}>
      <div className="nav-bar-container fx-box">
        {props.activity.map((item, idx) => {
          return (
            <div
              key={"nvk_" + idx}
              onClick={() => {
                if (activeKey != idx) {
                  setActiveKey(idx);
                  props.reload && props.reload(idx);
                }
              }}
              className="navItem"
            >
              <div
                className={
                  idx == activeKey
                    ? "font-size18 lh26 font-w5"
                    : "font-size15 font-w4 lh27 font-color5"
                }
              >
                {item.title || item.name}
              </div>
              <div className="lineBox fx-box fx-jc-c">
                {idx == activeKey ? (
                  <div className="navLine"></div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          );
        })}
        {/* 分屏、推荐页才有 */}

        {props.activeTab === 0 ? (
          <div
            className="nav-bar-search2"
            onClick={() =>handleClick() }
          >
            <img
            width={"100%"}
              src={
                props.showLiveList
                  ? "/static/images/live-list2.png"
                  : "/static/images/live-list.png"
              }
            />
          </div>
        ) : (
          ""
        )}
        {props.showSearch !== false ? (
          <div
            className="nav-bar-search"
            onClick={() => {
              navigate("/search");
            }}
          >
            {/* <i className='iconfont icon-sousuo search-icon' style={{fontSize:24}}/> */}
            <img src={SEARCH_ICON} className="search-icon" />
            {/* <svg className="search-icon" 
                            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1782">
                            <path d="M938.64603 886.273219l-173.071777-173.074847c53.665247-63.987337 86.075401-146.400325 86.075401-236.446154 0-203.406666-164.895561-368.298134-368.301204-368.298134-203.409736 0-368.302227 164.892491-368.302227 368.298134 0 203.409736 164.892491 368.302227 368.302227 368.302227 89.246627 0 171.055864-31.767518 234.798631-84.579327l173.148525 173.148525c1.576915 1.576915 8.15575-2.443655 14.6957-8.979512l23.675212-23.675212C936.205445 894.428969 940.222945 887.850134 938.64603 886.273219zM483.347426 778.093381c-166.425404 0-301.338093-134.912689-301.338093-301.338093s134.912689-301.338093 301.338093-301.338093S784.685519 310.329884 784.685519 476.755288 649.773853 778.093381 483.347426 778.093381z" p-id="1783"></path></svg> */}
          </div>
        ) : null}
        {props.right}
      </div>
    </div>
  );
};

export default NavBar;
