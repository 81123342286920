import { useEffect, useState, useRef } from "react";
import Navigation from "../../components/navigation/navigation";

import { VERSION } from "../../service/config";
import { useParams, useNavigate } from "react-router-dom";
import { CONST_TOP_ADJUST_HEIGHT } from "../../service/config";
import UserMsgItem2 from "../../components/user-msg-item/user-msg-item-detail";
import * as service from "../../service";

const EvaluateList = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [commentList, setCommentList] = useState(null);
  const list = () => {
    Promise.resolve()
      .then(() => {
        return service.queryModelComment(userId);
      })
      .then((data) => {
        console.log("%c [debug]commentList:%o", "color:blue", data);
        if (data.code == "21") throw new Error("login");
        setCommentList(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // useEffect(() => {
  //   if (!commentList) {
  //     list();
  //   }
  // }, [commentList, commentList]);
  return (
    <div className="pay-container">
      <div className="fx-1">
        <Navigation
          theme={{ color: "#000", bg: "#fff" }}
          height={"0.5rem"}
          style={{ paddingTop: CONST_TOP_ADJUST_HEIGHT }}
          title={"用户评价"}
          onBack={() => {
            navigate("/person/" + userId, {
              replace: true,
              state: { from: "EvaluateList" },
            });
          }}
        ></Navigation>
        <div
          style={{
            margin: "0 auto",
            marginTop: "0.9rem",
            width: "3.35rem",
            overflowY: "scroll",
            height: "80vh",
          }}
        >
          <UserMsgItem2
            msgs={commentList?.evaluationList ?? []}
            userId={userId}

          />
        </div>
      </div>
    </div>
  );
};

export default EvaluateList;
