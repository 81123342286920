import { useEffect,useState } from "react";
import Navigation from "../../components/navigation/navigation";
import './pay.css';
import {VERSION} from '../../service/config';

const Pay= ()=>{
    const [ payUrl,setPayUrl] = useState();
    useEffect(()=>{
        console.log('/=/JOSN出错定位24，:',localStorage.getItem("profile"));
        const appInfo = JSON.parse(localStorage.getItem('appStart'));
        const profile = JSON.parse(localStorage.getItem('profile'));
        //userId=%d&agent=%@&env=PROD&scene=IOS&version=%@&tag=%@&userLevel=%@"
        let tmpUrl = appInfo.payUrl+'?userId='+profile.userId+'&agent=(null)&env=PROD&scene=IOS&version='+VERSION+'&tag='+appInfo.tag+'&userLevel='+appInfo.payUserLeven+'&fussr=true';
        
        //let tmpUrl = 'https://pssr-t01.douquwl88.cn/?userId='+profile.userId+'&agent=(null)&env=PROD&scene=IOS&version=1.0.2&tag='+appInfo.tag+'&userLevel=50';
        //tmpUrl=tmpUrl.replace('http:','https:');
        setPayUrl(tmpUrl)
    },[setPayUrl]);
    return (
        <div className="pay-container">
            <div className="fx-1">
                <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} style={{top:'0.4rem'}} title={'充值中心'}></Navigation>
                <iframe src={payUrl} className='pay-web-container'>

                </iframe>
            </div>
        </div>
    )
}

export default Pay;