import React, { useEffect ,useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import Navigation from "../../../components/navigation/navigation";
import * as service from '../../../service';
import './Hint.css';
const HintOne=()=>{
    // const profile = JSON.parse(localStorage.getItem('profile'));
    const params = useParams();
    const location = useLocation();
    // const item = location.state.item;
    const [item,setItem]=useState();
    useEffect(()=>{
        !item&&service.getHint(params.id).then(data=>{
            console.log(data);
            setItem(data.noticeInfo);
        })
    },[item])
    return (
        <div className="comment-page hide-x fx-box fx-fd-c">
           <Navigation theme={{color:'#000',bg:'#fff'}} style={{top:0,paddingTop:30}} height={'0.5rem'} title={item?.title}></Navigation>
           <div className='full-r-w fx-box fx-1 fx-fd-c' style={{paddingTop:'0.8rem'}}>
                  <div className='commentItem fx-box fx-jc-sb fx-ai-c'>
                        <div className='fx-box fx-1 fx-fd-c'>
                            
                            <div className='black-userid fx-box fx-fd-r  fx-jc-sb'>
                                <div className='font-size15 fx-1'>{item?.title}</div>
                               
                            </div>
                            
                            <div className='font-size13'>
                                    {item?.content}
                            </div>
                           
                        </div>
                       
                    </div>
           </div>
           
        </div>
      );
}

export default HintOne;