import React, { useState, useEffect,useRef } from "react";
import "./live-list-item.css";

import * as service from "../../service";

const cssStatus = (code) => {
  const codeCss = {
    0: "off-line-rd",
    1: "on-line-rd",
    2: "chatting-rd",
    3: "active-rd",
    4: "dnd-rd",
  };
  return codeCss[code];
};

const LiveListItem = function (props) {
  let { onClickItem } = props;
  console.log('/=/测试111');


  useEffect(() => {
console.log('/=/测试2');
  }, []);

  return (
    <div className="liveList liveList2" id="live-list">
      {props.liveList?.map((item, idx) => {
        return (
          <div
            key={idx}
            className="liveListItem"
            onClick={() => {
              onClickItem(item);
            }}
          >
            <img src={service.personHead(item)} />
            <div className="liveListItemStar">
              {
      
      Array.from({length: item.startLevel}, (_, i) => i).map(item=>{
               return   <img
               key={item}
                    src={"/static/images/live_list_star.png" }
                  />
                })
              }
            </div>
            <div className="on-line fx-box fx-ai-c fx-jc-e">
              <span className={cssStatus(item.status)}></span>
              <span className="font-size10 font-color3 live-status-text">
                {service.statusText(item.status)}
              </span>
            </div>
            <div className="live-item-bottom">
              <div className="user-info theme-font">
                <div className="font-w5 font-color3 font-size13 textHidden">
                  {item.name}
                  {item?.distanceDesp ? " | " + item?.distanceDesp : ""}
                </div>
                <div className="font-w4 font-color4 font-size10 live-sign textHidden">
                  {item.sign}
                </div>
              </div>
              {/* theme-font  fx-box fx-ai-c */}
              <div className="chat-price  font-size10">
                {/* <div  className="liveListItemVipBg font-size8" >
                  <span>{item.vipDiscountPriceDesp.replace('分钟','分')}</span>
                  <img src={item?.effects.SMALL_BACKGROUND_EFFECT} alt="" />
                </div>
                <div className="font-size10 liveListItemPosition">
                  <span className="font-color3  font-w5">
                    {item.vedioPrice}
                  </span>
                  <span className="font-color3 font-w4">金币/分钟</span>
                </div> */}
                <div  className="liveListItemVipBg font-size8" >
                  <span>{item.vipDiscountPriceDesp.replace('分钟','分')}</span>
                  <img src={item?.effects.SMALL_BACKGROUND_EFFECT} alt="" />
                </div>
                <div className="font-size10 text-center">
                  <span className="font-color3  font-w5">
                    {item.vedioPrice}
                  </span>
                  <span className="font-color3 font-w4">金币/分钟</span>
                </div> 
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LiveListItem;
