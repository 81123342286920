import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Overlay } from 'react-vant';
import Navigation from '../../components/navigation/navigation';
import { CONST_TOP_ADJUST_HEIGHT } from '../../service/config';
import Live from './Live';
import './Live.css';

export default (props)=>{
    console.log('/=/测试2');
    const o2ohome=localStorage.getItem('o2ohome');
    const navigate = useNavigate();
    const [showFirst,setShowFirst] = useState(!localStorage.getItem('first_live'));
    // const [showFirst,setShowFirst] = useState(true);
 

    const closeFirst=()=>{
        localStorage.setItem('first_live','true');
        setShowFirst(false);
    }

   

    return (
        <div className='fx-box fx-fd-c'>

            <Navigation style={{marginTop:45,position:'relative'}} title='视频聊天' right={
                    <div className='font-size12 btn-bg1 font-color3 live-home-btn' 
                        style={{borderRadius:15}} onClick={()=>{
                            localStorage.setItem('o2ohome',o2ohome=='true'?'false':'true');
                            navigate('/app')
                        }}>
                        {o2ohome=='true'?'取消设置':'设为首页'}
                    </div>
            }/>
            <Live style={{marginTop:0}} navBarStyle={{paddingTop:0}}></Live>
            <Overlay visible={showFirst} onClose={closeFirst} style={{zIndex:1000}}>
                    <div className='font-size12 btn-bg1 font-color3 live-home-btn-cover'>
                            设为首页
                    </div>
                    <div className='first_live_icon'>
                        <img src="/static/images/first_2.png"/>
                    </div>
                    <div className='first_live_ok_btn' onClick={
                        closeFirst
                    }>
                        我知道了
                    </div>
            </Overlay>
        </div>
    )
}