import { useEffect, useState } from "react";
import Navigation from "../../components/navigation/navigation";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./mineVipManage.css";
import { PasswordInput, Toast } from "react-vant";
import * as service from "../../service";
import { CONST_TOP_ADJUST_HEIGHT } from "../../service/config";

const MineVipManagePwd = () => {
  let theme = {
    color: "#FFF",
    bg: "linear-gradient(138.47deg, #FF6161 -2.56%, #FD8F8F 76.52%)",
  };

  const [searchParams] = useSearchParams();

  // 使用 URLSearchParams 提供的方法来获取参数
  const privateMode = searchParams.get("privateMode");
  const navigate = useNavigate();
  const [myInfo, setMyInfo] = useState(null);
  const [showHiddenDialog2, setShowHiddenDialog2] = useState(false); // 打开Svip弹窗
  // const appStart = JSON.parse(localStorage.getItem("appStart"));
  const [showPage1, setShowPage1] = useState(true); // 是否显示组件
  const [pwd1, setpwd1] = useState(null); // 密码
  const [pwd2, setpwd2] = useState(null); // 密码
  //   /user/permission/checkPrivateMode.json  纯净模式
  useEffect(() => {
    console.log("/数据改变：");
  }, []); // 只执行一次：空数组[]，模拟 componentDidMount 这个生命周期

  return (
    <div className="mineVipManage mineVipManagePrivateMode">
      <Navigation
      style={{ top: CONST_TOP_ADJUST_HEIGHT }}
        theme={theme}
        height={"0.5rem"}
        title={"输入密码"}
      ></Navigation>
      <div className={showPage1 ? "" : "mineVipManagePrivateModeShow"}>
        <p className="font-size15 text-center">独立密码</p>
        <p className="font-size10  text-center mineVipManagePrivateModeShowP2">
          请输入第一次使用设置的独立密码
        </p>
        <div className="mineVipManagePrivateModeShowDiv">
          <PasswordInput
            type="number"
            gutter={10}
            length={4}
            onSubmit={(val) => setpwd1(val)}
          />
        </div>
        <div
          className="mineVipManageBtn"
          onClick={() => {
            if (!pwd1 || pwd1.length !== 4) {
              Toast.info("请输入完整四位数字密码");
              return;
            }
            const obj = {
              pass: pwd1,
              value: privateMode === "true" ? "OFF" : "ON",
              isReset: false,
            };
            service.setPrivateMode(obj).then((data) => {
              navigate("/app");
            });
          }}
        >
          {privateMode === "true" ? "关闭" : "开启"}纯净模式
        </div>
      </div>
    </div>
  );
};

export default MineVipManagePwd;
