import "./chat-msg-list.css";
import React, { Profiler, PureComponent, useEffect, useRef } from "react";
import { DefaultHeadUrl } from "../../service/images";
import { Link } from "react-router-dom";
import moment from "moment";
import { getFuTimeString } from "../../service";
import { GiftDict } from "../../service/config";

const getGiftName = (giftId) => {
  const gift = GiftDict[giftId];
  return gift && gift.name;
};
const getGiftPrice = (giftId) => {
  const gift = GiftDict[giftId];
  return gift && gift.price;
};

const getGiftIcon = (giftId) => {
  const gift = GiftDict[giftId];
  return gift && gift.icon;
};

const ChatMsgList = (props) => {
  const msgListRef = useRef();
  console.log("/=/聊天记录：", props?.messages);
  const videoChat = (msg) => {
    console.log("/=/点击事件：", msg);
    if (msg.ext.liveType) {
      props.sendVideoFunc();
    }
  };
  useEffect(() => {
    // console.log(msgListRef?.current?.scrollTop,
    //     msgListRef?.current?.scrollHeight,
    //     msgListRef?.current?.offsetHeight
    //     );
    setTimeout(() => {
      msgListRef &&
        msgListRef.current &&
        (msgListRef.current.scrollTop =
          msgListRef.current.scrollHeight - msgListRef.current.offsetHeight);
    }, 100);
  });
  return (
    <div
      className="chat-msg-container fx-box fx-fd-c fx-ai-c theme-font font-size13 full-r-w fx-1"
      ref={msgListRef}
    >
      {props?.messages?.map((msg, idx) => {
        // console.log(msg);

        return msg?.ext?.giftId ? (
          msg.from == props.me ? (
            <div key={"msg_" + idx}>
              <div className="msg-time fx-box fx-jc-c ">
                {getFuTimeString(msg.time * 1)}
              </div>
              <div className="chat-msg-item chat-other-msg fx-box fx-jc-e fx-ai-c">
                <div
                  className="chat-msg-content-g font-size13 font-color3 fx-box fx-fd-r"
                  style={{ marginRight: 8 }}
                >
                  <div>
                    {/* <img src={getGiftIcon(msg?.ext?.giftId)} className='msg-gift'/> */}
                    <img src={msg?.ext?.icon} className="msg-gift" />
                  </div>
                  <div>
                    <div>
                      送出{msg?.ext?.nmbs}个
                      {/* {getGiftName(msg?.ext?.giftId)} */}
                      {msg?.ext?.giftName}
                    </div>
                    <div>
                      价值
                      {/* {getGiftPrice(msg?.ext?.giftId)*msg?.ext?.nmbs} */}
                      {msg?.ext?.price * msg?.ext?.nmbs}
                      金币
                    </div>
                  </div>
                </div>

                <div>
                  <Link to="/app/mine">
                    <img
                      src={props.myHeadUrl}
                      className="chat-msg-line-head-icon"
                    />
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div key={"msg_" + idx}>
              <div className="msg-time fx-box fx-jc-c ">
                {getFuTimeString(msg.time * 1)}
              </div>
              <div
                key={"msg_" + idx}
                className="chat-msg-item chat-other-msg fx-box fx-jc-s fx-ai-c"
              >
                <div>
                  <Link to={"/person/" + props.targetId}>
                    <img
                      src={props.targetHeadUrl}
                      className="chat-msg-line-head-icon"
                    />
                  </Link>
                </div>

                <div
                  className="chat-msg-content-g font-w5 font-color3"
                  style={{ marginLeft: 8 }}
                >
                  <div>
                    <div>
                      收到{msg?.ext?.nmbs}个{getGiftName(msg?.ext?.giftId)}
                    </div>
                    <div>
                      价值{getGiftPrice(msg?.ext?.giftId) * msg?.ext?.nmbs}金币
                    </div>
                  </div>
                  <div>
                    <img
                      src={getGiftIcon(msg?.ext?.giftId)}
                      className="msg-gift"
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : msg.from == props.me ? (
          // 文字消息---自己发送的、如果视频，可回拨
          <div key={"msg_" + idx}>
            <div className="msg-time fx-box fx-jc-c ">
              {getFuTimeString(msg.time * 1)}
            </div>
            <div className="chat-msg-item chat-other-msg fx-box fx-jc-e fx-ai-c">
              <div style={{ marginRight: 8 }}>
                <div
                  className="chat-msg-content-o font-w5 font-color3"
                  onClick={() => {
                    videoChat(msg);
                  }}
                >
                  {msg.ext.liveType ? (
                    <img
                      src="/static/images/video-icon-chat.png"
                      className="chat-msg-content-o-video"
                    />
                  ) : (
                    ""
                  )}
                  {msg.data || msg.msg}
                </div>
              </div>
              <div>
                <Link to="/app/mine">
                  <img
                    src={props.myHeadUrl}
                    className="chat-msg-line-head-icon"
                  />
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div key={"msg_" + idx}>
            <div className="msg-time fx-box fx-jc-c ">
              {getFuTimeString(msg.time * 1)}
            </div>
            <div
              key={"msg_" + idx}
              className="chat-msg-item chat-other-msg fx-box fx-jc-s fx-ai-c"
            >
              <div>
                <Link to={"/person/" + props.targetId}>
                  <img
                    src={props.targetHeadUrl}
                    className="chat-msg-line-head-icon"
                  />
                </Link>
              </div>
              <div style={{ marginLeft: 8 }}>
                <div>{props.targetName}</div>
                <div
                  className="chat-msg-content-s font-w5 font-color3"
                  onClick={() => {
                    videoChat(msg);
                  }}
                >
                  {msg.ext.liveType ? (
                    <img
                      src="/static/images/video-chat-icon.png"
                      className="chat-msg-content-o-video-from"
                    />
                  ) : (
                    ""
                  )}
                  {msg.data || msg.msg}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatMsgList;

// export default class ChatMsgList extends PureComponent{
//     //增加滚动高度计算 如果此处处理不行 就要把父组件[改装区域]容器这块进行改装成class 并加入这段code 还有下方的ref
//     componentDidUpdate(prevProps, prevState, prevScrollHeight) {
//         console.log('prevScrollHeight===>',prevScrollHeight);
//         let scrollHeight=this.rootNode.scrollHeight;
//         let clientHeight=this.rootNode.scrollHeight;
//         // 将滚动高度加上一个变化后的页面高度
//         if(prevScrollHeight){
//             this.rootNode.scrollTop = prevScrollHeight+scrollHeight+clientHeight;
//         }else{
//             this.rootNode.scrollTop = scrollHeight+clientHeight;
//         }
//     }
//     render(){
//         return (
//                 <div className="chat-msg-container fx-box fx-fd-c fx-ai-c theme-font font-size13 full-r-w fx-1"  ref={(node) => (this.rootNode = node)}>
//                   {this.props?.messages?.map((msg,idx)=>{
//                       return (
//                         msg.from ==this.props.me?
//                       <div key={'msg_'+idx} className="chat-msg-item chat-other-msg fx-box fx-jc-e fx-ai-c">
//                           <div className="chat-msg-content-o font-w5 font-color3">
//                               {msg.data}
//                           </div>
//                       </div>:
//                       <div key={'msg_'+idx} className="chat-msg-item chat-other-msg fx-box fx-jc-s fx-ai-c">
//                         <div className="chat-msg-content-s font-w5 font-color3">
//                             {msg.data}
//                         </div>
//                     </div>
//                       )
//                   })}

//         </div>);
//     }
// }
