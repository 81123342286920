import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Mine.css";
import { Switch, Dialog, Overlay, Popup } from "react-vant";
import * as service from "../../service";
import { UserLevelBadge } from "../../components/level-badge";
import store from "../../redux/store";

const userMenuList = [
  {
    icon: "iconfont icon-icon",
    image: require("../../assets/images/mine/mine-icon-rank.png"),
    title: "排行榜",
    menuType: "icon",
    path: "/ranking",
  },
  {
    icon: "iconfont icon-biaoqing",
    image: require("../../assets/images/mine/mine-icon-dnd.png"),
    title: "勿扰模式",
    menuType: "switch",
  },
  // {
  //   icon: "iconfont icon-biaoqing",
  //   image: require("../../assets/images/mine/mine-icon-hidden.png"),
  //   title: "隐身模式",
  //   menuType: "switch",
  // },
  {
    icon: "iconfont icon-qianbao",
    image: require("../../assets/images/mine/mine-icon-vip.png"),
    title: "会员权限",
    menuType: "icon",
    path: "/mineVipManage",
  },
  {
    icon: "iconfont icon-qianbao",
    image: require("../../assets/images/mine/mine-icon-wallet.png"),
    title: "我的钱包",
    menuType: "icon",
    path: "/wallet",
  },

  //   {
  //         icon:'iconfont icon-dongtai',
  //         title:'我的动态',
  //         menuType:'icon'
  //   },
  {
    icon: "iconfont icon-shenfenzheng",
    image: require("../../assets/images/mine/mine-icon-starcert.png"),
    title: "女神认证",
    menuType: "icon",
    path: "/star-certificate",
  },
  {
    icon: "iconfont icon-xitongquanxian",
    image: require("../../assets/images/mine/mine-icon-auth.png"),
    title: "权限管理",
    menuType: "icon",
    path: "/authman",
  },
  {
    icon: "iconfont icon-heimingdan",
    image: require("../../assets/images/mine/mine-icon-blacklist.png"),
    title: "黑名单",
    menuType: "icon",
    path: "/blacklist",
  },
  //   {
  //         icon:'iconfont icon-qinmifu',
  //         title:'我的亲密榜',
  //         menuType:'icon'
  //   },
  //   {
  //         icon:'iconfont icon-shengriliwu',
  //         title:'收入的礼物',
  //         menuType:'icon'
  //   },
  //   {
  //         icon:'iconfont icon-lianxiren',
  //         title:'重要联系人',
  //         menuType:'icon'
  //   },
  //   {
  //         icon:'iconfont icon-magic-',
  //         title:'美颜设置',
  //         menuType:'icon'
  //   },
  {
    icon: "iconfont icon-kefu",
    image: require("../../assets/images/mine/mine-icon-cs.png"),
    title: "客服中心",
    menuType: "icon",
    path: "/chat/socialfu_138002",
  },
  {
    icon: "iconfont icon-shezhi",
    image: require("../../assets/images/mine/mine-icon-setting.png"),
    title: "设置",
    menuType: "icon",
    path: "/setting",
  },
  //   {
  //         icon:'iconfont icon-shezhi',
  //         image:require('../../assets/images/mine/mine-icon-setting.png'),
  //         title:'测试',
  //         menuType:'icon',
  //         path:'/test'

  //   }
];

const Mine = (props) => {
  console.log("/=/不断渲染");
  const nickNameRef = useRef();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  const [notDisturb, setNotDisturb] = useState(null);
  const [hidden, setHidden] = useState(null);

  const [myInfo, setMyInfo] = useState(null);
  const [showHiddenDialog, setShowHiddenDialog] = useState(false);
  const hiddenModeNoticed =
    localStorage.getItem("hidden_mode_noticed") == "true";
  const appStart000 = localStorage.getItem("appStart") || "{}";
  console.log("/=/JOSN出错定位12，:", appStart000);
  const appStart = JSON.parse(appStart000);
  const [showCertDialog, setShowCertDialog] = useState(false);
  useEffect(() => {
    // console.log('%c [debug] 勿扰模式:','color:green',notDisturb,appStart);
    if (notDisturb == null) {
      setNotDisturb(appStart.appInfo.notDisturb);
    }
    if (!myInfo) {
      service.queryMyselfInfo().then((data) => {
        console.log("....myInfo:", data);

        if (data.code == "21" || data.code == "98") {
          console.log("/=/应该退出登录");
          store.dispatch({ type: "IM_LOGIN", payload: undefined });
          localStorage.setItem("appStart", "");
          navigate("/login");
          return;
        }
        setMyInfo(data);
        setProfile(data?.user)
        localStorage.setItem('profile',JSON.stringify(data?.user)); // 重新设置，第一处在登录页
                                            
        // setHidden(data?.user?.isHidden);
        // 请求基本信息
        /**
         *vipStatus: Effected(1), Expired(2), None(3)
        "type": "VIP", //VIP(3), SUPER_VIP(4);
        "effects": {
"NICK_EFFECT": "0xFFFF4444",
"PROMPT_EFFECT":飘屏 "http://img.douquwl88.top/test/2024/1/3/B8B2BD14-F21F-A3FE-6F27-6D5CCABFB17F.pag",
"HEADER_EFFECT":头像皇冠 "http://img.douquwl88.top/test/2024/1/5/826800E9-5B11-1817-A7CB-31429E4B7BA4.pag",
"SMALL_BACKGROUND_EFFECT":小图时vip价格显示 "http://img.douquwl88.top/test/2024/1/10/2CB760BB-DFC5-DEB7-431C-301DE9801249.png",
"BACKGROUND_EFFECT": 大图vip价格显示"http://img.douquwl88.top/test/2024/1/15/98432DBF-73DA-49FB-8230-73CCB008D5D7.png"
}
isPrivateMode:纯净模式
isHideScreenPrompt：飘屏
         */
        //         // 设置保存，供会员权限页面调用
        // localStorage.setItem("myInfo", JSON.stringify(data));
        if (data.user?.vipStatus === "Effected") {
          // 没过期渲染pag
          pagFunc(data.user.vipProductInfo.effects.HEADER_EFFECT, "headImg");
          // pagFunc(data.user.vipProductInfo.effects.PROMPT_EFFECT, "headImg2");
          const colorString =
            data.user.vipProductInfo.effects.NICK_EFFECT.replace(
              /0xFF/g,
              "#"
            ).split(",");

          if (colorString.length == 1) {
            nickNameRef.current.style.color = colorString[0];
          } else if (colorString.length == 4) {
            nickNameRef.current.style.background = `linear-gradient(240deg, ${colorString[0]} 19.9%, ${colorString[1]} 42.28%, ${colorString[2]} 65.23%, ${colorString[3]} 91.63%)`;
            nickNameRef.current.style.backgroundClip = "text";
            nickNameRef.current.style["-webkit-background-clip"] = "text";
            nickNameRef.current.style["-webkit-text-fill-color"] =
              "transparent";
          }
        }
      });
    }
  }, [myInfo, setMyInfo, notDisturb,setProfile]);

  const pagFunc = async (llpagUrl, name) => {
    // const canvas = document.getElementById(name);
    // // 3、获取绘制上下文
    // const ctx = canvas.getContext("2d");
    // // 4、绘制图形
    // // moveTo 、 lineTo 和 stroke 方法暂时可以不用管，它们的作用是绘制图形，这些方法在后面会讲到~
    // ctx.moveTo(0, 0) // 起点坐标 (x, y)
    // ctx.lineTo(89, 89) // 终点坐标 (x, y)
    // ctx.stroke() // 将起点和终点连接起来
    // return
    llpagUrl = llpagUrl.replace("http:", "https:");
    console.log("/=/:", llpagUrl);
    const llbuffer = await fetch(llpagUrl).then((response) =>
      response.arrayBuffer()
    );
    const llpagFile = await window.PAG.PAGFile.load(llbuffer);
    const llCanvas = document.getElementById(name);
    if (llCanvas) {
      llCanvas.width = llpagFile.width();
      llCanvas.height = llpagFile.height();

      window.PAG.PAGView.init(llpagFile, llCanvas).then((v) => {
        v.setRepeatCount(0);
        v.play();
      });
    }
  };
  const renderSwitch = (item) => {
    if (item.title == "勿扰模式") {
      return (
        <Switch
          defaultChecked
          checked={notDisturb != "0"}
          style={{ marginRight: 8 }}
          activeColor="#FC66FF"
          inactiveColor="#ffffff"
          onChange={(checked) => {
            Dialog.confirm({
              title: "提醒",
              message: checked ? "是否开启勿扰模式？" : "是否关闭勿扰模式?",
            }).then(() => {
              service.switchDND(checked).then((ret) => {
                console.log(
                  "%c[DEBUG]switch disturb appstart:%o",
                  "color:green",
                  appStart,
                  checked
                );
                appStart.appInfo.notDisturb = checked ? "1" : "0";
                localStorage.setItem("appStart", JSON.stringify(appStart));
                setNotDisturb(appStart.appInfo.notDisturb);
              });
            });
          }}
        />
      );
    }
    if (item.title == "隐身模式") {
      return (
        <Switch
          defaultChecked
          checked={hidden}
          style={{ marginRight: 8 }}
          activeColor="#FC66FF"
          inactiveColor="#ffffff"
          onChange={(checked) => {
            if (localStorage.getItem("hidden_noticed") != "true") {
              setShowHiddenDialog(true);
            }

            // Dialog.confirm({
            //   title: '提醒',
            //   message: '是否切换隐身状态？',
            // }).then(() => {
            service.switchHiddenMode(checked ? "ON" : "OFF").then((ret) => {
              setHidden(checked);
            });
            // });
          }}
        />
      );
    }
  };

  return (
    <div className="mine-page">
      <div className="mine-header">
        <div className="mine-top fx-box fx-ai-c">
          <div className="avatars layout-rel">
            <img
              alt=""
              className="user-avatar"
              src={profile.headUrl || "/static/images/head.png"}
            />
            <div className="layout-abs theme-font font-size10 font-color3 text-center">
              主页
            </div>
          </div>
          {myInfo?.user?.vipStatus === "Effected" ? (
            <canvas className="vipheaderClass" id="headImg"></canvas>
          ) : (
            ""
          )}
          <div className="fx-1 fx-box layout-ml16 fx-ai-c fx-jc-sb">
            <div>
              <div className="nickname theme-font fx-box fx-ai-c">
                <span ref={nickNameRef}> {profile.nickname}</span>
                <UserLevelBadge
                  level={myInfo?.userInfo?.userLeven ?? 1}
                ></UserLevelBadge>
                {myInfo?.user.vipStatus === "Effected" ? (
                  <img src={myInfo?.user.vipProductInfo.icon} height="16" />
                ) : (
                  ""
                )}
              </div>
              <div className="user-id">
                ID: {profile.userId}
                <span className="id-icon">
                  <i className="iconfont icon-book"></i>
                </span>
              </div>
            </div>
            <div
              className="mine-edit fx-box fx-ai-c fx-jc-sb"
              onClick={() => {
                navigate("/personedit");
              }}
            >
              <span>编辑</span>
              <i className="iconfont icon-weibiaoti--"></i>
            </div>
          </div>
        </div>

        <div className="mine-center theme-font fx-box">
          <div className="centerItem fx-1 fx-box fx-fd-c fx-ai-c">
            <Link to="/address">
              <div className="font-size18 font-color1">
                {myInfo && myInfo?.userInfo?.myAttentions}
              </div>
              <div className="font-color4 font-size11">关注</div>
            </Link>
          </div>

          <div className="centerItem fx-1 fx-box fx-fd-c fx-ai-c">
            <div className="font-size18">
              {myInfo && myInfo.userInfo.wealthXz}
            </div>
            <div className="font-color4 font-size11">金币</div>
          </div>
          <div className="centerItem fx-1 fx-box fx-fd-c fx-ai-c">
            <div className="font-size18">
              {myInfo && myInfo.userInfo.wealthXp}
            </div>
            <div className="font-color4 font-size11">钻石</div>
          </div>
        </div>
      </div>
      <div className="mine-vipClass">
        {myInfo?.user.vipProductInfo.type === "SUPER_VIP" ? (
          <img src="/static/images/mine-vipbgText2.png" />
        ) : (
          <img src="/static/images/mine-vipbgText.png" />
        )}

        <p>{myInfo?.user.vipPrompt}</p>
        <div
          className="mine-vipClassBtn"
          onClick={() => {
            navigate("/minePayVip");
          }}
        >
          {myInfo?.user.vipStatus === "Effected" ? "立即续费" : "立即开通"}
        </div>
      </div>
      <div className="mine-menu fx-box fx-ai-c fx-jc-sa">
        <div
          className="fx-1 fx-box fx-fd-c fx-ai-c"
          onClick={() => {
            navigate("/pay");
            //window.open('alipay://alipayclient/?%7B%22requestType%22%3A%22SafePay%22%2C%22fromAppUrlScheme%22%3A%22alipays%22%2C%22dataString%22%3A%22h5_route_token%3D%5C%22RZ546uagzVZ79D8uPcdXWuwC3cUXd0mobilecashierRZ54%5C%22%26is_h5_route%3D%5C%22true%5C%22%26h5_route_senior%3D%5C%22true%5C%22%22%7D');
          }}
        >
          <div className="menuItem menu-bg1 fx-box fx-ai-c fx-jc-c">
            {/* <i className='iconfont icon-chongzhi'></i> */}
            <img src="/static/images/mine-deposit.png" />
          </div>
          <div className="theme-font font-size13 font-w5 text-center">充值</div>
        </div>
        <div
          className="fx-1 fx-box fx-fd-c fx-ai-c"
          onClick={() => {
            navigate("/mycircle");
          }}
        >
          <div className="menuItem menu-bg2 fx-box fx-ai-c fx-jc-c">
            {/* <i className='iconfont icon-quanzi'></i> */}
            <img src="/static/images/mine-fu.png" />
          </div>
          <div className="theme-font font-size13 font-w5 text-center">
            我的圈
          </div>
        </div>
        <div
          className="fx-1 fx-box fx-fd-c fx-ai-c"
          onClick={() => {
            navigate("/live");
          }}
        >
          <div className="menuItem menu-bg3 fx-box fx-ai-c fx-jc-c">
            {/* <i className='iconfont icon-shipin'></i> */}
            <img src="/static/images/mine-o2o.png" />
          </div>
          <div className="theme-font font-size13 font-w5 text-center">
            视频交友
          </div>
        </div>
        <div
          className="fx-1 fx-box fx-fd-c fx-ai-c"
          onClick={() => {
            navigate("/share");
          }}
        >
          <div className="menuItem menu-bg4 fx-box fx-ai-c fx-jc-c">
            {/* <i className='iconfont icon-shengriliwu'></i> */}
            <img src="/static/images/mine-share.png" />
          </div>
          <div className="theme-font font-size13 font-w5 text-center">
            分享有礼
          </div>
        </div>
      </div>

      <div className="menu-list">
        {userMenuList.map((item, idx) => {
          return (
            <div
              onClick={() => {
                if (!item.path) {
                  return false;
                }
                if (item.path == "/star-certificate") {
                  setShowCertDialog(true);
                  return;
                }
                navigate(item.path);
              }}
              className="menuListItem fx-box fx-jc-sb"
              key={"mine_" + idx}
            >
              <div className="listItemIcon fx-box fx-ai-c">
                {/* <i className={item.icon}></i> */}
                <img src={item.image} className="mine-icon" />
                <span className="font-size13">{item.title}</span>
              </div>
              <div className="right-menu fx-box fx-ai-c">
                {item.menuType === "icon" ? (
                  <i className="iconfont icon-weibiaoti--"></i>
                ) : (
                  renderSwitch(item)
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Overlay
        visible={showHiddenDialog}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hidden-notice-dialog">
          <div className="hidden-title">隐身模式</div>
          <div className="hidden-desc">
            开启后，在公共区域你的信息将不会显示，主播将无法主动联系您
          </div>
          <div
            className="fx-box fx-jc-c fx-ai-c"
            onClick={() => {
              localStorage.setItem("hidden_noticed", "true");
              setShowHiddenDialog(false);
            }}
          >
            <div className="hidden-confirm-btn">我知道了</div>
          </div>
        </div>
      </Overlay>

      <Popup
        visible={showCertDialog}
        style={{
          borderRadius: 12,
          width: "75%",
          padding: 20,
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="cert-dialog font-size13">
          <div
            className="font-size15 fx-box fx-jc-c fx-ai-c"
            style={{ marginBottom: 15 }}
          >
            温馨提示
          </div>
          <div style={{ marginBottom: 15, fontWeight: 200 }}>
            女神认证请点击按钮去下载主播版Fu，如遇到问题，请联系客服
          </div>
          <div className="fx-box fx-fd-r fx-jc-sb">
            <div
              className="fx-1 fx-box fx-jc-c fx-ai-c"
              onClick={() => {
                setShowCertDialog(false);
              }}
            >
              <div className="btn-cancel">考虑一下</div>
            </div>
            <div className="fx-1 fx-box fx-jc-c fx-ai-c">
              {/* <a href="wanttopays://fudw.douquwl88.top/static/ioswebversion.html"> */}
              <div
                className="btn-ok"
                onClick={() => {
                  window?.webkit?.messageHandlers?.callbackHandler?.postMessage(
                    "openURL:https://fudw.douquwl88.top/static/ioswebversion.html"
                  );
                }}
              >
                去下载
              </div>
              {/* </a> */}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};
export default Mine;
