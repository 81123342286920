import React, { useEffect ,useRef,useState} from 'react';
import { useParams } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import Navigation from "../../components/navigation/navigation";
import * as service from '../../service';
import {GiftDict} from '../../service/config';
import './Gifts.css'; 
const Gifts=()=>{
    // const profile = JSON.parse(localStorage.getItem('profile'));
    const listRef = useRef();

    const [blist,setList] = useState(null);
    let loading = false;
    const params = useParams();

    useEffect(()=>{
        if(!blist&&params?.id){
            service.giftList(params?.id).then(data=>{
                if(data.code==0)
                {
                    console.log('%c data:%o','color:green',data);
                    setList(data.giftList);
                }
            })
        }
    },[setList])
      return (
        <div className="black-page hide-x">
           <Navigation theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'收到的礼物'}></Navigation>
           <div className='full-r-w fx-box fx-fd-r fx-ai-c layout-mt60 fx-wp' style={{paddingLeft:'0.1rem'}} ref={listRef}>
                 {
                    blist?.map((item,index)=>{
                        // const gift = GiftDict[item.giftId];
                        // console.log(gift);
                        return ( <div key={index} className='gift-item fx-box fx-jc-sb fx-ai-c fx-fd-c'>
                        
                            {/* <Image width={'0.41rem'} height={'0.41rem'} round src={gift.icon}/> */}
                            <Image width={'0.41rem'} height={'0.41rem'} round src={item.icon}/>
                            <div className='font-size15'>{item.name}</div>
                            <div className='font-size15 font-color2'>×{item.giftNums}</div>
                       
                    </div>)
                    })
                 }
           </div>
          
        </div>
      );
}

export default Gifts;