import { Outlet, useNavigate, Redirect, useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect, useContext } from "react";

import WebIM, { initConn } from "../components/easemob/WebIM";
import store from "../redux/store";
import { rtm } from "../components/agora/rtm";

import { ConnectionContext } from "../ConnectionContext";

import * as service from "../service";

import { appStart } from "../service";
import { connect } from "react-redux";

const _FUBase = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log('/=/监听路由000',props.forceLogin);
    if (props.forceLogin) {
      console.log('/=/监听路由');
      store.dispatch({ type: "IM_LOGIN", payload: undefined });
      localStorage.setItem("appStart", "");
      navigate("/login");
      return
    }
  }, [props.forceLogin]);
  const location = useLocation();

  useEffect(() => {
    if (props.imLogin) {
      console.log(
        "%c [AGORA] register AGORA RTM EVENT:localinvitationreceivedbypeer",
        "color:green"
      );


      //   rtm已弃用
      rtm.on("RemoteInvitationReceived", async (args) => {
        console.log(
          "%c AGORA RTM EVENT:localinvitationreceivedbypeer:%o",
          "color:green",
          args
        );
        console.log(args.callerId, args.content, args.channelId);
        rtm.currentRemoteInvitation = args;
        // 检查视频语音权限---2024-0426弃用rtm，变更为cmd
        const allowVideo = await service
          .checkWebMediaPermission()
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
        allowVideo &&
          service.getModelDetail(args.callerId).then((ret) => {
            console.log("%c [ONE2ONE]model detail 2:%o", "color:green", ret);
            console.log("[ONE2ONE]Laungch call navigate");
            console.log(location);

            navigate("/one2one", { state: { invite: true, caller: ret.user } });
            //dispatch({type:'NAVIGATE',payload:{state:{invite:true,caller:ret.user}}})
          });
      });
      console.log("/=/全局监听跳转：", props.videoStatus);
      // 跳转完成后要及时清空videoStatus数据
      if (props.videoStatus) {
        console.log("/==/监听变化");
        AsyncCheckVideo(props.videoStatus);
        props.dispatch({
            type: "videoStatus",
            payload:false,
          });
      }
    }
  }, [props.imLogin, props.videoStatus]);
  // 异步打开视频通话
  async function AsyncCheckVideo(args) {
    // 检查视频语音权限---2024-0426弃用rtm，变更为cmd
    const allowVideo = await service
      .checkWebMediaPermission()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
    // args.ext.data.invitorId   主叫 
    // getModelDetail(正式-模特)-------queryUserInfo(测试-用户)
    // args.ext.data.invitorId---这是服务端下发的消息
    // args.ext.ext.invitorId---这是来自于alet的信息
    const  modelID=args.from.slice(9)
    allowVideo &&
      service.getModelDetail(modelID).then((ret) => {
        console.log("%c [ONE2ONE]model detail 2:%o", "color:green", ret);
        console.log("[ONE2ONE]Laungch call navigate");
        navigate("/one2one", { state: { invite: true,roomId:args.ext.callId, caller: ret.user } });
        //dispatch({type:'NAVIGATE',payload:{state:{invite:true,caller:ret.user}}})
      });
  }
  // const cloudContext = useContext(ConnectionContext);
  // const [inited,setInited] = useState(false);
  // const profile = JSON.parse(localStorage.getItem('profile'));

  // console.log('%c \n[DEBUG]FUBASE function','color:cyan');

  // useEffect(()=>{
  //     if(inited||props.appStart)return;
  //     console.log('%c [FUBASE]init:%o %o','color:green',inited,props.appStart);
  //     if(props.forceLogin){
  //         // console.log('no profile 001 to login');
  //         setInited(true);
  //         navigate('/login');
  //         return;
  //     }

  //     service.appStart().then((appStart)=>{
  //         setInited(true);
  //         console.log('%c [DEBUG]logined:','color:green',appStart);
  //         props.dispatch({type:'FU_LOGINED',payload:profile,appStart})
  //     })
  // },[props.forceLogin])

  // useEffect(()=>{

  //     if(!props.profile||!props.appStart)return;

  //     console.log('[DEBUG]2profile:',profile);

  //     if(rtm._logining){
  //         console.log('rtm._logging....');
  //         return;
  //     }
  //     rtm._logining = true;
  //     console.log('\n\n\n\n');
  //     service.appStart().then((appret)=>{
  //         console.log('%c [DEBUG]login:','color:green',appStart);
  //         service.queryBanner().then(ret=>{
  //             console.log('%c[DEBUG]queryBanner:','color:blue',ret);
  //             store.dispatch({type:'SHOW_BANNER',payload:ret.data});
  //         })
  //         return appret;

  //     }).then(ret=>{
  //         if(ret.code!=0){
  //             throw new Error('重新登录');
  //         }
  //         console.log('[debug]appstart:',ret);

  //         store.dispatch({type:'LIVE_OTO_TAB',payload:ret?.tags?.oto});

  //         localStorage.setItem('appStart',JSON.stringify(ret));

  //         initConn(ret.easemob_config.accessKey);

  //         //
  //         ret.mqtt.hostname=ret.mqtt.endpoint.split('//').pop();
  //         window.mqtt(ret.mqtt,(p)=>{
  //             console.log('%c[DEBUG]mqtt callback:','color:cyan',p)
  //             const obj = JSON.parse(p);
  //             if(obj.m=='consume'){
  //                 store.dispatch({type:'show_consume',payload:obj.b});
  //             }
  //             else if(obj.m=='pay'){
  //                 store.dispatch({type:'show_recharge',payload:obj.b});
  //             }
  //         });

  //         return ret;
  //     }).then(app=>{
  //         console.log('[INFO]try to login rtm:',profile,app.rtmToken);
  //         if(!rtm._logined){
  //             rtm.on('ConnectionStateChanged',(ns,rs)=>{
  //                 console.log('%c rtm connection info:'+ns+' '+rs,'color:green');
  //             })

  //             rtm.login(profile.userId+'',app.rtmToken).then(() => {
  //                 console.log('[INFO]rtm connected.')

  //                 rtm.on('RemoteInvitationReceived',async (args)=>{
  //                     console.log('%c AGORA RTM EVENT:localinvitationreceivedbypeer:%o','color:green',args);
  //                     console.log(args.callerId,args.content,args.channelId);
  //                     rtm.currentRemoteInvitation=args;
  //                     const allowVideo=await service.checkWebMediaPermission().then(()=>{return true;}).catch(()=>{
  //                         return false;
  //                     });
  //                     allowVideo&&service.getModelDetail(args.callerId).then(ret=>{
  //                         console.log('%c model detail 2:%o','color:green',ret);
  //                         console.log('Laungch call navigate');
  //                         navigate('/one2one',{state:{invite:true,caller:ret.user}});
  //                     })

  //                 })

  //               }).catch((err) => {
  //                 console.error('=========> rtm')
  //                 console.error('======>',err)
  //             })
  //         }
  //     }).then(()=>{
  //         const options = {
  //             apiUrl: WebIM.config.apiURL,
  //             user: profile.hxUserName,
  //             pwd: profile.hxUserName.split('_')[1],
  //             appKey: WebIM.config.appkey
  //         };

  //         store.dispatch({type:'IM_LOGIN',payload:options});
  //         setTimeout(()=>{
  //             console.log('start easemob connect ....',options);
  //             WebIM.conn.open(options);},3000);
  //     })
  //     // .then(()=>{
  //     //     navigate('/app');
  //     //     console.log('finally...');

  //     // })
  //     .catch(err=>{
  //         console.error(err);
  //         console.log('.......error.......');
  //         navigate('/login');

  //     }).finally(()=>{
  //         rtm._logining=false;
  //         setInited(true);
  //     })
  // },[props.profile,props.appStart])

  return <Outlet />;
};

export const FUBase = connect(
  (state) => {
    return {
      profile: state.app.profile,
      appStart: state.app.appStart,
      forceLogin: state.app.forceLogin,
      imLogin: state.app.imLogin,
      videoStatus: state.app.videoStatus,
    };
  },
  (dispatch) => ({ dispatch })
)(_FUBase);
