import { combineReducers } from "redux";
import Live from "./liveReducer";
import Activity from "./activityReducer";
import { tabReducer } from "./tabReducer";
import geo from "./geo";

const initialState = {
  conversations: [],
  imLogin: null,
  appStart: null,
  profile: null,
  imError: null,
  WebMediaPermissionAllowed: false,
  WebMediaPermissionRequested: false,
  banner: null,
  bannerShowed: false,
  forceLogin: false,
  initing: false,
  videoStatus: false, // 被叫方--FUBase.js页面跳转到接听页面
  videoStatusCancel: false, // 被叫方--对方主动挂断
  videoStatusCancel2: false, // 被叫方--主动挂断
  videoStatusJoin: false, // 被叫方--主动方接听
  videoStatusText:false, // 视频时的聊天记录
  videoStatusToken:false, // 视频时的更新token
};

function appReducer(state = initialState, action) {
  // console.log('REDUCER:',action?.type||'no action type....');
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "videoStatus":
      return Object.assign({}, state, { videoStatus: action.payload });
    case "videoStatusCancel":
      return Object.assign({}, state, { videoStatusCancel: action.payload });
    case "videoStatusCancel2":
      return Object.assign({}, state, { videoStatusCancel2: action.payload });
    case "videoStatusJoin":
      return Object.assign({}, state, { videoStatusJoin: action.payload });
      case "videoStatusText":
        return Object.assign({}, state, { videoStatusText: action.payload });
        case "videoStatusToken":
          return Object.assign({}, state, { videoStatusToken: action.payload });
    // Do something here based on the different types of actions
    case "INIT_SERVICE":
      console.log("%c [REDUX]INIT_SERVICE:", action.ready, action);
      if (action.ready == false)
        return Object.assign({}, state, { initing: true });
      else return Object.assign({}, state, { initing: false });

    case "NEED_LOGIN":
      return Object.assign({}, state, { forceLogin: true });
    case "FU_LOGINED":
      console.log("%c[debug]redux FU_LOGINED:%o,%o", "color:blue", action);
      return Object.assign({}, state, {
        profile: action.payload,
        appStart: action.appStart,
      });
    case "OPENDEBUG":
      return Object.assign({}, state, { showdebug: true });
    case "SHOW_BANNER":
      if (state.bannerShowed) return state;
      return Object.assign({}, state, { banner: action.payload });
    case "BANNER_SHOWED":
      return Object.assign({}, state, { bannerShowed: true, banner: null });
    case "VIDEO_PERMISSION_NOTALLOW":
      return Object.assign({}, state, { WebMediaPermissionRequested: true });
    case "VIDEO_PERMISSION_ALLOW":
      return Object.assign({}, state, { WebMediaPermissionAllowed: true });
    case "one2one":
      return Object.assign({}, state, { one2one: action.payload });
    case "clear_ad":
      return Object.assign({}, state, {
        lastConsume: null,
        lastRecharge: null,
      });
    case "show_consume":
      return Object.assign({}, state, {
        lastConsume: action.payload,
        lastRecharge: null,
      });
    case "show_recharge":
      return Object.assign({}, state, {
        lastRecharge: action.payload,
        lastConsume: null,
      });
    case "LOGOUT":
      console.log("%c user logout, clear state", "color:green");
      return initialState;
    case "IM_ERROR":
      return Object.assign({}, state, { imError: action.payload });
    case "IM_READ_ALL": {
      console.log("/=/1111111111");
      state.conversations.map((e) => {
        e.msgnum = 0;
      });

      return Object.assign({}, state, {
        conversations: state.conversations.slice(),
      });
    }
    case "READ_ALL_MESSAGE":
      console.log("/=/111111111122222222");
      const name = action.payload;
      const conversation = state.conversations.find((c) => {
        return c.name == action.payload.name;
      });
      conversation && (conversation.msgnum = 0);
      return Object.assign({}, state, {
        conversations: state.conversations.slice(),
      });
    case "IM_LOAD":
      console.log("/=/11111111113333333333");
      console.log("%c [REDUCX]IM_LOADED", "color:green");
      return Object.assign({}, state, { conversations: action.payload });
    case "IM_LOGIN":
      console.log("%c [REDUCX]IM_LOGIN", "color:green");
      console.log('/=/JOSN出错定位9，:',localStorage.getItem("profile"));
      return Object.assign({}, state, {
        forceLogin: false,
        imError: false,
        imLogin: action.payload,
        profile: JSON.parse(localStorage.getItem("profile")),
      });
    case "sendMessage":
      // console.log('%c sendMessage:','color:blue',action);
      //console.log('%c CURRENT STATE:','color:green');
      //console.log(JSON.stringify(state));
      console.log("/=/11111111114444444444");
      if (action.payload.body.type == "txt") {
        const conversation = state.conversations.find((c) => {
          return c.name == action.payload.meta.name;
        });

        console.log("find:", action.payload, conversation);
        console.log("conversations:", state.conversations);

        if (!conversation) {
          console.log(
            "%c IM sendMessage reducer:%o",
            "color:green",
            action.payload
          );

          return Object.assign({}, state, {
            conversations: [
              {
                //todo: send  add should process this.

                nickname: action.payload.body.ext.postName,
                image: action.payload.body.ext.postHeader.replace(
                  "http:",
                  "https:"
                ),
                msg:
                  action.payload.body.msg ||
                  action.payload.body.message ||
                  action.payload.body.data ||
                  action.payload.body.text,
                msgnum: 1,
                name: action.payload.body.to,
                messages: [action.payload.body],
                lastMsgId: action.payload.meta.id,
              },
              ...state.conversations,
            ],
          });
        } else {
          const idx = state.conversations.indexOf(conversation);
          console.log("find idx:", idx);

          if (idx >= 0) {
            state.conversations.splice(idx, 1);
            conversation.messages.push(action.payload.body);
            return Object.assign({}, state, {
              conversations: [
                Object.assign({}, conversation, {
                  lastMsgId: action.payload.meta.serverMsgId,
                  msg: action.payload.body.msg,
                  msgnum:
                    conversation.msgnum * 1 +
                      (action.payload.meta.isUnread === 0 ? 0 : 1) ||
                    (action.payload.meta.isUnread === 0 ? 0 : 1),
                }),
                ...state.conversations,
              ],
            });
          }
        }
      }
      return state;

    case "receiveMessage":
      console.log("/=/11111111115555555555");
      //console.log('%c CURRENT STATE:','color:green');
      //console.log(JSON.stringify(state));
      if (action.payload.type == "text") {
        const conversation = state.conversations.find((c) => {
          return c.name == action.payload.name;
        });
        console.log("find:", action.payload, conversation);
        console.log("conversations:", state.conversations);

        if (!conversation) {
          return Object.assign({}, state, {
            conversations: [
              {
                //todo: send  add should process this.
                // id:action.payload.from,
                nickname: action.payload.ext.sendName,
                image: action.payload.ext.sendHeader.replace("http:", "https:"),
                msg: action.payload.msg,
                msgnum: 1,
                name: action.payload.name,
                messages: [action.payload],
              },
              ...state.conversations,
            ],
          });
        } else {
          const idx = state.conversations.indexOf(conversation);
          console.log("find idx:", idx);

          if (idx >= 0) {
            state.conversations.splice(idx, 1);
            conversation.messages.push(action.payload);
            return Object.assign({}, state, {
              conversations: [
                Object.assign({}, conversation, {
                  msg: action.payload.msg,
                  msgnum:
                    conversation.msgnum * 1 +
                      (action.payload.isUnread === 0 ? 0 : 1) ||
                    (action.payload.isUnread === 0 ? 0 : 1),
                }),
                ...state.conversations,
              ],
            });
          }
        }
      }
      break;
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}

export default combineReducers({
  Live,
  geo,
  app: appReducer,
  GlobalTab: tabReducer,
  Activity,
});
