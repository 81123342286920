import React, { useState } from "react";
import { Image, Toast } from "react-vant";
import "./gift-dialog.css";
import * as service from "../../service";

const IntimacyDialog = (props) => {
  console.log("/=/获取亲密度：", props);
  const [info, setInfo] = useState(null); // 获取vip亲密度
  const [progress, setProgress] = useState(0); // 获取vip亲密度

  if (!info) {
    service.getvipIntimacyUrl(props.modelId).then((data) => {
      console.log("/=/数据：", data);
      setInfo(data);
      // 亲密度计算需要判断用户个人是否vip，详情页同弹窗页
      console.log(
        "/=/用户是否vip：",
        props.userIsvip,
        Math.min(Math.trunc((props.intimacy * 100) / props.intimacyLine), 100)
      );
      let progress2 = 0;
      if (props.userIsvip) {
        // 如果是vip
        progress2 =
          props.intimacy && props.intimacyLine
            ? Math.min(
                Math.trunc((props.intimacy * 100) / data.vipIntimacy),
                100
              )
            : 0;
      } else {
        progress2 =
          props.intimacy && props.intimacyLine
            ? Math.min(
                Math.trunc((props.intimacy * 100) / props.intimacyLine),
                100
              )
            : 0;
      }
      setProgress(progress2);
    });
  }

  return (
    <div
      className="intimacy-mask"
      onClick={() => {
        props.onClose();
      }}
    >
      <div className="full-r-w fx-box fx-jc-c theme-font">
        <div className="intimacy-box fx-box fx-fd-c fx-ai-c">
          <div className="fx-box">
            <div className="intimacy-box-img layout-rel">
              <Image
                width="0.54rem"
                height="0.54rem"
                round
                src={props.modelHead}
              />
            </div>
            <div className="intimacy-box-img layout-rel">
              <Image
                width="0.54rem"
                height="0.54rem"
                round
                src={props.myHead}
              />
            </div>
          </div>
          <div className="intimacy-title font-size15">
            当前与Ta的亲密度为：
            <span className="font-size30 font-color2">{progress}%</span>
          </div>
          <div className="intimacy-process layout-rel full-r-w">
            <div
              className="layout-abs intimacy-process-item"
              style={{
                width: progress ? 0.0258 * Math.min(progress, 100) + "rem" : 0,
              }}
            ></div>
          </div>
          <div className="intimacy-value font-w5 font-size11 font-color2 fx-box fx-ai-c">
            <div>
              {props.intimacy}/{props.intimacyLine}
            </div>
            <div>亲密值</div>
          </div>
          <div className="intimacy-value font-w5 font-size11 font-color2 fx-box fx-ai-c">
            <div>
              {info?.userIntimacy}/{info?.vipIntimacy}
            </div>
            <div>亲密值</div>
            <img
              className="intimacy_valueVipIcon"
              src={info?.vipProductInfo?.icon}
              alt=""
            />
          </div>
          {progress < 100 ? (
            <div className="intimacy-remark font-size11">
              亲密值累计{" "}
              <span className="font-color2">
                {info?.vip ? info?.vipIntimacy : info?.intimacy}
              </span>{" "}
              可成为私密好友并免费解锁微信每与她消费1金币，可增加1亲密值
            </div>
          ) : (
            <div className="intimacy-wx-id font-size17 fx-box fx-jc-c">
              微信号：{props.wx}
            </div>
          )}
          {progress < 100 ? (
            <div className="intimacy-btn btn-bg4 fx-box fx-jc-c fx-ai-c">
              <div>
                <Image
                  width={"0.1rem"}
                  height={"0.14rem"}
                  src="/static/images/lock-icon.png"
                />
              </div>
              <span className="font-size15 font-color3">查看Ta的微信</span>
            </div>
          ) : (
            <div
              className="intimacy-btn btn-bg1 fx-box fx-jc-c fx-ai-c"
              onClick={async () => {
                await navigator.clipboard.writeText(props.wx);
                Toast({
                  message: "主播微信已复制",
                  icon: (
                    <Image width={50} src="/static/images/toast-icon.png" />
                  ),
                });
              }}
            >
              <div>
                <Image
                  width={"0.14rem"}
                  height={"0.14rem"}
                  src="/static/images/open-icon.png"
                />
              </div>
              <span className="font-size15 font-color3">复制Ta的微信</span>
            </div>
          )}
          {
            <div className="intimacy-info">
              <div className="intimacy-tip font-size12">风险提示</div>
              <div className="intimacy-tip font-size10">
                1、亲密度代表了熟悉程度,循序渐进的积累更有助于稳固彼此的亲密关系哦!
                <br></br>
                2、如主播在直播任何场合以陪玩、送礼等方式进行诱导打赏、私下交易，请谨慎判断，以防人身或财产损失。请用户注意财产安全，谨防网络诈骗。此外，用户在第三方平台与主播产生的任何风险与财产损失与平台无关
                。
              </div>
              <div className="intimacy-stip font-size10">
                如果您遇到主播有此违规行为欢迎您联系客服进行举报。
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default IntimacyDialog;
