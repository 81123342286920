var locationRetry=0;
export const requestLocationAuth=(dispatch)=>{
    locationRetry=0;
    window.futrue.getLocationAuthorizationCallback=(ret)=>{
        if(!ret){

            if(locationRetry>3){
                return;
            }
            setTimeout(() => {
                locationRetry++;
                window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getLocationAuthorization');
            }, 3000);
            window?.webkit?.messageHandlers?.callbackHandler?.postMessage('requestLocationPermission');
        }
        else {

            window.futrue.getLocationCallback=(ret)=>{
                console.log('%c Geolocation got:','color:blue',ret);
                if(ret.latitude&&ret.longitude)dispatch&&dispatch({type:'GEO_LOADED',payload:ret})
            };
            dispatch&&dispatch({type:'GEO_LOAD'});

            window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getLocation');

        }
    };
    window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getLocationAuthorization');
}

export const requestLocation=(dispatch,onSuccess)=>{
    window.futrue.getLocationAuthorizationCallback=(ret)=>{
        if(!!ret){
            window.futrue.getLocationCallback=(ret)=>{
                console.log('%c Geolocation got:','color:blue',ret);
                if(ret.latitude&&ret.longitude){
                    onSuccess&&onSuccess();

                    dispatch&&dispatch({type:'GEO_LOADED',payload:ret})
                }
                
                // dispatch&&dispatch({type:'GEO_LOADED',payload:ret})
            };

            dispatch&&dispatch({type:'GEO_LOAD'});
            window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getLocation');
        }
    };
    window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getLocationAuthorization');
}