import React, { useState, useRef, useEffect, memo } from "react";
import { Image, PullRefresh, List, Tabs, Cell } from "react-vant";
import { DefaultHeadUrl } from "../../service/images";
import * as service from "../../service";
import "./user-msg-item.css";

// h5上多次加载PAG图片容易引起页面卡顿

// 模拟异步请求
async function getData(userId, count) {
  return new Promise((resolve, reject) => {
    service.queryModelComment(userId, count).then((data) => {
      console.log("/=/请求数据：", data);
      resolve(data.evaluationList);
    });
  });
}

const UserMsgItem = (props) => {
  const [list, setList] = useState([]);
  const [finished, setFinished] = useState(false);
  const [count, setCount] = useState(0);
  console.log("/==/22:", list);
  const onLoad = async () => {
    console.log("/=/继续加载...", list.length);

    const data = await getData(props.userId, count);
    setList([...list, ...data]);
    setCount(count + 20);
    console.log("/==/:", list);
    if (data.length < 20) {
      setFinished(true);
      return;
    }
  };

  const pagFunc = async (llpagUrl, name) => {
    llpagUrl = llpagUrl.replace("http:", "https:");
    const llbuffer = await fetch(llpagUrl).then((response) =>
      response.arrayBuffer()
    );
    const llpagFile = await window.PAG.PAGFile.load(llbuffer);
    const llCanvas = document.getElementById(name);
    if (llCanvas) {
      llCanvas.width = llpagFile.width();
      llCanvas.height = llpagFile.height();

      window.PAG.PAGView.init(llpagFile, llCanvas).then((v) => {
        v.setRepeatCount(0);
        v.play();
      });
    }
  };

  return (
    <List finished={finished} onLoad={onLoad}>
      {list.map((item, idx) => {
        let personDetailBg = {};
        if (item.vip) {
          pagFunc(
            item.vipProductInfo.effects.HEADER_EFFECT,
            "personDetail" + idx
          );
          const colorString = item.vipProductInfo.effects.NICK_EFFECT.replace(
            /0xFF/g,
            "#"
          ).split(",");
          console.log("/=/渲染：", colorString);
          if (colorString.length == 1) {
            personDetailBg = {
              color: colorString[0],
            };
          } else if (colorString.length == 4) {
            personDetailBg = {
              background: `linear-gradient(240deg, ${colorString[0]} 19.9%, ${colorString[1]} 42.28%, ${colorString[2]} 65.23%, ${colorString[3]} 91.63%)`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            };
          }
        }

        return (
          <div
            className="msg-item-container fx-box fx-ai-c fx-jc-sb theme-font"
            key={idx}
          >
            <div className="msg-item-info fx-box fx-ai-c layout-rel">
              <Image
                width="0.35rem"
                height="0.35rem"
                round
                src={item.headUrl || DefaultHeadUrl}
              />
              {item.vip ? (
                // <canvas
                //   className="personDetailCanvas"
                //   id={"personDetail" + idx}
                // ></canvas>
                // "SUPER_VIP"
                item.vipProductInfo.type === "VIP" ? (
                  <img
                    className="personDetailCanvas"
                    src="/static/images/person-vip.png"
                    alt="vip"
                  />
                ) : (
                  <img
                    className="personDetailCanvas"
                    src="/static/images/person-svip.png"
                     alt="SUPER_VIP"
                  />
                )
              ) : null}
              <div className="layout-ml8">
                <div
                  className="msg-item-name font-size10"
                  style={personDetailBg}
                >
                  {item.nickName}
                </div>
                <div className="fx-box fx-jc-sb">
                  <div className="msg-item-level font-size10 font-color3 fx-box fx-jc-sb fx-ai-c">
                    <Image
                      width="0.08rem"
                      height="0.08rem"
                      src="/static/images/command-icon.png"
                    />
                    <div>{item.userLeven || item.modelLeven}</div>
                  </div>
                  {item.vip ? (
                    <img
                      className="personDetailCanvasIcons"
                      src={item.vipProductInfo.icon}
                      alt=""
                      height={16}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <div className="fx-box fx-ai-c">
              {item.evaluationParts.map((tItem, index) => {
                return (
                  <div
                    key={"umk_" + index}
                    className={
                      "msg-item-tag font-size10 font-color3 msg-item-tag-bg"
                    }
                    style={{ backgroundColor: tItem.color.replace(/0x/g, "#") }}
                  >
                    {tItem.prompt}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </List>
  );
};

export default memo(UserMsgItem);
