import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GiftDialog from "../../components/gift-dialog/gift-dialog";
import "./One2one.css";
import {
  checkLive,
  getLiveToken,
  CALLMODEL,
  getCallUserInfo,
  finishLive,
  liveGetTimes,
  checkVague,
  joinRoom,
  addMessageByUser,
} from "../../service";
import { rtm, writeLogs } from "../../components/agora/rtm";
import { AGORA_APP_ID } from "../../service/config";
import { DefaultHeadUrl } from "../../service/images";
import { giveGift, charge, queryMyselfInfo, spam } from "../../service";
import MsgDialog from "../../components/msg-dialog/msg-dialog";
import MsgInput from "../../components/msg-dialog/msg-input";
import WebIM from "../../components/easemob/WebIM";

import AgoraRTC, {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
  useClient,
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "../../components/agora/rtc.tsx";
import { PersonContext } from "../Person/PersonContext";
import { ChargeContext } from "../Person/ChargeContext";
import { Overlay, Toast } from "react-vant";
import ChargeModal from "../Pay/PayModal";
import GiftTip from "../../components/gift-dialog/gift-tip";
import { DepositDialog } from "../../components/deposit-dialg";
import IntimacyDialog from "../../components/gift-dialog/intimacy-dialog";
import * as service from "../../service";
import { connect } from "react-redux";
import { giftSend } from "../../service/im";
// 主页面请求接口直接使用函数，不需要导入

// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component

const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

//[PMTool sharedPMTool] pm:0 liveId:self.tokenModel.liveId
// modelId:modelId opusId:0
// type:5 success:^(id  _Nullable json) {

// var isChatting = false;
const statusStrings = [
  "正在接入视频服务",
  "已经接入视频服务，正在拨通对方", //1072
  "已经拨通，等待响应", //1073
  "对方拒绝接听，正在结束通话",
  "对方接听，正在接入视频", //1074
  "已挂断,正在断开视频服务",
  "已挂断,正在断开视频服务",
];

// WebIM.conn.listen({
//     onCmdMessage: function (message) {
//         /**
//          * 1、接听；2、对方接听；3、对方挂断---cmdType
//          */
//         console.log("onCmdMessage这是视频页面: ", message);

//       }, //收到命令消息
// })

const One2one = (props) => {
  // console.log("/=/props：", props);

  const reviewContext = useContext(PersonContext);
  const chargeContext = useContext(ChargeContext);
  const [history, setHistory] = useState([]);
  const [inCall, setInCall] = useState(true);
  // const [isFailed,setFailed ] = useState(false);
  const [needDeposit, setNeedDeposit] = useState(false);
  const navigate = useNavigate();
  const [callStatus, setCallStatus] = useState(0);

  const [conversationId, setConversationId] = useState(null);
  const [conversationIdReceive, setConversationIdReceive] = useState(null); // 作为接听方暂存
  const [channelName, setChannelName] = useState(null);
  const [liveToken, setLiveToken] = useState(null);
  const [liveId, setLiveId] = useState(null);
  const [callHangup, setCallHangup] = useState(false);
  const [expTime, setExpTime] = useState(0); // 剩余通话时长
  let countDownTimer = null; // 通话剩余时长计时器
  const [myInfo, setMyInfo] = useState(null);
  const [vague, setVague] = useState(true);
  const [renewToken, setRenewToken] = useState(null);
  const [inviteData, setInviteData] = useState(null); // 被邀请时请求的数据

  // console.log('[DEBUG]conversationId:',conversationId);
  console.log("/=/JOSN出错定位20，:", localStorage.getItem("profile"));
  const profile = JSON.parse(localStorage.getItem("profile"));

  const location = useLocation();
  // console.warn('LOCATION:',location);
  // console.log("/=/获取到的页面传参：", location, location?.state?.roomId);

  AgoraRTC.getDevices(true);

  useEffect(() => {
    // videoStatusJoin: state.app.videoStatusJoin,1、底部props定义；2、effect长链接；3、effect使用
    console.log("/=/主叫方挂断通知接听方:", props.videoStatusCancel);
    if (props.videoStatusCancel) {
      // 作为接听方，拨打方主动挂断
      props.dispatch({
        type: "videoStatusCancel",
        payload: false,
      });
      cancelClearTime(); // 主动挂断，结束主动拨打时间循环
      setCallStatus(6); //   "已挂断,正在断开视频服务",
    }
    console.log("/=/主叫方收到--接听方挂断消息:", props.videoStatusCancel2);
    if (props.videoStatusCancel2) {
      // 作为拨打方，接听方主动挂断
      props.dispatch({
        type: "videoStatusCancel2",
        payload: false,
      });
      cancelClearTime(); // 主动挂断，结束主动拨打时间循环
      setCallStatus(3); //   "对方拒绝接听，正在结束通话",
    }
    console.log("/=/主叫方收到--接听方接听消息:", props.videoStatusJoin);
    if (props.videoStatusJoin) {
      // 作为拨打方，接收到接听方确认接通消息
      props.dispatch({
        type: "videoStatusJoin",
        payload: false,
      });
      cancelClearTime(); // 主动挂断，结束主动拨打时间循环
      setCallStatus(4);
    }
    console.log("/=/对方的文字消息:", props.videoStatusText);
    if (props.videoStatusText) {
      // 接收到来自原对方的文字消息
      props.dispatch({
        type: "videoStatusText",
        payload: false,
      });
      const obj = {
        avatar: null,
        giftId: 0,
        visiable: 0,
        killId: 0,
        code: 10,
        message: props.videoStatusText,
        nmbs: 0,
        type: 0,
      };
      history.push(obj);
      setHistory(history.slice());
    }
    if (props.videoStatusToken) {
      // 接收cmd消息更新token和时间
      props.dispatch({
        type: "videoStatusToken",
        payload: false,
      });

      setRenewToken(props.videoStatusToken.data.token); // 刷新token
      setExpTime(props.videoStatusToken.data.duration); // 刷新倒计时
      startCountDownTimer(props.videoStatusToken.data.duration); // 开始倒计时
    }

    let timer;
    // 查询信息
    if (!myInfo) {
      queryMyselfInfo().then((data) => {
        console.log("....myInfo直播房间:", data);
        if (data.code == 0) {
          setMyInfo(data);
        }
      });
    }
    // 无用
    if (needDeposit) {
      // console.log('需要充值');
      chargeContext.setPay(true);

      if (location?.state?.person?.userId) {
        console.log("[NAVIGATE]navigate to person line 94");
        navigate("/person/" + location?.state?.person?.userId, {
          replace: true,
          state: { from: "one2one" },
        });
      } else {
        console.log("[NAVIGATE]navigate from one2one -1");
        navigate(-1);
      }

      return;
    }
    console.log("/=/这是callStatus：", callStatus);
    if (callStatus == 3) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        console.log("[NAVIGATE]callStatus==3 navigate back -1 line 115");
        navigate(-1);
      }, 500);
    } else if (callStatus >= 5 && !liveToken) {
      clearTimeout(timer);
      const modelId = location?.state?.invite
        ? location?.state?.caller?.userId
        : location?.state?.person?.userId;
      console.log("/=/这又是哪儿挂断电话，未接通时");
      liveId &&
        finishLive(liveId, channelName, modelId, profile.userId).then(
          (data) => {
            console.log("%c finish live got:%o", "color:purple", data);
            data.liveId = liveId;
            reviewContext.setLive(data);
          }
        );

      timer = setTimeout(() => {
        console.log("callStatus>5 navigate back -1");
        navigate(-1);
      }, 1000);
    } else if (callStatus >= 5 && callHangup == true) {
      // 通话结束-挂断电话
      console.log(
        "%c[GIFTTIMEDEBUG] callStatus>5 2 navigate back:%o",
        "color:blue",
        new Date()
      );
      console.log("/=/通话结束-挂断电话");
      console.log(
        "%c callStatus==5, callHangup==true, try to end call",
        "color:orange"
      );

      const modelId = location?.state?.invite
        ? location?.state?.caller?.userId
        : location?.state?.person?.userId;

      // liveId &&
      //   finishLive(liveId, channelName, modelId, profile.userId).then(
      //     (data) => {
      //       console.log("%c finish live got:%o", "color:purple", data);
      //       data.liveId = liveId;
      //       reviewContext.setLive(data);
      //     }
      //   );
      liveId &&
        service.cancelRoom(channelName).then((data) => {
          console.log("%c finish live got:%o", "color:purple", data);
          // 传递数据，流向src/pages/Person/review/index.js
          reviewContext.setLive(data.data);
          // 顺序问题
          reviewContext.setReview(true);
          clearTimeout(timer);
          timer = setTimeout(() => {
            console.log("[NAVIGATE]navigate to person");
            // navigate(-1);
            if (renewToken)
              navigate("/person/" + modelId, {
                replace: true,
                state: { from: "one2one" },
              });
            else navigate("/person/" + modelId, { replace: true });
          }, 100);
        });
    }
    return () => {
      clearInterval(rtm.chargeTimer);
      clearTimeout(timer);
      clearInterval(countDownTimer); // 结束通话倒计时计时器
    };
  }, [
    callStatus,
    navigate,
    callHangup,
    rtm,
    needDeposit,
    props.videoStatusCancel,
    props.videoStatusCancel2,
    props.videoStatusJoin,
    props.videoStatusText,
    props.videoStatusToken,
  ]);

  rtm.removeAllListeners("MessageFromPeer");

  useEffect(() => {
    (async () => {
      // 判断是否是被动接听
      if (!location?.state?.invite) {
        // console.log('isChecking:',isChatting);
        // const userId = JSON.parse(localStorage.getItem("profile")).userId;
        const modelId = location?.state?.person?.userId;
        // console.log('%c current conversationId:%o,%o','color:green ',conversationId,isChatting);
        console.log("/=/私聊第一是");
        if (!conversationId) {
          // isChatting=true;
          // const data = await checkLive(modelId+'',userId+'');
          // console.log('[DEBUG]check live return:',data);
          // isChatting=false;
          // if(data.code=='707'||data.code=='709'){
          //     setNeedDeposit(true);
          //     chargeContext.setPay(true);
          // }
          // else if(!data.conversationId){
          //     setFailed(true);
          // }
          const data = location?.state?.liveData.data;
          console.log("/=/第一次进入唤起页面：", data);
          if (data) {
            // setConversationId(data.conversationId);
            setConversationId(data.roomId);
            setChannelName(data.roomId);
            setInviteData(data);
            // data.channelName
            //   ? setChannelName(data.roomId)
            //   : setChannelName(
            //       `${profile.userId}-${
            //         location?.state?.person?.userId
            //       }-${new Date().valueOf()}`
            //     );
            //    从0变为1
            setCallStatus(1);
          }
        }
        console.log(
          "%c current conversationId:%o",
          "color:green ",
          conversationId
        );
        // callStatus 从0变为1，第二次运行，conversationId有值了
        if (callStatus < 2 && conversationId) {
          const param = {
            userId: profile.userId,
            modelId: "" + location?.state?.person?.userId,
            conversationId: conversationId,
            channelName,
          };
          console.log("%c current param:", "color:green ", param);
          // 调用声网使得模特端唤起接听
          // rtm.sendLocalInvitation(param,{
          //     refused:()=>{
          //         console.log('%c invitation refused setCallStatus 3','color:red');
          //         setCallStatus(3);
          //     },
          //     accepted:()=>{
          //         setCallStatus(4);
          //     },
          //     received:()=>{
          //         setCallStatus(2);
          //     },
          //     fail:(e)=>{
          //         console.log('%c invitation failed setCallStatus 3','color:red',e);
          //         setCallStatus(3);
          //     }
          // })

          // console.log("/=/发送cmd消息：", param);
          sendCMDMessage("invite"); // 调用cmd
          window.localStorage.setItem("calleeDevId_is", true); // 是否存储接听方设备号
          window.localStorage.setItem("calleeDevId", ""); // 设置接听方设备号，confirmCallee需要用

          setCallStatus(2);
        }
        if (callStatus == 4) {
          //send default mohu
          const p1 = JSON.stringify({
            code: 3 + "",
            visiable: vague ? "1" : "0", //跟当前vague是反的。
          });
          // rtm.sendPeerMessage(p1,targetId)

          console.log(
            "%c VAGUE DEFAULT SET TO DAKAIMOHU:%o",
            "color:green",
            p1
          );
          //getLiveToken
          console.log("========callout===========to connect video ");
          console.log("channelName:", channelName);
          console.log("conversationId:", conversationId);
          console.log("userId:", profile.userId);
          console.log("modelId:", location?.state?.person?.userId);
          // const tokenData = await getLiveToken(channelName,location?.state?.person?.userId+'',profile.userId+'',conversationId)
          const tokenData = await joinRoom(conversationId);
          console.log("getLiveToken:", tokenData);
          if (tokenData.code == 0) {
            sendCMDMessage("confirmCallee", "accept"); //  主叫方发送
            clearInterval(rtm.callExpTimer);
            rtm.callExpTimer = null;
            setLiveToken(tokenData.data.token);
            // setLiveId(tokenData.liveId);
            setLiveId(tokenData.data.token);
            setExpTime(tokenData.data.expire); // 设置剩余通话时长
            startCountDownTimer(tokenData.data.expire);
          }
        }
      }
      // !location?.state?.invite   反向
      else {
        if (location?.state?.roomId) {
          setChannelName(location?.state?.roomId); // 挂断电话时可用
          setConversationId(location?.state?.roomId); // 不能设置，否则直接连接视频，然后断开
          //   setConversationIdReceive(location?.state?.roomId);
        }
        if (callStatus < 2 && conversationId) {
          console.log("/=/只进入一次------------");
        }
        console.log("/=/作为接听方处理conversationId：", conversationId);
        // 作为接听方
        console.log("%c receive call:%o", "color:green", callStatus);
        console.log(
          "%c receive caller:%o",
          "color:green",
          location.state.caller
        );
        if (callStatus == 4 && !liveToken) {
          sendCMDMessage("answerCall", "accept");
          //getLiveToken
          console.log("========receive call===========to connect video ");
          console.log("conversationId:", conversationId);
          console.log("userId:", profile.userId);

          getCallUserInfo(location?.state?.caller?.userId).then(async (cs) => {
            console.log("%c getCallStatus:%o", "color:green", cs);

            console.log("/=/接听方创建房间");
            const tokenData = await joinRoom(conversationId);
            console.log("getLiveToken:", tokenData);
            if (tokenData.code == 0) {
              clearInterval(rtm.callExpTimer); // 清除接听时的倒计时
              rtm.callExpTimer = null;
              setLiveToken(tokenData.data.token);
              setLiveId(tokenData.data.token);
              setExpTime(tokenData.data.expire);
              startCountDownTimer(tokenData.data.expire);
            }
          });
        }
      }
    })();
  }, [
    conversationId,
    location,
    setConversationId,
    setCallStatus,
    channelName,
    callStatus,
    setLiveToken,
    vague,
  ]);

  const onUserJoin = (user) => {
    const model = Object.assign({}, CALLMODEL, { code: 6 });
    // rtm.sendPeerMessage(
    //   JSON.stringify(model),
    //   location?.state?.invite
    //     ? rtm.currentRemoteInvitation.callerId
    //     : location?.state?.person?.userId
    // );
    const modelId = location?.state?.invite
      ? location?.state?.caller?.userId
      : location?.state?.person?.userId;
    startChargeTimer(liveId, modelId, profile.userId, channelName);
  };
  const cancelClearTime = () => {
    clearInterval(rtm.countDownTimer); // 结束通话倒计时
    clearInterval(rtm.callExpTimer); // 主动挂断，结束主动拨打时间循环
    rtm.countDownTimer = null;
  };
  const cancelCall = async () => {
    cancelClearTime(); // 主动挂断，结束主动拨打时间循环
    // console.log('cancel call');
    setCallStatus(6);
    setCallHangup(true);
    try {
      if (location?.state?.invite) {
        // 主动拒接
        cancelrefuse();
        // await rtm.currentRemoteInvitation.refuse();
      } else {
        // 主动取消
        // await rtm.cancelLocalInvitation();
        cancelLocalInvitation();
      }
    } catch (err) {
      console.error(err);
    }
  };
  // 主动取消拨打，通知被叫方
  const cancelLocalInvitation = () => {
    service.cancelCreateRoom(conversationId).then((data) => {
      console.log("/=/取消拨打");
      sendCMDMessage("cancelCall");
    });
  };
  // 主动挂断，通知主叫方acceptCall---cancelrefuse
  //   conversationIdReceive
  const cancelrefuse = () => {
    service.cancelJoinRoom(conversationId).then((data) => {
      console.log("/=/拒绝接听", data);
      sendCMDMessage("answerCall", "refuse");
    });
  };

  // 发送cmd：客户端,type:invite--邀请,alert-接收到视频邀请后发送
  const sendCMDMessage = (typeAction, resType) => {
    const modelId = location?.state?.invite
      ? location?.state?.caller?.userId
      : location?.state?.person?.userId;
    const calleeDevId_is = localStorage.getItem("calleeDevId_is") === "true"; // 只有ios主动拨打才会判断两边设备ID
    const ext = {
      action: typeAction,
      callId: channelName,
      channelName: channelName,
      calleeDevId: calleeDevId_is
        ? localStorage.getItem("calleeDevId")
        : "h5_" + localStorage.getItem("fu_device_no"),
      callerDevId: calleeDevId_is
        ? "h5_" + localStorage.getItem("fu_device_no")
        : localStorage.getItem("calleeDevId"),
      msgType: "rtcCallWithAgora",
      type: 1, // 可能1为视频
      messageId: WebIM.conn.getUniqueId(),
      ext: inviteData,
    };
    console.log(
      "/=/再次判断：",
      calleeDevId_is,
      localStorage.getItem("calleeDevId"),
      "h5_" + localStorage.getItem("fu_device_no")
    );
    if (typeAction === "dimMsg") {
      // 是否开启模糊
      ext.data = {
        isDim: resType ? true : false,
      };
    } else if (typeAction === "sendMessage") {
      // 视频时发送文字消息
      ext.data = {
        message: resType,
      };
    } else if (resType) {
      ext.result = resType;
    }
    console.log("/=/获取判定：：，发送cmd消息-----：", calleeDevId_is, ext);
    const msg = new WebIM.message("cmd");
    writeLogs(2003, ext); // 发送cmd消息
    msg.set({
      to: "socialfu_" + modelId,
      action: "rtcCall",
      contentsType: "COMMAND",
      ext: ext,
      success: function (id, serverMsgId) {
        console.log("发送文本消息成功");
      },
      fail: function (err) {
        console.log("发送文本消息失败", err);
        setCallStatus(3);
      },
    });
    WebIM.conn.send(msg.body);
  };

  const onCallTimeout = async (status) => {
    console.log("%c onCallTimeout:", "color:green", status);
    const modelId = location.state.invite
      ? location.state.caller.userId
      : location.state.person.userId;
    const callUserId = location.state.invite
      ? location.state.caller.userId
      : profile.userId;

    switch (status * 1) {
      case 1:
        console.log("%c onCallTimeout report:", "color:green", 1072);
        spam({
          operate: 1072,
          type: 2,
          liveId,
          modelId,
          callUserId,
          reCode: 0,
          channelName: "声网在线",
        });
        break;
      case 2:
        console.log("%c onCallTimeout report:", "color:green", 1073);
        spam({
          operate: 1073,
          type: 2,
          liveId,
          modelId,
          callUserId,
          reCode: 0,
          channelName: "模特没接听",
        });
        break;
      case 4:
        console.log("%c onCallTimeout report:", "color:green", 1074);
        spam({
          operate: 1074,
          type: 2,
          liveId,
          modelId,
          callUserId,
          reCode: 0,
          channelName: "用户在接通状态",
        });
        break;
      default:
        console.log("%c [DEBUG]call status:%o", "color:green", status);
    }

    setCallStatus(6);
    setCallHangup(true);
    try {
      if (location?.state?.invite) {
        cancelrefuse();
        // await rtm.currentRemoteInvitation.refuse();
      } else {
        // await rtm.cancelLocalInvitation();
        cancelLocalInvitation();
      }
    } catch (err) {
      console.error(err);
    }
  };

  // 计算通话倒计时
  const startCountDownTimer = (expTime) => {
    console.log("%c [DEBUG]start count down timer", "color:blue");
    clearInterval(countDownTimer);
    countDownTimer = null;
    countDownTimer = setInterval(() => {
      console.log("/=/设置剩余时长12:", expTime);
      setExpTime(expTime--);
      if (expTime <= 0) {
        console.log("\n=====>expTime run out.", expTime);
        clearInterval(countDownTimer);
        expTime = 0;
      }
    }, 1000);
  };

  const startChargeTimer = (liveId, modelId, viewerId, channelName) => {
    console.log("/=/计时器？，视频时");
    if (rtm.chargeTimer) {
      clearInterval(rtm.chargeTimer);
      rtm.chargeTimer = null;
    }
  };

  if (!liveToken) {
    return (
      <Calling
        invite={location?.state?.invite}
        model={
          location?.state?.invite
            ? location?.state?.caller
            : location?.state?.person
        }
        status={statusStrings[callStatus]}
        callStatus={callStatus}
        cancelCall={cancelCall}
        onTimeout={onCallTimeout}
        acceptCall={async () => {
          // 自动跳转到callStatus == 4,作为接听方
          // 通知主叫方acceptCall---cancelrefuse
          console.log("/=/可以接打电话");
          //   (await rtm.currentRemoteInvitation) &&
          //     rtm.currentRemoteInvitation.accept();
          setCallStatus(4);
          clearInterval(rtm.callExpTimer);
          rtm.callExpTimer = null;
        }}
        vague={vague}
        setVague={setVague}
        autoPlay={callStatus < 3}
        sendCMDMessage={sendCMDMessage}
      />
    );
  } else {
    //return <Chatting {...props}/>
    return (
      <VideoCall
        updateTime={() => {
          const modelId = location?.state?.invite
            ? location?.state?.caller?.userId
            : location?.state?.person?.userId;
          const viewerId = profile.userId;

          console.log(
            "%c[GIFTTIMEDEBUG] update time %o %o %o %o ",
            "color:blue",
            modelId,
            viewerId,
            channelName,
            expTime
          );
        }}
        //  下面的不应该折叠
        setInCall={setInCall}
        onUserJoin={onUserJoin}
        setCallHangup={() => {
          clearInterval(rtm.countDownTimer);
          rtm.countDownTimer = null;
          clearInterval(rtm.chargeTimer);
          rtm.chargeTimer = null;

          setCallHangup(true);
        }}
        setCallStatus={setCallStatus}
        channelName={conversationId}
        token={liveToken}
        renewToken={renewToken}
        model={
          location?.state?.invite
            ? location?.state?.caller
            : location?.state?.person
        }
        dispatch={props.dispatch}
        history={history}
        onClear={() => {
          setHistory([]);
        }}
        expTime={expTime}
        onSendMessage={(obj) => {
          history.push(obj);
          setHistory(history.slice());
        }}
        liveId={liveId}
        vague={vague}
        setVague={setVague}
        balance={myInfo?.userInfo?.wealthXz}
        uid={profile?.userId}
        sendCMDMessage={sendCMDMessage}
      />
    );
  }
};

const Calling = (props) => {
  const { model } = props;
  console.log("/=/model是什么：", model);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (timer === null) {
      clearInterval(rtm.callExpTimer);

      rtm.callExpTime = 20;
      rtm.callExpTimer = setInterval(() => {
        console.log("[TIMER]start call timer", rtm.callExpTime);
        setTimer(rtm.callExpTime--);
        if (rtm.callExpTime < 0) {
          clearInterval(rtm.callExpTimer);
          rtm.callExpTimer = null;
          console.log(
            "%c props.callStatus:%o",
            "color:green",
            props.callStatus
          );
          props.onTimeout(props.callStatus);
          // props.cancelCall();
        }
      }, 1000);
    }
  }, [timer, setTimer, props.callStatus, props.onTimeout]);
  return (
    <div className="one2one">
      <video
        className="main-video"
        poster={model?.headUrl}
        style={{ objectFit: "cover" }}
      />

      {/* <video className='other-video' poster='/static/images/live-02.png'></video> */}
      <div className="calling-video">
        <div className="calling-home fx-box fx-ai-c fx-jc-c">
          <div className="calling-home-avatar fx-box fx-jc-c fx-ai-c">
            <img src={model.headUrl} />
          </div>
        </div>
        <div className="calling-name fx-box fx-jc-c fx-ai-c font-size13 font-color3">
          {model.nickName}
        </div>

        <div className="calling-cost fx-box fx-ai-c fx-jc-c">
          <div className="uptext theme-font font-size11 font-color3">
            每分钟支出{model.oneToOneVedioPrice}金币
          </div>
        </div>

        <div className="calling-status fx-box fx-ai-c fx-jc-c">
          <div className="uptext theme-font font-size11 font-color3">
            {props.status} {timer === null ? 20 : timer}秒
          </div>
        </div>

        <div className="main-menu-pause fx-box fx-fd-r fx-ai-c fx-jc-c theme-font">
          {/* <div className='menu-pause-state font-size11 font-color3'>剩余01:30</div> */}

          <div
            className="pause-btn fx-box fx-jc-c fx-ai-c"
            onClick={() => {
              props.cancelCall();
            }}
          >
            {/* <i className='iconfont icon-guaduan'></i> */}
            <img src="/static/images/hangup.png" className="calling-hangup" />
          </div>
          {/* 接听电话 */}
          {props.invite ? (
            <div
              className="accept-btn fx-box fx-jc-c fx-ai-c"
              onClick={() => {
                props?.acceptCall && props.acceptCall();
              }}
            >
              {/* <img src='/static/images/hangup.png' className='calling-hangup'/> */}
              {/* <i className='iconfont icon-guaduan'></i> */}
              <img
                src="/static/images/call-accept.png"
                className="calling-hangup"
              />
            </div>
          ) : null}
        </div>

        <div
          className="calling-menu-dim fx-box fx-ai-c fx-jc-c theme-font"
          onClick={() => {
            const resType = props.vague ? 1 : 0;
            checkVague(model.userId, resType, props.channelName).then(
              (data) => {
                console.log(data);

                const p2 = JSON.stringify({
                  code: 3 + "",
                  visiable: props.vague ? "0" : "1", //跟当前vague是反的。
                });
                // rtm.sendPeerMessage(p2, props.model.userId);
                props.sendCMDMessage("dimMsg", resType);
                props.setVague(!props.vague);
                console.log(
                  "%c VAGUE SET TO DAKAIMOHU:%o",
                  "color:green",
                  p2,
                  !props.vague
                );
              }
            );
          }}
        >
          <i className="iconfont icon-mohumopi"></i>
          <span className="font-size15 font-color3">
            {/* 视频前模糊设置 */}
            {props.vague ? "关闭模糊" : "打开模糊"}
          </span>
        </div>
      </div>

      <audio
        id="ring"
        src="/static/sounds/ring.mp3"
        autoPlay={props.autoPlay}
        loop={true}
      />
    </div>
  );
};
// 正在通话中的组件
const VideoCall = (props) => {
  console.log("/=/通话时改变：", props);
  const [showInput, setShowInput] = useState(false);
  const [showgift, setshowgift] = useState(false);
  const { setInCall, channelName, onUserJoin } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [currentCamera, setCurrentCamera] = useState(0);
  const [showSwitchCamera, setShowSwitchCamera] = useState(true);
  const [cameras, setCameras] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [showTip, setShowTip] = useState(false);
  const [tipAction, setTipAction] = useState(null);
  const [tip, setTip] = useState({});
  const [showIndicator] = useState(props?.model?.wechatShowFlag != 0);

  const [showIntimacyDialog, setShowIntimacyDialog] = useState(false);
  // const [showHistory,setShowHistory] = useState(props.history&&props.history.length);
  // console.log('----------------------------------------------------------');
  // console.log('one2one1:',props.model);
  // console.log('%c history:','color:green',props.history.length);

  // using the hook to get access to the client object
  const client = useClient();
  // ready is a state variable, which returns true
  //when the local tracks are initialized, untill then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  console.log("/=/JOSN出错定位21，:", localStorage.getItem("profile"));
  const profile = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    // 设置模糊状态
    console.log("/=/设置模糊状态--------------------视频前只有ios需要知道状态");
    const resType = 0;
    checkVague(props.model.userId, resType, props.channelName).then((data) => {
      props.setVague(true);
      props.sendCMDMessage("dimMsg", resType);
    });
  }, []);

  useEffect(() => {
    window.futrue.onUserTakeScreenShotCallback = async (ret) => {
      console.log("=====>USER DID TAKE SCREEN SHOT:", ret);
      try {
        rtm.sendPeerMessage(
          JSON.stringify({
            code: 8 + "",
            type: "1",
          }),
          props.model.userId
        );
      } catch (err) {
        console.log(err);
      }
    };

    window.futrue.onUserChangeCapture = (ret) => {
      console.log("=====>USER DID CHANGE CAPTURE:", ret);
      try {
        rtm.sendPeerMessage(
          JSON.stringify({
            code: 8 + "",
            type: "2",
          }),
          props.model.userId
        );
      } catch (err) {
        console.log(err);
      }
    };

    // if(props.history&&props.history.length&&!showHistory){
    //     setShowHistory(true);
    // }

    // function to initialise the SDK
    let init = async (name) => {
      client.removeAllListeners();

      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success:", user, mediaType);
        if (mediaType === "video") {
          onUserJoin(user);
          setUsers((prevUsers) => {
            // return [...prevUsers, user];
            return [user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("connection-state-change", async (s) => {
        console.log("%c connection-state-changed:%o", "color:purple", s);
        if (s == "DISCONNECTED") {
          await client.leave();
          props.setCallHangup(true);
          props.setCallStatus(6);
        }
      });

      client.on("user-unpublished", async (user, type) => {
        console.log("%c user-unpublished: %o %o", "color:orange", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
        if (tracks) {
          if (tracks.length > 0) tracks[0].close();
          if (tracks.length > 1) tracks[1].close();
        }
        // await client.leave();
        // props.setCallHangup(true);
      });

      client.on("user-left", async (user) => {
        console.log("%c user-left: %o", "color:orange", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
        //all client leave.
        if (tracks) {
          if (tracks.length > 0) tracks[0].close();
          if (tracks.length > 1) tracks[1].close();
        }
        await client.leave();
        props.setCallHangup(true);
        props.setCallStatus(6);
        //props.dispatch({type:'hangup'});
      });
      console.log("/=/AGORA_APP_ID令牌无效1：", AGORA_APP_ID);
      console.log("/=/channelName令牌无效2：", name);
      console.log("/=/props.token令牌无效3：", props.token);
      console.log("/=/props.token令牌无效3：", props.uid);
      // 角色 role 设为 "host" （主播）或 "audience"（观众）
      // client.setClientRole("host"); 在rtc启动页面已写死
      await client.join(AGORA_APP_ID, name, props.token, props.uid);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks) {
      console.log("init ready");
      init(channelName);
    }

    !cameras &&
      AgoraRTC.getCameras(true).then((data) => {
        console.log("cameras:", data);
        if (data && data.length > 1) {
          setShowSwitchCamera(true);
          // setCameras(data);
        }
        setCameras(data);
        // else setShowSwitchCamera(false);
      });

    return async () => {
      // we close the tracks to perform cleanup
      // try{
      //     await client.leave();
      //     client.removeAllListeners();
      //     tracks[0].close();
      //     tracks[1].close();
      // }
      // catch(err){
      //     console.error(err);
      // }
    };
  }, [channelName, client, ready, tracks, setShowSwitchCamera]);

  useEffect(() => {
    if (props.renewToken) {
      console.log("===>renew token");
      client.renewToken(props.renewToken);
    }
  }, [props.renewToken]);

  const [rtmMessage, setRtmMessage] = useState("");
  const [balance, setBalance] = useState(props.balance || 0);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);

  let tipTimer = null;

  return (
    <div className="one2one">
      {/* <video className='main-video' poster='/static/images/live-03.png'/> */}
      {/* <video className='other-video' poster='/static/images/live-02.png'></video> */}
      {start && tracks && (
        <Videos vague={props.vague} users={users} tracks={tracks} />
      )}

      <div className="main-home fx-box fx-ai-c fx-jc-sb">
        <div className="main-home-avatar fx-box">
          <img src={props?.model?.headUrl ?? DefaultHeadUrl} />
        </div>
        <div className="one2one-user-info theme-font fx-box fx-fd-c fx-jc-c fx-ai-s">
          <div className="font-size12 font-color3 one2one-nickname">
            {props?.model?.nickName}
          </div>
          <div className="one2one-user-btn text-center font-size11 font-color3">
            lv{props?.model?.playerLeven}
          </div>
        </div>
      </div>

      {/* <div className='main-coast theme-font font-size11 font-color3'>消费 23000</div> */}

      <div className="main-menu fx-box theme-font">
        <div className="one2one-menuItem fx-box fx-ai-c fx-jc-c">
          <i className="iconfont icon-more"></i>
        </div>
        <div
          className="one2one-menuItem font-size10 font-color3 text-center "
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          清屏
        </div>
      </div>
      {showAll ? (
        <>
          <div className="main-menu-pause fx-box fx-fd-c fx-ai-c fx-jc-c theme-font">
            {props.expTime <= 120 ? (
              <div className="menu-pause-state font-size11 font-color3 fx-box fx-jc-sb">
                <span>剩余{props.expTime}秒</span>
                <div
                  className="tocharge"
                  onClick={() => {
                    setShowChargeModal(true);
                  }}
                >
                  去充值
                </div>
              </div>
            ) : null}
            <div
              className="pause-btn fx-box fx-jc-c fx-ai-c"
              onClick={async () => {
                props.setCallStatus(6);

                if (tracks) {
                  if (tracks.length > 0) tracks[0].close();
                  if (tracks.length > 1) tracks[1].close();
                }
                await client.leave();
                props.setCallHangup(true);
              }}
            >
              {/* 正在通话中、允许挂断电话 */}
              <img src="/static/images/hangup.png" className="calling-hangup" />
            </div>
          </div>
          <div className="main-menu-bar">
            {showSwitchCamera ? (
              <div
                className="theme-font fx-box fx-fd-c fx-ai-c"
                onClick={() => {
                  let newIdx;
                  for (newIdx = 0; newIdx < cameras.length; newIdx++) {
                    if (
                      cameras[newIdx].label != cameras[currentCamera * 1].label
                    ) {
                      console.log(
                        "\n[CAMERA]found camera idx:",
                        newIdx,
                        cameras[newIdx]
                      );
                      setCurrentCamera(newIdx);
                      tracks[1].setDevice(cameras[newIdx].deviceId);
                      break;
                    }
                  }
                }}
              >
                <div className="main-menu-barItem fx-box fx-jc-c fx-ai-c">
                  <i className="iconfont icon-2"></i>
                </div>
                <div className="font-size11 font-color3">翻转镜头</div>
              </div>
            ) : null}

            {/* <div className='theme-font fx-box fx-fd-c fx-ai-c'>
                <div className='main-menu-barItem fx-box fx-jc-c fx-ai-c'> 
                    <i className='iconfont icon-meiyanxianxing'></i>
                </div>
                <div className='font-size11 font-color3'>美颜</div>
            </div> */}

            <div className="theme-font fx-box fx-fd-c fx-ai-c">
              <div
                className="main-menu-barItem fx-box fx-jc-c fx-ai-c"
                onClick={() => {
                  console.log("try to show input");
                  setShowInput(!showInput);
                }}
              >
                <i
                  className="iconfont icon-jianpan"
                  onClick={() => {
                    console.log("try to show input");
                    setShowInput(!showInput);
                  }}
                ></i>
              </div>
              <div
                className="font-size11 font-color3"
                onClick={() => {
                  console.log("try to show input");
                  setShowInput(true);
                }}
              >
                聊天
              </div>
            </div>
          </div>
          <div
            className="main-menu-dim fx-box fx-ai-c fx-jc-c theme-font"
            onClick={() => {
              // setVague(props.model.userId).then(data=>{
              //     if(data!=0)Toast.info(data.errMsg);
              // })
              console.log("/=/视频通话设置前的模糊状态：", props.vague);
              const resType = props.vague ? 1 : 0;
              checkVague(props.model.userId, resType, props.channelName).then(
                (data) => {
                  // console.log(data);
                  props.setVague(!props.vague);
                  // rtm.sendPeerMessage(
                  //   JSON.stringify({
                  //     code: 3 + "",
                  //     visiable: props.vague ? "0" : "1", //跟当前vague是反的。
                  //   }),
                  //   props.model.userId
                  // );
                  props.sendCMDMessage("dimMsg", resType);
                }
              );
            }}
          >
            <i className="iconfont icon-mohumopi"></i>
            <span className="font-size15 font-color3">
              {/* 视频通话时模糊设置 */}
              {props.vague ? "关闭模糊" : "打开模糊"}
            </span>
          </div>
          {showIndicator ? (
            <div
              onClick={() => {
                setShowIntimacyDialog(true);
              }}
              className="one2one-intimacy"
            >
              <div className="one2oneItem fx-box fx-fd-c  fx-jc-c fx-ai-c">
                {/* <i className='iconfont icon-liwu1'></i> */}
                <img
                  src="/static/images/intimacy.png"
                  className="calling-gift"
                ></img>
              </div>
            </div>
          ) : null}
          <div
            onClick={() => {
              setshowgift(true);
            }}
            className="one2one-gift"
          >
            <div className="one2oneItem fx-box fx-fd-c  fx-jc-c fx-ai-c">
              {/* <i className='iconfont icon-liwu1'></i> */}
              <img
                src="/static/images/o2ogift.png"
                className="calling-gift"
              ></img>
            </div>
          </div>

          {showgift ? (
            <GiftDialog
              scenesType={3}
              balance={balance || props.balance}
              onGiveGift={(giftId, nmbs, giftName, icon) => {
                console.log("onSend...");
                console.log("/=/送礼备注：", props);
                giveGift(props.model.userId, giftId, nmbs)
                  .then((ret) => {
                    console.log("give gift:", ret);
                    if (ret.code == 0) {
                      addMessageByUser(props.model.userId, "一条礼物消息").then(
                        (data) => {
                          console.log(
                            "%c addMessageByUser return:%o %o",
                            "color:green",
                            data
                          );
                          if (data.code == 0)
                            giftSend({
                              messageId: data.messageId,
                              giftId,
                              nmbs,
                              postHeader: props.model?.image ?? DefaultHeadUrl,
                              postName: props.model?.nickname ?? "",
                              sendHeader: profile?.headUrl,
                              sendNickname: profile?.nickname,
                              to: "socialfu_" + props.model.userId,
                              from: profile?.hxUserName,
                              dispatch: props.dispatch,
                              icon,
                              giftName,
                              price: ret.price,
                            });
                          else Toast(data.errMsg);
                        }
                      );

                      setBalance(ret.userBalance);

                      clearTimeout(tipTimer);

                      // (nmbs || 0) * 1 + (tip.count || 0) * 1,
                      const countCount =
                        tip && tip.name === giftName ? tip.count + nmbs : nmbs;

                      setTip({
                        name: giftName,
                        count: countCount,
                        icon: icon,
                      });

                      setShowTip(true);
                      setTipAction("open");

                      tipTimer = setTimeout(() => {
                        setTipAction("close");
                      }, 3500);
                      // 20240730  取消rtm
                      // rtm.sendPeerMessage(
                      //   JSON.stringify({
                      //     code: 4 + "",
                      //   }),
                      //   props.model.userId
                      // );

                      //更新剩余聊天时间--20240730  删除更新时间
                      // props?.updateTime && props?.updateTime();
                      //send rtm message
                      /*
                                    messageModel.code = 2;
                            messageModel.avatar = model.headUrl;
                            messageModel.giftId = (int) button.tag;
                            messageModel.nmbs = nmbs;
                            
                            //发送消息
                            AgoraRtmMessage *rtmKit = [[AgoraRtmMessage alloc] initWithText:[[NSDictJsonTool nSDictJsonTool] modelToJson:messageModel]];
                            AppDelegate *appDelegate = (AppDelegate*)[UIApplication sharedApplication].delegate;
                            [appDelegate.signalEngine sendMessage:rtmKit toPeer:self.remoteAccount completion:nil];
                                */
                      // rtm.sendPeerMessage(
                      //   JSON.stringify({
                      //     code: 2 + "",
                      //     avatar: profile.headUrl,
                      //     giftId: giftId + "",
                      //     nmbs: nmbs + "",
                      //   }),
                      //   props.model.userId
                      // );
                    } else if (ret.code == 709 || ret.code == 701) {
                      setShowDeposit(true);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
              onCharge={() => {
                setShowChargeModal(true);
              }}
              onClose={() => {
                setshowgift(false);
              }}
            />
          ) : (
            <div></div>
          )}
          {showIntimacyDialog ? (
            <IntimacyDialog
              intimacy={props?.model?.userIntimacy}
              intimacyLine={props?.model?.intimacy}
              modelHead={props?.model?.headUrl}
              myHead={profile.headUrl || DefaultHeadUrl}
              modelId={props?.model?.userId}
              wx={props?.model?.wechat}
              onClose={() => {
                setShowIntimacyDialog(false);
              }}
            ></IntimacyDialog>
          ) : null}
        </>
      ) : null}
      {showTip ? <GiftTip openTip={tipAction} tip={tip} /> : null}

      <MsgDialog
        show={props.history && props.history.length > 0}
        onClose={() => {
          props.onClear && props.onClear();
        }}
        messages={props.history}
      />

      <MsgInput
        show={showInput}
        onSend={(e) => {
          let messageObj = {
            avatar: null,
            giftId: 0,
            visiable: 0,
            killId: 0,
            code: 10,
            message: e.title,
            nmbs: 0,
            type: 0,
          };
          // rtm.sendPeerMessage(JSON.stringify(messageObj), props.model.userId);
          props.onSendMessage(messageObj);
          props.sendCMDMessage("sendMessage", e.title);
          // setShowHistory(true);
        }}
      />
      <Overlay
        zIndex={1002}
        visible={showChargeModal}
        onClick={() => setShowChargeModal(false)}
      >
        <ChargeModal
          onClose={() => {
            console.log("im called.");
            setShowChargeModal(false);
          }}
        />
      </Overlay>

      <DepositDialog
        onClose={() => {
          setShowDeposit(false);
        }}
        visible={showDeposit}
      />
    </div>
  );
};

// const Controls = (props)=>{
//     const {audioTrack,tracks} = props;
//     console.log('audioTrack:',audioTrack);
//     if(tracks&&tracks.map)tracks.map((track)=>{
//         console.log('%c [DEBUG]track:%o','color:blue',track.trackMediaType);
//     });
//     return(
//         <div style={{position:'absolute',top:100,fontSize:36}} >
//             <button onClick={()=>{
//                 audioTrack?.setVolume(audioTrack._volume+50);
//                 console.log(audioTrack._volume);
//             }}>UP</button>
//             <button onClick={()=>{
//                 if(audioTrack?._volume<=0)return;
//                 audioTrack?.setVolume(audioTrack._volume-50);
//                 console.log(audioTrack._volume);
//             }}>DOWN</button>
//             <button>[{audioTrack?._volume??0}]</button>

//         </div>
//     )
// }

var playbackDeviceIndex = 0;

const Videos = (props) => {
  const { users, tracks } = props;

  //   console.log('%c is vague? %o','color:blue',props.vague);
  return (
    <div className="videos">
      <div id="videos" className="videos-1">
        {/* AgoraVideoPlayer component takes in the video track to render the stream,
            you can pass in other props that get passed to the rendered div */}

        {users.length > 0 &&
          users.map((user) => {
            if (user.videoTrack) {
              if (user.audioTrack) {
                // user.audioTrack.setVolume(200);
                //user.audioTrack.setPlaybackDevice()
              }
              return (
                <AgoraVideoPlayer
                  className="vid-remote"
                  videoTrack={user.videoTrack}
                  key={user.uid}
                />
              );
            } else return null;
          })}
        <AgoraVideoPlayer
          className="vid-local"
          videoTrack={tracks[1]}
          onClick={() => {
            console.log("=====>video player clicked");
          }}
        />
        {props.vague ? (
          <div
            className="vid-local"
            style={{ backgroundColor: "rgba(99, 99, 99, 0.98)" }}
            onClick={() => {
              AgoraRTC.getPlaybackDevices().then((data) => {
                console.log("/=/这是哪儿111", data);
                if (playbackDeviceIndex >= data.length) playbackDeviceIndex = 0;
                console.log(data[playbackDeviceIndex].deviceId);
                users[0].audioTrack.setPlaybackDevice(
                  data[playbackDeviceIndex].deviceId
                );
                playbackDeviceIndex++;
              });
            }}
          ></div>
        ) : null}
        {/* <Controls tracks={tracks} audioTrack={users[0]?.audioTrack}/>    */}
      </div>
    </div>
  );
};

// export default One2one;
export default connect(
  (state) => {
    return {
      videoStatusCancel: state.app.videoStatusCancel,
      videoStatusCancel2: state.app.videoStatusCancel2,
      videoStatusJoin: state.app.videoStatusJoin,
      videoStatusText: state.app.videoStatusText,
      videoStatusToken: state.app.videoStatusToken,
    };
  },
  (dispatch) => ({ dispatch })
)(One2one);
