import {v1} from 'uuid';
var DEVICE_NO = null;
// var DEVICE_NO='6CB53030-DC60-4674-80E4-569CBE0C68E7';
export const getDeviceNo=()=>{
    if(DEVICE_NO==null){
        DEVICE_NO= localStorage.getItem('fu_device_no');
        if(!DEVICE_NO) {
            DEVICE_NO=v1().toUpperCase();
            localStorage.setItem('fu_device_no',DEVICE_NO);
        }
    }

    return DEVICE_NO;
}

export const requestId = '01ae5e36a64805aa';
export const deviceId = getDeviceNo().slice(0,16);
// export const deviceId = DEVICE_NO.slice(0,16);

export const TIME_STAMP = new Date().valueOf();

export const CONST_TOP_ADJUST_HEIGHT ='0.3rem';


export const kefuID = 'socialfu_138002'

export const appVersion='1.1.3' // 自定义版本号
// export const dev_prod=true    // 自定义设置是否为开发环境还是正式环境

//production
//export const VERSION='1.0.2';
//export const APP_VERSION_KEY='D6AFD152523569EA47C3FD6F';

// 正式环境--开始
export const VERSION = 'h5apply'
export const APP_VERSION_KEY='CD91F74E63D38C8E0A70A821BB018B81'
export const HOST = 'https://fuh5gw.douquwl88.cn/';
export const AGORA_APP_ID='5143d36132d3421aaa44616ba0737dd0';
export const ENV = 'prod'
// 正式环境--结束

//dev
// export const APP_VERSION_KEY = "RKx62IXV6ZCtWwoDmsl6HoqEZxFKWWKO";
// export const VERSION = "1.0.1";

//  测试环境--开始
// export const VERSION = 'h5apply'
// export const APP_VERSION_KEY='CD91F74E63D38C8E0A70A821BB018B81'
// export const HOST = 'https://fuh5gw-t01.douquwl88.cn/';
// export const AGORA_APP_ID='65a62074384549aea9b0dbf4408e49ae';
// export const ENV = 'dev'
//  测试环境--结束

const  gifts =[
    {
        id: 28,
        name:'新春福饺',
        icon:'',
        price: '666'
    },
    {
       id:4,
       name:'么么哒',
       icon:'',
       price:'58'	
   },
   {
       id:1,
       name:'小香蕉',
       icon:'',
       price:'158'	
   },
   {
       id:2,
       name:'杜蕾斯',
       icon:'',
       price:'258'	
   },
   {
       id:5,
       name:'告白气球',
       icon:'',
       price:'520'	
   },
   {
       id:6,
       name:'比心',
       icon:'',
       price:'1024'	
   },
   {
       id:14,
       name:'海洋珍珠',
       icon:'',
       price:'2888'	
   },
   {
       id:7,
       name:'黄金跑车',
       icon:'',
       price:'5200'	
   },
   {
       id:8,
       name:'飞机',
       icon:'',
       price:'8888'	
   },
       {
           id:3,
           name:'仙女棒',
           icon:'',
           price:'99'	
       },
       {
           id:9,
           name:'小黄瓜',
           icon:'',
           price:'199'	
       },
       {
           id:10,
           name:'口红',
           icon:'',
           price:'399'	
       },
       {
           id:13,
           name:'一箭穿心',
           icon:'',
           price:'666'	
       },
       {
           id:11,
           name:'粉玫瑰',
           icon:'',
           price:'999'	
       },
       {
           id:12,
           name:'抱抱熊',
           icon:'',
           price:'1314'	
       },
       {
           id:21,
           name:'包你满意',
           icon:'',
           price:'3999'	
       },
       {
           id:15,
           name:'刷我滴卡',
           icon:'',
           price:'13140'	
       },
       {
           id:19,
           name:'钻戒',
           icon:'',
           price:'9999'	
       },
       {
           id:16,
           name:'游轮',
           icon:'',
           price:'18888'	
       },
       {
           id:25,
           name:'飞艇',
           icon:'',
           price:'29999'	
       },
       {
           id:18,
           name:'梦幻城堡',
           icon:'',
           price:'66666'	
       },
       {
           id:17,
           name:'爱心火箭',
           icon:'',
           price:'88888'	
       },
       {
           id:20,
           name:'飞碟',
           icon:'',
           price:'131425'	
       }
   ];
export const GiftDict={};
gifts.forEach(g=>{
    g.icon = '/static/images/gift/'+g.name+'.png';
    GiftDict[g.id]=g;
})
