import React from 'react';
import Navigation from "../../components/navigation/navigation";
import { useNavigate } from 'react-router-dom'
import './Setting.css';
import {rtm} from '../../components/agora/rtm';
import WebIM from '../../components/easemob/WebIM';
import store from '../../redux/store';
import { CONST_TOP_ADJUST_HEIGHT } from '../../service/config';



const Setting=()=>{
      const navigate=useNavigate();
      let sets=[
        {
            title:'意见反馈',
            path:'/feedback'
        },
        {
            title:'关于我们',
            path:'/aboutus'
        }
      ]
      //style={{top:CONST_TOP_ADJUST_HEIGHT}}
      return(
      <div className='set-page hide-x theme-font'>

          <Navigation  theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'设置'}></Navigation>

          <div className='fx-box fx-jc-c'>
                <div className='set-page-menu layout-mt70'>
                    {
                        sets.map((item,index)=>{
                            return (
                            <div  key={index} onClick={()=>{
                                navigate(item.path)
                            }}  className='set-page-menuItem full-r-w fx-box fx-jc-sb fx-ai-c'>
                                <div className='font-size15 font-w5'>{item.title}</div>
                                <div className='fx-box fx-ai-c'>
                                <i className='iconfont icon-weibiaoti--'></i>
                                </div>
                            </div>
                        )
                        })
                    }
                    </div>
          </div>
          <div className="fx-btn layout-fx set-submit fx-box fx-jc-c full-r-w" onClick={
            async ()=>{
                try{
                    WebIM.detachDB();
                    localStorage.removeItem('profile');
                    localStorage.removeItem('appStart');
                    store.dispatch({type:'LOGOUT'});
                    // await rtm.logout();
                    await WebIM.conn.close();
                }
                catch(err){
                    console.error(err);
                }
                
                navigate('/login');
            }
          }>
               <div className="fx-submit btn-bg1 font-size15 font-w6 font-color3">退出登录</div>
           </div>


      </div>);
}
export default Setting;
