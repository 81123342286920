import WebIM, { initConn } from './components/easemob/WebIM';
import store from './redux/store';
import {rtm,initSLSFunc} from './components/agora/rtm';

import * as service from './service';

export const initAll=(profile,appInfo,store)=>{
    console.log('\n\n\n\n\n\n');
    console.log('appInfo:',appInfo);
    localStorage.setItem('appStart',JSON.stringify(appInfo));
    return service.queryBanner().then(ret=>{
        console.log('%c[DEBUG]queryBanner:','color:blue',ret);
        store.dispatch({type:'SHOW_BANNER',payload:ret.data});
    }).then(()=>{
        store.dispatch({type:'LIVE_OTO_TAB',payload:appInfo?.tags?.oto});
        initConn(appInfo.easemob_config.accessKey);
        appInfo.mqtt.hostname=appInfo.mqtt.endpoint.split('//').pop();
        window.mqtt(appInfo.mqtt,(p)=>{
            console.log('%c[DEBUG]mqtt callback:','color:cyan',p)
            console.log('/=/JOSN出错定位1，:',p);
            const obj = JSON.parse(p);
            if(obj.m=='consume'){
                store.dispatch({type:'show_consume',payload:obj.b});
            }
            else if(obj.m=='pay'){
                store.dispatch({type:'show_recharge',payload:obj.b});
            }
        });

        return appInfo;
    })
    // .then(app=>{
    //     console.log('[INFO]try to login rtm:',profile,app.rtmToken);
    //     if(!rtm._logined){
    //         rtm.on('ConnectionStateChanged',(ns,rs)=>{
    //             console.log('%c rtm connection info:'+ns+' '+rs,'color:green');
    //         })
    //         console.log('\n\n\n\n%c userId:%o,rtmTopek:%o','color:blue',profile,app.rtmToken);
    //         return rtm.login(profile.userId+'',app.rtmToken)
    //     }
    // })
    .then(()=>{
        initSLSFunc() // 启动阿里云日志
        const options = {
            apiUrl: WebIM.config.apiURL,
            user: profile.hxUserName,
            pwd: profile.hxUserName.split('_')[1],
            appKey: WebIM.config.appkey
        };
        console.log('/=/这是哪儿：',options);
        store.dispatch({type:'IM_LOGIN',payload:options});
        setTimeout(()=>{
            console.log('start easemob connect ....',options);
            WebIM.conn.open(options);},2500);
    })
}
    
    
            

       