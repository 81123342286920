import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Swiper, Image, Overlay } from "react-vant";
import "./gift-dialog.css";
import * as service from "../../service";
const GiftDialog = (props) => {

  const navigate = useNavigate();
  const [showNumberPopup, setShowNumberPopup] = useState(false);
  const [showCountInput, setShowCountInput] = useState(false);
  const [giftCount, setGiftCount] = useState(1);
  const [giftList, setGiftList] = useState(null); // 礼物列表
  const [tmpGiftCount, setTmpGiftCount] = useState("");
  const gift_config = [
    {
      gifts: [
        {
          id: 28,
          name: "新春福饺",
          icon: "",
          price: "666",
        },
        {
          id: 4,
          name: "么么哒",
          icon: "",
          price: "58",
        },
        {
          id: 1,
          name: "小香蕉",
          icon: "",
          price: "158",
        },
        {
          id: 2,
          name: "杜蕾斯",
          icon: "",
          price: "258",
        },
        {
          id: 5,
          name: "告白气球",
          icon: "",
          price: "520",
        },
        {
          id: 6,
          name: "比心",
          icon: "",
          price: "1024",
        },
        {
          id: 14,
          name: "海洋珍珠",
          icon: "",
          price: "2888",
        },
        {
          id: 7,
          name: "黄金跑车",
          icon: "",
          price: "5200",
        },
      ],
    },
    {
      gifts: [
        {
          id: 3,
          name: "仙女棒",
          icon: "",
          price: "99",
        },
        {
          id: 9,
          name: "小黄瓜",
          icon: "",
          price: "199",
        },
        {
          id: 10,
          name: "口红",
          icon: "",
          price: "399",
        },
        {
          id: 13,
          name: "一箭穿心",
          icon: "",
          price: "666",
        },
        {
          id: 11,
          name: "粉玫瑰",
          icon: "",
          price: "999",
        },
        {
          id: 12,
          name: "抱抱熊",
          icon: "",
          price: "1314",
        },
        {
          id: 21,
          name: "包你满意",
          icon: "",
          price: "3999",
        },
        {
          id: 15,
          name: "刷我滴卡",
          icon: "",
          price: "13140",
        },
      ],
    },
    {
      gifts: [
        {
          id: 19,
          name: "钻戒",
          icon: "",
          price: "9999",
        },
        {
          id: 16,
          name: "游轮",
          icon: "",
          price: "18888",
        },
        {
          id: 25,
          name: "飞艇",
          icon: "",
          price: "29999",
        },
        {
          id: 18,
          name: "梦幻城堡",
          icon: "",
          price: "66666",
        },
        {
          id: 8,
          name: "飞机",
          icon: "",
          price: "8888",
        },
        {
          id: 17,
          name: "爱心火箭",
          icon: "",
          price: "88888",
        },
        {
          id: 20,
          name: "飞碟",
          icon: "",
          price: "131425",
        },
      ],
    },
  ];
  console.log("/=/礼物也：",props);
  //   1: 全部 (默认) 2: 1v1语音聊天 3: 1v1视频聊天 4: 直播
  if (!giftList) {
    const num=props.scenesType?props.scenesType:1
    service.giveGiftList(num).then((data) => {
      console.log("....礼物列表:", data);
      data.data.forEach((item, index) => {});
      let newArr = [];
      for (let i = 0; i < data.data.length / 8; i++) {
        newArr[i] = data.data.slice(8 * i, 8 * (i + 1));
      }
      console.log(newArr);
      setGiftList(newArr);
    });
  }

  const createPoint = (total, current) => {
    let size = [...new Array(total).keys()];
    let arr = size.map((item, idx) => {
      if (idx === current) {
        return (
          <div
            key={"gift-active-id-" + idx}
            style={{ width: "calc(0.5rem/" + total + ")" }}
            className="gift-pagation-item gift-pagation-item-active"
          ></div>
        );
      } else {
        return (
          <div
            key={"gift-active-id-" + idx}
            style={{ width: "calc(0.5rem/" + total + ")" }}
            className="gift-pagation-item"
          ></div>
        );
      }
    });
    return arr;
  };
  const [selectedGift, setSelectedGift] = useState(null);
  return (
    <div
      className="gift-mask"
      onClick={() => {
        props.onClose();
      }}
    >
      <div
        onClick={(e) => {
          e.nativeEvent.stopPropagation();
          e.stopPropagation();
        }}
        className="gift-container theme-font layout-rel"
        style={{ ...props.style }}
      >
        <div className="font-size13 gift-title">
          赠大V一个小礼物吧，开启心动时刻
        </div>
        {giftList ? (
          <Swiper
            indicator={(total, current) => (
              <div className="full-r-w gift-pagation fx-box fx-jc-c fx-ai-c">
                <div className="gift-pagation-cxt fx-box fx-ai-c">
                  {createPoint(total, current)}
                </div>
              </div>
            )}
          >
            {
              //   gift_config.map((item,index)=>{
              //      return (
              //      <Swiper.Item key={'gift-item-id-'+(index+1)}>
              //           <div className='gift-board'>
              //                 {
              //                   item.gifts.map((cItem,cIndex)=>{
              //                      return (
              //                      <div  key={'gift-board-item-id-'+(cIndex+1)} className={'gift-board-item fx-box fx-fd-c fx-ai-c '+(selectedGift?.id==cItem.id?'selected-gift':'')} onClick={()=>{
              //                         setSelectedGift(cItem);
              //                      }}>
              //                      <img alt="" src={'/static/images/gift/'+cItem.name+'.png'}/>
              //                      <span className='font-size13 font-color3 text-nowrap'>{cItem.name}</span>
              //                      <span className='font-size10 font-color3'>{cItem.price}金币</span>
              //                    </div>)
              //                   })
              //                 }
              //           </div>

              //      </Swiper.Item>)
              //   })
              giftList?.map((item, index) => {
                console.log("/=/几步：", index);
                return (
                  <Swiper.Item key={"gift-item-id-" + (index + 1)}>
                    <div className="gift-board">
                      {item.map((cItem, cIndex) => {
                        return (
                          <div
                            key={"gift-board-item-id-" + (cIndex + 1)}
                            className={
                              "gift-board-item fx-box fx-fd-c fx-ai-c " +
                              (selectedGift?.id == cItem.id
                                ? "selected-gift"
                                : "")
                            }
                            onClick={() => {
                              setSelectedGift(cItem);
                            }}
                          >
                            <img alt="" src={cItem.showIcon} />
                            <span className="font-size13 font-color3 text-nowrap">
                              {cItem.name}
                            </span>
                            <span className="font-size10 font-color3">
                              {cItem.price}金币
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </Swiper.Item>
                );
              })
            }
          </Swiper>
        ) : null}

        <div className="full-r-w gift-tools fx-box fx-jc-sb">
          <div className="fx-box fx-ai-c charge-tools">
            <Image
              width="0.16rem"
              height="0.16rem"
              round
              src="/static/images/gold-icon.png"
            />
            <span className="font-size13 font-color3 layout-ml4">
              {props.balance}
            </span>
            <span
              style={{ display: "block" }}
              className="font-size13 font-color3 layout-ml4 charge-text"
              onClick={() => {
                if (props.onCharge) props.onCharge();
                else navigate("/pay");
              }}
            >
              {"充值>"}
            </span>
          </div>

          <div className="gift-button fx-box">
            <div className="gift-button-item1 fx-box fx-ai-c fx-jc-c font-size11 font-color3">
              <span>{giftCount}</span>
              <i
                className="iconfont font-size11 icon-weibiaoti-- font-w5"
                onClick={() => {
                  setShowNumberPopup(!showNumberPopup);
                }}
              ></i>
            </div>
            <div
              className="gift-button-item2 fx-box fx-ai-c fx-jc-c font-size11 font-color3"
              onClick={() => {
                if (!selectedGift) return;
                props.onGiveGift &&
                  props.onGiveGift(
                    selectedGift?.id,
                    giftCount,
                    selectedGift.name,
                    selectedGift.showIcon
                  );
              }}
            >
              赠送
            </div>
          </div>
        </div>
        <Overlay
          visible={showNumberPopup}
          style={{ position: "absolute", bottom: 0, right: 0 }}
          onClose={() => {
            setShowNumberPopup(false);
          }}
          onClick={() => {
            setShowNumberPopup(false);
          }}
        >
          <div className="gift-number-popup font-size13">
            <div
              className="gift-number-item"
              onClick={() => {
                setGiftCount(1);
                setShowNumberPopup(false);
              }}
            >
              <div className="gift-number-item-number">1</div>
              <div className="fx-1">一心一意</div>
            </div>
            <div
              className="gift-number-item"
              onClick={() => {
                setGiftCount(99);
                setShowNumberPopup(false);
              }}
            >
              <div className="gift-number-item-number">99</div>
              <div className="fx-1">天长地久</div>
            </div>
            <div
              className="gift-number-item"
              onClick={() => {
                setGiftCount(520);
                setShowNumberPopup(false);
              }}
            >
              <div className="gift-number-item-number">520</div>
              <div className="fx-1">我爱你</div>
            </div>
            <div
              className="fx-box fx-jc-c gift-number-color"
              onClick={() => {
                setShowCountInput(true);
              }}
            >
              <div>其他数量</div>
            </div>
          </div>
        </Overlay>
        <Overlay
          visible={showCountInput}
          style={{ position: "absolute", left: 0, right: 0 }}
        >
          <div className="fx-box fx-fd-r gift-num-input-dialog">
            <div className="fx-1">
              <input
                type="text"
                placeholder="请输入打赏数量"
                value={tmpGiftCount}
                onChange={(e) => {
                  setTmpGiftCount(e.target.value);
                }}
              ></input>
            </div>
            <div>
              <button
                onClick={() => {
                  setGiftCount(tmpGiftCount);
                  setShowCountInput(false);
                }}
              >
                确定
              </button>
            </div>
          </div>
        </Overlay>
      </div>
    </div>
  );
};

export default GiftDialog;
