import React, { useEffect ,useRef,useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import Navigation from "../../../components/navigation/navigation";
import * as service from '../../../service';
import './Record.css';
const Record=()=>{
    // const profile = JSON.parse(localStorage.getItem('profile'));
    const [blist,setList] = useState(null);
    const listRef = useRef();

    
    useEffect(()=>{
        if(!blist){
            service.convList().then(data=>{
                console.log('%c o2o list:%o','color:green',data);
                setList(data.conversationList);
            })
        }
        
        const handleScroll=()=>{
            const scrollTop = document.getElementById('record-list').scrollTop;
            const screenHeight = window.screen.height;
            const offsetHeight =  document.getElementById('record-list').scrollHeight;
            
            console.log('%c scroll event adjust:%d,%d,%d','color:green',scrollTop,screenHeight,offsetHeight);

            if (scrollTop >= (offsetHeight - screenHeight * 1.5)) {
                service.convList(blist.length).then(data=>{
                    setList([...blist,...data.conversationList])
                })
            }
        }
        const list = listRef.current;
        list.addEventListener('scroll', handleScroll);
        return () => {
            // list.removeEventListener('touchmove', handleScroll);
            list.removeEventListener('scroll', handleScroll);
        };

    },[setList,blist])

      return (
        <div className="comment-page hide-x fx-box fx-fd-c">
           <Navigation  style={{paddingTop:30}} theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'通话记录'}></Navigation>
           <div id='record-list' className='record-list full-r-w fx-box fx-1 fx-fd-c' ref={listRef} style={{paddingTop:'0.8rem'}}>
                 {
                    blist?.map((item,index)=>{
                        return ( <div key={index} className='commentItem fx-box fx-jc-sb fx-ai-c'>
                        <div className='fx-box fx-1'>
                            <Link to ={'/person/'+item.userId}><Image width={'0.41rem'} height={'0.41rem'} round src={item.headUrl}/></Link>
                            <div className='fx-box fx-1 fx-fd-c'>
                                <div className='record-userid fx-box fx-fd-r  fx-jc-sb'>
                                    <div className='font-size15 fx-1'>{item.nickname}</div>
                                </div>
                                
                                <div className='font-size13 font-color6'>
                                        {item.remark}
                                </div>
                            </div>
                            <div className='font-size13 font-color6 conv-time'>
                                {item.createTime}
                            </div>
                        </div>
                       
                    </div>)
                    })
                 }
           </div>
          
        </div>
      );
}

export default Record;