import { useEffect, useState } from "react";
import Navigation from "../../components/navigation/navigation";
import { useNavigate } from "react-router-dom";
import "./mineVipManage.css";
import { Switch, Overlay } from "react-vant";
import * as service from "../../service";
import { CONST_TOP_ADJUST_HEIGHT } from '../../service/config';

const MineVipManage = () => {
  let theme = {
    color: "#FFF",
    bg: "linear-gradient(138.47deg, #FF6161 -2.56%, #FD8F8F 76.52%)",
  };
  const navigate = useNavigate();
  const [myInfo, setMyInfo] = useState(null);
  const [hidden, setHidden] = useState(null); // 隐身模式
  const [isHideScreenPrompt, setisHideScreenPrompt] = useState(null); // 飘屏
  const [showHiddenDialog, setShowHiddenDialog] = useState(false); // 打开vip弹窗
  const [showHiddenDialog2, setShowHiddenDialog2] = useState(false); // 打开Svip弹窗
  //   /user/permission/checkPrivateMode.json  纯净模式
  useEffect(() => {
    service.queryMyselfInfo().then((data) => {
      console.log("....myInfo:", data);
      if (data.code == 21) {
        navigate("/login");
      }
      setMyInfo(data);
      setHidden(data?.user.isHidden);
      setisHideScreenPrompt(data?.user.isHideScreenPrompt);
    });
    console.log("/数据改变：");
  }, []); // 只执行一次：空数组[]，模拟 componentDidMount 这个生命周期
  return (
    <div className="mineVipManage">
      <Navigation
        style={{top:CONST_TOP_ADJUST_HEIGHT}}
        theme={theme}
        height={"0.5rem"}
        title={"VIP权限管理"}
      ></Navigation>
      <div>
        <div className="menuListItem fx-box fx-jc-sb">
          <div className="listItemIcon fx-box fx-ai-c">
            <span className="font-size13">隐身模式</span>
            <img
              className="mineVipManageImg"
              src="/static/images/mine-icon-question.png"
            />
            <img src="/static/images/mine-vip.png" />
          </div>
          <div className="right-menu fx-box fx-ai-c">
            <Switch
              defaultChecked
              checked={hidden}
              style={{ marginRight: 8 }}
              activeColor="#FC66FF"
              inactiveColor="#ffffff"
              onChange={(checked) => {
                if (myInfo.user.vipStatus !== "Effected") {
                  setShowHiddenDialog(true);
                  return;
                }
                console.log("/=/测试myinfo:", myInfo);
                service
                  .switchHiddenMode("STEALTH", checked ? "ON" : "OFF")
                  .then((ret) => {
                    console.log("/=/测试:", checked);
                    setHidden(checked);
                  });
              }}
            />
          </div>
        </div>
        <div className="menuListItem fx-box fx-jc-sb">
          <div className="listItemIcon fx-box fx-ai-c">
            <span className="font-size13">关闭飘屏</span>
            <img
              className="mineVipManageImg"
              src="/static/images/mine-icon-question.png"
            />
            <img src="/static/images/mine-svip.png" />
          </div>
          <div className="right-menu fx-box fx-ai-c">
            <Switch
              defaultChecked
              checked={isHideScreenPrompt}
              style={{ marginRight: 8 }}
              activeColor="#FC66FF"
              inactiveColor="#ffffff"
              onChange={(checked) => {
                if (myInfo.user.vipProductInfo.type !== "SUPER_VIP") {
                  setShowHiddenDialog2(true);
                  return;
                }

                service
                  .switchHiddenMode(
                    "HIDE_SCREEN_PROMPT",
                    checked ? "ON" : "OFF"
                  )
                  .then((ret) => {
                    setisHideScreenPrompt(checked);
                  });
              }}
            />
          </div>
        </div>
        <div className="menuListItem fx-box fx-jc-sb">
          <div className="listItemIcon fx-box fx-ai-c">
            <span className="font-size13 layout-mr8">纯净模式</span>
            <img src="/static/images/mine-svip.png" />
          </div>
          <div className="right-menu fx-box fx-ai-c font-size13"    onClick={() => {
              navigate("/mineVipManagePrivateMode");
            }}>
            {myInfo?.user.isPrivateMode ? <span className="mineVipManageColor">已开启</span> : "未开启"}
            <i className="iconfont icon-weibiaoti--"></i>
          </div>
        </div>
      </div>

      <Overlay
        visible={showHiddenDialog}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          background: "unset",
        }}
        onClick={() => setShowHiddenDialog(false)}
      >
        <div className="mine-vipClass mineVipManageBg">
          <p className="mineVipManageBgP">隐身模式</p>
          <p>开通VIP即可使用专属权限</p>
          <div
            className="mine-vipClassBtn"
            onClick={() => {
              navigate("/minePayVip");
            }}
          >
            立即开通
          </div>
        </div>
      </Overlay>
      <Overlay
        visible={showHiddenDialog2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          background: "unset",
        }}
        onClick={() => setShowHiddenDialog2(false)}
      >
        <div className="mine-vipClass mineVipManageBg">
          <p className="mineVipManageBgP">关闭飘屏</p>
          <p>开通SVIP即可使用专属权限</p>
          <div
            className="mine-vipClassBtn"
            onClick={() => {
              navigate("/minePayVip");
            }}
          >
            立即开通
          </div>
        </div>
      </Overlay>
    </div>
  );
};

export default MineVipManage;
