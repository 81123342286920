import AgoraRTM from "agora-rtm-sdk";
import EventEmitter from "events";
import { AGORA_APP_ID, appVersion } from "../../service/config";
import moment from "moment";

// apps2logd -> livelog
// AccessKey ID LTAI5tQvmBRrW2atgkM1YM26
// AccessKey Secret GVV5g4eXwp91h2rqkCgweHjbMINq9o

import SlsTracker from "@aliyun-sls/web-track-browser";
// 阿里云日志文档
// https://help.aliyun.com/zh/sls/developer-reference/use-web-tracking-sdk-for-javascript-to-collect-browser-logs?spm=a2c4g.11186623.0.0.6894419cYNYmfF

// 函数里面放置函数无法被调用，自定义一个容器：SLS容器、设备ID、用户ID
let trackerInit = null; 
let deviceidInit = null;
let userIdInit = null;
// 启动链接日志
export function initSLSFunc() {
  const opts = {
    host: "cn-shanghai.log.aliyuncs.com", // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
    project: "apps2logd", // Project名称。
    logstore: "livelog", // Logstore名称。
    time: 10, // 发送日志的时间间隔，默认是10秒。
    count: 10, // 发送日志的数量大小，默认是10条。
    topic: "FU_live", // 自定义日志主题。:通话时调用
    source: "h5",
  };
  trackerInit = new SlsTracker(opts); // 创建SlsTracker对象
  console.log("%c /=/链接sls", "color:green", trackerInit);
  deviceidInit = "h5_" + localStorage.getItem("fu_device_no");
  userIdInit = JSON.parse(localStorage.getItem("profile"))?.userId;
  console.log("/=/ceshi:", deviceidInit, userIdInit);
}
// 在src/components/easemob/WebIM.js使用两次，src/pages/Live/One2one.js使用一次
export function writeLogs(code, parameters) {
  console.log("/=/开始上传");
  // 单条日志立即上传（time和count参数不生效）
  trackerInit.sendImmediate({
    code, // 2001 是收到的  2003 是发出的
    appver: appVersion,
    time: moment().format("YYYY-MM-DD HH:mm:ss"),
    // osver: "13",
    deviceid: deviceidInit,
    userId: userIdInit,
    parameters,
  });
}
export class RTMClient extends EventEmitter {
  constructor() {
    super();
    this.channels = {};
    this._logined = false;
  }

  init(appId) {
    this.client = AgoraRTM.createInstance(appId);
    this.subscribeClientEvents();
  }

  // subscribe client events
  subscribeClientEvents() {
    const clientEvents = [
      "ConnectionStateChanged",
      "MessageFromPeer",
      "RemoteInvitationReceived",
    ];
    clientEvents.forEach((eventName) => {
      this.client.on(eventName, (...args) => {
        console.log("emit ", eventName, ...args);
        // log event message
        this.emit(eventName, ...args);
      });
    });
  }

  // subscribe channel events
  subscribeChannelEvents(channelName) {
    const channelEvents = ["ChannelMessage", "MemberJoined", "MemberLeft"];
    channelEvents.forEach((eventName) => {
      this.channels[channelName].channel.on(eventName, (...args) => {
        console.log("emit ", eventName, args);
        this.emit(eventName, { channelName, args: args });
      });
    });
  }

  async login(accountName, token) {
    this.accountName = accountName;
    return this.client.login({ uid: this.accountName, token }).then((ret) => {
      this._logined = true;
      return ret;
    });
  }

  async logout() {
    this._logined = false;
    return this.client.logout();
  }

  async joinChannel(name) {
    console.log("joinChannel", name);
    const channel = this.client.createChannel(name);
    this.channels[name] = {
      channel,
      joined: false, // channel state
    };
    this.subscribeChannelEvents(name);
    return channel.join();
  }

  async leaveChannel(name) {
    console.log("leaveChannel", name);
    if (
      !this.channels[name] ||
      (this.channels[name] && !this.channels[name].joined)
    )
      return;
    return this.channels[name].channel.leave();
  }

  async sendChannelMessage(text, channelName) {
    if (!this.channels[channelName] || !this.channels[channelName].joined)
      return;
    return this.channels[channelName].channel.sendMessage({ text });
  }

  async sendPeerMessage(text, peerId) {
    console.log("sendPeerMessage", text, peerId);
    return this.client.sendMessageToPeer({ text }, peerId.toString());
  }

  async queryPeersOnlineStatus(memberId) {
    console.log("queryPeersOnlineStatus", memberId);
    return this.client.queryPeersOnlineStatus([memberId]);
  }

  //send image
  async uploadImage(blob, peerId) {
    const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
      messageType: "IMAGE",
      fileName: "agora.jpg",
      description: "send image",
      thumbnail: blob,
      // width: 100,
      // height: 200,
      // thumbnailWidth: 50,
      // thumbnailHeight: 200,
    });
    return this.client.sendMessageToPeer(mediaMessage, peerId);
  }

  async sendChannelMediaMessage(blob, channelName) {
    console.log("sendChannelMessage", blob, channelName);
    if (!this.channels[channelName] || !this.channels[channelName].joined)
      return;
    const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
      messageType: "IMAGE",
      fileName: "agora.jpg",
      description: "send image",
      thumbnail: blob,
      // width: 100,
      // height: 200,
      // thumbnailWidth: 50,
      // thumbnailHeight: 200,
    });
    return this.channels[channelName].channel.sendMessage(mediaMessage);
  }

  async cancelImage(message) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 1000);
    await this.client.downloadMedia(message.mediaId, {
      cancelSignal: controller.signal,
      onOperationProgress: ({ currentSize, totalSize }) => {
        console.log(currentSize, totalSize);
      },
    });
  }

  async sendLocalInvitation(param, callbacks) {
    //清除前一个localInvitation实例
    if (this.localInvitation) {
      this.localInvitation.removeAllListeners();
      this.localInvitation = null;
    }
    try {
      this.localInvitation = await this.client.createLocalInvitation(
        param.modelId
      );
      this.localInvitation.content = param.channelName;
      //console.log('[DEBUG]rtm localinvitation content:',this.localInvitation.content);
      this.localInvitation.on("LocalInvitationFailure", (r) => {
        console.log("======================rtm local invitation failed:", r);
      });
      this.localInvitation.on(
        "LocalInvitationRefused",
        callbacks["refused"] || (() => {})
      );
      this.localInvitation.on(
        "LocalInvitationAccepted",
        callbacks["accepted"] || (() => {})
      );
      this.localInvitation.on(
        "LocalInvitationReceivedByPeer",
        callbacks["received"] || (() => {})
      );
      this.localInvitation.on(
        "LocalInvitationFailure",
        callbacks["fail"] || (() => {})
      );

      await this.localInvitation.send();
    } catch (err) {
      console.log(".........rtm error caught");
      console.error(err);
    }
  }

  async cancelLocalInvitation() {
    try {
      if (this.localInvitation) {
        await this.localInvitation.cancel();
        this.localInvitation = null;
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export const rtm = new RTMClient();

try {
  console.log("/=/执行rtm111");
  rtm.init(AGORA_APP_ID);
} catch (err) {
  console.error(err);
}
