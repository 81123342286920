import { useEffect, useState } from "react";
import Navigation from "../../components/navigation/navigation";
import "./MinePayVip.css";
import { VERSION } from "../../service/config";
import { Link } from "react-router-dom";
import { CONST_TOP_ADJUST_HEIGHT } from "../../service/config";

const MinePayVip = () => {
  const [payUrl, setPayUrl] = useState();
  useEffect(() => {
    console.log('/=/JOSN出错定位23，:',localStorage.getItem("profile"));
    const appInfo = JSON.parse(localStorage.getItem("appStart"));
    const profile = JSON.parse(localStorage.getItem("profile"));
    //userId=%d&agent=%@&env=PROD&scene=IOS&version=%@&tag=%@&userLevel=%@"
    let tmpUrl =
      appInfo.vipPayUrl +
      "?userId=" +
      profile.userId +
      "&agent=(null)&env=PROD&scene=IOS&version=" +
      VERSION +
      "&tag=" +
      appInfo.tag +
      "&userLevel=" +
      appInfo.payUserLeven +
      "&fussr=h5";

    //let tmpUrl = 'https://pssr-t01.douquwl88.cn/?userId='+profile.userId+'&agent=(null)&env=PROD&scene=IOS&version=1.0.2&tag='+appInfo.tag+'&userLevel=50';
    //tmpUrl=tmpUrl.replace('http:','https:');
    setPayUrl(tmpUrl);
  }, [setPayUrl]);
  return (
    <div className="pay-container">
      <div className="fx-1">
        <Navigation
          theme={{ color: "#000", bg: "#fff" }}
          height={"0.5rem"}
          style={{ paddingTop: CONST_TOP_ADJUST_HEIGHT }}
          title={"会员中心"}
          right={
            <Link to="/MinePayVipList">
              <div className="font-size15">
                <span>开通记录</span>
              </div>
            </Link>
          }
        ></Navigation>
        <iframe src={payUrl} className="minePayVip-pay-web-container"></iframe>
      </div>
    </div>
  );
};

export default MinePayVip;
