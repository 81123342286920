import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import "./Home.css";
import * as service from "../../service";

import Live from "../Live/Live";
import AgoraRTC from "../../components/agora/rtc.tsx";
import { resolveModuleName } from "typescript";
import { Overlay, Popup } from "react-vant";
import { connect } from "react-redux";

// const loadImage = (src) => {
//     return new Promise((resolve) => {
//       const image = new Image();
//       image.onload = () => {
//         resolve(image);
//       };
//       image.onerror = () => {
//         resolve(false);
//       };
//       image.crossOrigin = 'anonymous';
//       image.src = src;
//       //image.style.borderRadius='100%';
//     });
// };

const loadImage = (src) => {
  const crop = (img) => {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let cw = img.width;
    let ch = img.width;

    canvas.width = cw;
    canvas.height = ch;

    ctx.drawImage(img, 0, 0);
    ctx.globalCompositeOperation = "destination-in";
    ctx.beginPath();
    ctx.arc(cw / 2, ch / 2, ch / 2, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();

    let ret = new Image();
    //let a = canvas.toDataURL();
    ret.src = canvas.toDataURL();
    //   console.log(a);

    ret.crossOrigin = "Anonymous";
    //   document.body.appendChild(ret);
    return new Promise((resolve) => {
      ret.onload = () => {
        resolve(ret);
      };
    });
  };

  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      crop(image).then((i) => {
        resolve(i);
      });
      //resolve(image);
    };
    image.onerror = () => {
      resolve(false);
    };
    image.crossOrigin = "anonymous";
    image.src = src;
  });
};

class Home extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pagImageNameDataMap = {};
    const first_home = !localStorage.getItem("first_home");
    console.log("%c first_home:%o", "color:blue", first_home);
    const appstart00 = localStorage.getItem("appStart")||'{}';
    console.log("/=/JOSN出错定位14，:", appstart00);

    const appInfo = JSON.parse(appstart00);
    this.state = { random: null, first_home, activityUrl: appInfo?.activityUrl };
  }
  closeInstruction() {
    this.setState({ first_home: false });
    localStorage.setItem("first_home", "true");
  }
  async componentDidMount() {
    console.log("\n\n\n\n===========>");
    const o2ohome = localStorage.getItem("o2ohome");
    // console.log(o2ohome);
    if (o2ohome == "true") return;

    Promise.resolve()
      .then(async () => {
        const llCanvas = document.getElementById("livelink");

        llCanvas.width = 150;
        llCanvas.height = 45;

        const llpagUrl = "/static/pag/home_live.pag";
        // Fetch pag file.
        const llbuffer = await fetch(llpagUrl).then((response) =>
          response.arrayBuffer()
        );
        // Load the PAGFile from file.
        const llpagFile = await window.PAG.PAGFile.load(llbuffer);
        const llpagView = await window.PAG.PAGView.init(llpagFile, llCanvas);
        llpagView.setRepeatCount(0);

        await llpagView.play();
      })
      .then(() => {
        return service.recommendList(14);
      })
      .then(async (data) => {
        // console.log('%c Recomment list:%o','color:blue',data);
        if (data == 21) {
          throw new Error(21);
        }

        const pagUrl = "/static/pag/home_jump.pag";
        // Initialize pag webassembly module.

        // Fetch pag file.
        const buffer = await fetch(pagUrl).then((response) =>
          response.arrayBuffer()
        );
        // Load the PAGFile from file.
        this.pagFile = await window.PAG.PAGFile.load(buffer);
        // Set the width from the PAGFile.
        this.canvas = document.getElementById("pag");
        if (!this.canvas) return;
        this.canvas.width = this.pagFile.width();
        this.canvas.height = this.pagFile.height();
        // console.log('%c recommend list data:%o','color:green',data);
        for (let i = 0; i < data?.userMapList?.length; i++) {
          const p1 = data.userMapList[i];

          // Load the PAGFile from file.
          const image = await loadImage(p1.headUrl.replace("http:", "https:"));

          // console.log('%c loadImage return:%o','color:green',image);

          const pagImage = window.PAG.PAGImage.fromSource(image);
          this.pagFile.replaceImage(i, pagImage);

          const imageLayer = this.pagFile.getLayersByEditableIndex(i, 5).get(0);

          this.pagImageNameDataMap[imageLayer.layerName()] = p1;

          const textDoc = this.pagFile.getTextData(i);
          // console.log('%c textDoc:%o','color:green',textDoc.text);
          textDoc.text = p1.Name;
          this.pagFile.replaceText(i, textDoc);
        }

        this.pagView = await window.PAG.PAGView.init(this.pagFile, this.canvas);
        this.pagView.setRepeatCount(0);

        await this.pagView.play();
      })
      .catch((err) => {
        console.error(err);
        if (err.message == "21") {
          console.error("to login");
          this.setState({ redirect: "/login" });
        }
      });
  }

  render() {
    const { activityUrl, random, redirect, first_home, redirectPayload } =
      this.state;
    const o2ohome = localStorage.getItem("o2ohome");

    if (redirect) {
      this.state = {};
      if (redirectPayload) {
        return <Navigate to={redirect} state={{ url: redirectPayload }} />;
      } else return <Navigate to={redirect} />;
    }

    if (o2ohome == "true") return <Live />;

    if (random) {
      return <Navigate to={"/person/" + random} />;
    }
    return (
      <div className="home-page layout-rel">
        <canvas
          className="pag-canvas"
          id="pag"
          onClick={(e) => {
            // console.log('%c click on the canvas:%o','color:green',e)
            // console.log('%c click on the canvas:(x:%d y:%d),(clientx:%d,clientY:%d)','color:green',e.screenX,e.screenY,e.clientX,e.clientY)

            if (this.pagView) {
              const xr = this.canvas.width / this.canvas.clientWidth;
              const yr = this.canvas.height / this.canvas.clientHeight;
              const vector = this.pagView.getLayersUnderPoint(
                e.clientX * xr,
                e.clientY * yr
              );

              if (vector.size() > 1) {
                const layer = vector.get(0);

                const d = layer && this.pagImageNameDataMap[layer.layerName()];
                //console.log('=====>recirector:','/person/'+d.id,layer.layerName());
                d && this.setState({ redirect: "/person/" + d.id });
              }
            }
          }}
        ></canvas>
        <div className="home-img layout-abs">
          <img src="/static/images/home-bg.jpg" />
        </div>
        <div className="layout-abs  icon-title">
          <span className="theme-font font-size30">
            F<span className="font-size24">u</span>
          </span>
        </div>
        <div className="live-link fx-box fx-jc-c fx-ai-c">
          <Link to="/live">
            <div className="font-size13 font-color3">
              <canvas className="pag-livelink" id="livelink"></canvas>
            </div>
          </Link>
        </div>
        <div
          className="full-r-w fx-box fx-jc-c layout-abs home-btn-container"
          onClick={() => {
            service.randomMatch().then((data) => {
              if (data.code == 0) {
                this.setState({ random: data.userId });
              }
            });
          }}
        >
          <div className="home-btn theme-font  font-color3 font-size17 text-center">
            随机匹配
          </div>
        </div>
        {activityUrl ? (
          <img
            onClick={() => {
              // navigate('/web',{state:{url:activityUrl}})
              this.setState({
                redirect: "/web",
                redirectPayload: activityUrl + "?fussr=true",
              });
            }}
            className="promotion-logo"
            src="/static/images/promotion.gif"
          ></img>
        ) : null}
        <Overlay
          visible={first_home}
          onClose={this.closeInstruction.bind(this)}
        >
          <div className="first_icon">
            <img src="/static/images/first_1.png" />
          </div>
          <div
            className="first_ok_btn"
            onClick={this.closeInstruction.bind(this)}
          >
            我知道了
          </div>
        </Overlay>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      banner: state.app.banner,
    };
  },
  (dispatch) => ({ dispatch })
)(Home);
