import { useState } from "react";
import {
  DefaultHeadUrl,
  HEART_BREAK_GRAY,
  HEART_BREAK_WHITE,
  HEART_ALL_GRAY,
  HEART_ALL_WHITE,
} from "../../../service/images";
import "./review.css";

const Review = (props) => {
  console.log("/=/聊天结论：", props);

  const good_badevaluation = [
    props.liveContext.praiseList,
    props.liveContext.negativeList,
  ];
  console.log("/=/----------good_badevaluation:", good_badevaluation);
  //   const good_badevaluation = props.liveContext.good_badevaluation;
  const [reviewTab, setReviewTab] = useState(0); // 类型
  const [reviewSelect, setReviewSelect] = useState({}); // 已选中
  let [reviewQueue] = useState([]); // 已选中
  const [state, setState] = useState(true); // 类型

  return (
    <div className="review-container" style={{ minHeight: 300 }}>
      <div className="fx-box fx-fd-r fx-jc-c review-icon-container">
        <img
          src={props.modelHeadUrl}
          style={{
            marginRight: -10,
            height: 54,
            width: 54,
            borderRadius: 27,
            marginTop: -20,
          }}
        />
        <img
          src={props.userHeadUrl}
          style={{
            marginLeft: -10,
            height: 54,
            width: 54,
            borderRadius: 27,
            marginTop: -20,
          }}
        />
      </div>
      <div className="fx-box fx-fd-c fx-jc-c fx-ai-c font-size15 font-w5 review-head">
        <div>与{props.modelName}的聊天</div>
        <div className="review-detail">
          {props.timeStr || props.liveContext?.duration}
        </div>
        <div className="review-detail review-consume fx-box fx-jc-c fx-ai-c">
          <div>
            消费:{props.timeConsume || props.liveContext?.totalConsume}金币
          </div>
          <div>
            送出:{props.giftConsume || props.liveContext?.totalGiftConsume}金币
          </div>
        </div>
      </div>
      <div className="fx-box fx-ai-c fx-jc-c fx-fd-r review-tab-container">
        <div
          className={
            "review-tab-left font-size15" +
            (reviewTab == 0 ? " review-tab-active" : "")
          }
          onClick={() => {
            setReviewTab(0);
            reviewQueue.length = 0;
            setReviewSelect({});
          }}
        >
          <img
            className="review-icon"
            src={reviewTab == 1 ? HEART_ALL_GRAY : HEART_ALL_WHITE}
          />
          <span>喜欢</span>
        </div>

        <div
          className={
            "review-tab-right font-size15" +
            (reviewTab == 1 ? " review-tab-active" : "")
          }
          onClick={() => {
            setReviewTab(1);
            reviewQueue.length = 0;
            setReviewSelect({});
          }}
        >
          <img
            className="review-icon"
            src={reviewTab == 1 ? HEART_BREAK_WHITE : HEART_BREAK_GRAY}
          />
          <span>无感</span>
        </div>
      </div>

      <div className="review-list">
        {good_badevaluation[reviewTab].map((e, idx) => {
          return (
            <div
              key={"i_" + idx}
              className={
                "font-size10 review-list-item " +
                (reviewSelect[e.id] ? "active-1" : "inactive-2")
              }
              style={{
                backgroundColor: reviewSelect[e.id]
                  ? e.color.replace(/0x/g, "#")
                  : "#C0CBD7",
              }}
              onClick={() => {
                reviewSelect[e.id] = !reviewSelect[e.id];
                if (reviewSelect[e.id] == true) {
                  reviewQueue.push(e);
                  if (reviewQueue.length > 3) {
                    const uidx = reviewQueue[0];
                    reviewSelect[uidx.id] = false;
                    reviewQueue.splice(0, 1);
                  }
                } else {
                  //   const iidx = reviewQueue.indexOf(e.id);
                  let iidx = -1;
                  reviewQueue.forEach((item, index) => {
                    if (item.id === e.id) {
                      iidx = index;
                    }
                  });
                  if (iidx >= 0) reviewQueue.splice(iidx, 1);
                  //   reviewQueue=reviewQueue.filter(item=>item.id!==e.id)  低拷贝无效
                }
                console.log("/=/怎么技术：", reviewSelect);
                console.log(reviewQueue);
                setReviewSelect(Object.assign({}, reviewSelect));
              }}
            >
              {e.prompt}
            </div>
          );
        })}
      </div>
      <div
        style={{ marginTop: "auto" }}
        className="fx-box fx-fd-r fx-jc-c fx-ai-c"
      >
        <div
          className={
            "font-size15 review-submit fx-box fx-jc-c fx-ai-c font-color2" +
            (reviewQueue.length > 0 ? " active" : " inactive")
          }
          onClick={() => {
            // const r = [];
            // Object.keys(reviewSelect).forEach((k) => {
            //   if (reviewSelect[k] == true)
            //     r.push(good_badevaluation[reviewTab][k]);
            // });
            if (!state) {
              // 只允许点击一次
              return;
            }
            setState(false);
            const type = reviewTab ? "INDIFFERENT" : "PRAISE";
            const r = reviewQueue.map((item) => item.prompt);
            props.onSubmit(type, r.join("|"));
          }}
        >
          提交评价
        </div>
      </div>
    </div>
  );
};

export default Review;
