import React, { useEffect, useState } from 'react'; 
import './Login.css';
import {getCheckCode,login,appStart, checkWebMediaPermission} from '../../service';
import { Navigate, useNavigate } from 'react-router-dom';
import { Toast,Image } from 'react-vant';
import {rtm} from '../../components/agora/rtm';
import WebIM, { initConn } from '../../components/easemob/WebIM';
import store from '../../redux/store';
import * as service from '../../service';
import {ENV}    from '../../service/config';


import { ConnectionContext } from '../../ConnectionContext';
import { connect } from 'react-redux';
import { initAll } from '../../init';

// class Login extends React.PureComponent{

//     constructor(props){
//         super(props);
//         this.state={
//             mobileNumber:'',
//             verifyCode:'',
//             logined:false,
//             countDown:0
//         }
//     }
//     onChangeMobile(e){
//         this.setState({
//             'mobileNumber':e.target.value
//         });
//     }
//     onChangeVerify(e){
//         this.setState({
//             'verifyCode':e.target.value
//         });
//     }
//     getCheckCode(){

//         const {countDown,mobileNumber} = this.state;
//         if(countDown>0)return;
//         const that = this;
//         that.setState({countDown:60});
//         that.countDownTimer = setInterval(()=>{
//             const {countDown} = that.state;
//             that.setState({countDown:countDown-1});
//             if(countDown<=0){
//                 clearInterval(that.countDownTimer);
//                 that.countDownTimer=null;
//             }
//         },1000);

        
//         if (mobileNumber == '') {
//             Toast.info('请输入手机号', 1500);
//             return false;
//         }
//         getCheckCode('86',mobileNumber).then(ret=>{
//             if(ret.code==0){
                
//                 Toast({
//                     message: '验证码发送成功',
//                     icon: <Image width={50} src="/static/images/toast-icon.png" />,
//                 }); 
                    
//             }
//             else{
//                 Toast.info('验证码发送失败');
//             }
//         }).catch(err=>{
//             console.error(err)
//         });
//     }
//     validate(){
//         const {mobileNumber,verifyCode} = this.state;
//         return mobileNumber&&mobileNumber.length>7&&verifyCode&&verifyCode.length>4;
//     }
//     componentWillUnmount(){
//         if(this.countDownTimer){
//             clearInterval(this.countDownTimer);
//             this.countDownTimer=null;
//         }
//     }

//     componentDidMount(){
//         const mobileNumber=localStorage.getItem('last_mobile')||'';
//         this.setState({mobileNumber});
//     }
 
const Login = (props)=>{

        const [mobileNumber,setMobileNumber] = useState(localStorage.getItem('last_mobile')||''); 
        const [verifyCode,setVerifyCode]  = useState('');

        const [countDown,setCountDown] = useState(0);

        let countDownTimer;

        const doGetCheckCode = (countDown)=>{
                    // const {countDown,mobileNumber} = this.state;
            if(countDown>0)return;
            
            countDown=60;
            setCountDown(countDown);

            countDownTimer = setInterval(()=>{                
                setCountDown(--countDown);
                if(countDown<=0){
                    clearInterval(countDownTimer);
                    countDownTimer=null;
                }
            },1000);

        
            if (mobileNumber == '') {
                Toast.info('请输入手机号', 1500);
                return false;
            }
            getCheckCode('86',mobileNumber).then(ret=>{
                if(ret.code==0){
                
                    Toast({
                        message: '验证码发送成功',
                        icon: <Image width={50} src="/static/images/toast-icon.png" />,
                    }); 
                    
                }
                else{
                    Toast.info('验证码发送失败:'+ret.errMsg);
                }
            }).catch(err=>{
                console.error(err)
            });
        }

        const validate = ()=>{
            return mobileNumber&&mobileNumber.length>7&&verifyCode&&verifyCode.length>4;
        }

        useEffect(()=>{
            return  ()=>{
                // clearInterval(countDownTimer)
            }
        })

        const navigate = useNavigate();
        const isLogin2=localStorage.getItem("appStart")
        console.log('/=/登录页：',props);
        console.log('/=/底部标题3333333333333333344455：');
        console.log('/=/登录2：',);
        if(props.imLogin||isLogin2){
            return <Navigate to='/app'></Navigate>
        }
        
        return (
            // <ConnectionContext.Consumer>

            // (
                    <div className="login-page">
                   <div className='login-header'></div>
                   <div className='login-container'>
                        <div className='login-nav'>
                              {/* <i className='iconfont icon-weibiaoti--'></i> */}
                              <span>&nbsp;</span>
                        </div>
                        <div className='login-title theme-font'>
                              <div className='font-size-28px'>手机号登陆</div>
                              <div className='font-size13 font-color6'>欢迎来到Fu</div>
                        </div>
                        <div className='login-form'>
                             
                             <div className='login-input fx-box fx-ai-c fx-jc-sb'>
                                 <div className='theme-font login-label fx-box fx-ai-c fx-jc-sb'>
                                    <span>+86</span>
                                    <i className='iconfont icon-weibiaoti--'></i>
                                 </div>
                                 <input value={mobileNumber} 
                                 placeholder='请输入移动电话号码'
                                 onChange={(e)=>{
                                    setMobileNumber(e.target.value)
                                 }} className='phone-number font-w5 theme-font font-size15'/>
                             </div>

                             <div className='login-input mb4 fx-box fx-ai-c  layout-rel'>
                                <div className='theme-font login-label fx-box fx-ai-c fx-jc-sb'>
                                    <span>验证码</span>
                                 </div>
                                 <input value={verifyCode} 
                                 maxLength={ENV=='prod'?5:6}
                                 placeholder='请输入验证码'
                                 onChange={(e)=>{
                                    setVerifyCode(e.target.value);
                                 }} className='login-field theme-font font-size-15px'/>
                                 <div className={'login-send layout-abs '+(countDown>0?'login-input-bg2 ':' login-input-bg3')}>
                                    <div onClick={()=>doGetCheckCode(countDown)} className='theme-font text-center font-size13 font-color3'>
                                        {countDown>0?countDown+'秒':'发送验证码'}
                                    </div>
                                 </div>
                             </div>

                             <div className={'login-submit login-input-active  mt16 '+(validate()?' login-input-bg3 ':' login-input-bg2 ')}>
                                  <div  className='theme-font font-size15 font-color3 text-center'
                                  onClick = {(()=>{
                                    
                                    if(mobileNumber==''){
                                        Toast.info('请输入电话号码');
                                        return;
                                    }
                                    if(!verifyCode||verifyCode.length<5){
                                        Toast.info('请输入正确验证码');
                                        return;
                                    }
                                    localStorage.removeItem('appStart');
                                    localStorage.setItem('last_mobile',mobileNumber);
                                    let profile=null;
                                    // console.log('',mobileNumber,verifyCode);
                                    login(mobileNumber,verifyCode)
                                    .then(ret=>{
                                        console.log('%c [DEBUG]login return DATA:','color:blue',ret);
                                        if(ret.code==0){
                                            // console.log('logined');
                                            
                                            const hostUser = ret.hostUser;
                                            profile = hostUser;
                                            localStorage.setItem('profile',JSON.stringify(hostUser));
                                            
                                            console.log('[DEBUG]try to xiGetInstallParam 0',hostUser);
                                            try{
                                                if(hostUser.regUserFlag=='1'){
                                                    try{
                                                        window?.webkit?.messageHandlers?.callbackHandler?.postMessage('xiReportRegister');
                                                        
                                                        console.log('[DEBUG]try to xiGetInstallParam 1');
                                                        
                                                        window?.webkit?.messageHandlers?.callbackHandler && (window.webkit.messageHandlers.callbackHandler.xiGetInstallParamsCallback=(ret)=>{
                                                            //setXiInstallParams(ret)
                                                            console.log('%c [DEBUG]xiGetInstallParam return:%o','color:blue',ret);
                                                            console.log('/=/JOSN出错定位13，:',ret);
                                                            const retObj = JSON.parse(ret);
        
                                                            if(!!(retObj?.data?.uo?.userid)){
                                                                console.log('=====>write invite code:',retObj?.data?.uo);
        
                                                                service.writeInviteCode(retObj?.data?.uo).catch(err=>{
                                                                    console.error(err);
                                                                });
                                                            }
                                                            else {
                                                                console.log('=====>not not not write invite code:',retObj?.data?.uo);
                                                            }
                                                            
                                                            //window?.webkit?.messageHandlers?.callbackHandler?.postMessage('xiGetInstallParamsgotresult:'+ret);    
                                                        });
                                                        console.log('\n');
                                                        console.log('[DEBUG]try to xiGetInstallParam');
                                                        window?.webkit?.messageHandlers?.callbackHandler?.postMessage('xiGetInstallParams');
                                                    }catch(err){
                                                        console.error(err);
                                                    }
                                                }
                                            }
                                            catch(err){

                                            }
                                            return appStart().then(appInfo=>{
                                                props.dispatch({type:'INIT_SERVICE',promise:initAll(profile,appInfo,store)})
                                            })
                                        }    
                                        else Toast(ret.errMsg)
                                    }).then(ret=>{
                                        props.dispatch({type:'FU_LOGINED',appStart:ret,payload:profile})
                                    })
                                    // .then(ret=>{
                                    //     //this.setState({logined:ret});
                                    //     clearInterval(countDownTimer);
                                    //     navigate('/app');
                                    //     props.dispatch({type:'TAB_SET',payload:0});
                                    // }).then(()=>{
                                    //     console.log('%c cloud init ok.','color:green');
                                    // }).catch(err=>{
                                    //     console.error(err);
                                    // })
                                  }).bind(this)}
                                  >登录</div>
                             </div>

                        </div>
                   </div>
             </div>
           
             )
             ;
    
}
export default connect(state=>{
    console.log('/=/state.app:',state.app);
    return {
        imLogin:state.app.imLogin
    }
},(dispatch)=>{return {dispatch}})(Login);

/*

return appStart().then(ret=>{
                                                console.log('[debug]appstart:',ret);
                                                localStorage.setItem('appStart',JSON.stringify(ret));
                                                /*
                                                accessKey: "1103211206113416#social"
                                                accessSecret: "YXA6-HUmywBVJ9aYucpIFMKUK4wWGwU"
                                                apnsCertName: "socialfu_online_push_certification"
                                                clientId: "YXA6gMr3MGriRN2W-Cw5gLiovg"
                                                filterUsers: ""
                                                prefix: "socialfu_"
                                                uri: "http://a1.easemob.com/1103211206113416/social"
                                                * /
                                                initConn(ret.easemob_config.accessKey);
                                                console.log('%c [debug] webIm config:%o','color:green',WebIM.config);
                                                const profile = JSON.parse(localStorage.getItem('profile'));
                                                return rtm.login(profile.userId+'', ret.rtmToken)
                                            }).then(() => {
                                                console.log('[INFO]rtm connected.')

                                                rtm.on('RemoteInvitationReceived',async (args)=>{
                                                    console.log('%c AGORA RTM EVENT:localinvitationreceivedbypeer','color:green',args);
                                                    console.log(args.callerId,args.content,args.channelId);
                                                    rtm.currentRemoteInvitation=args;
                                                    const allowVideo=await service.checkWebMediaPermission().then(()=>{return true;}).catch(()=>{   
                                                        return false;
                                                    });
                                                    allowVideo&& service.getModelDetail(args.callerId).then(ret=>{
                                                        console.log('%c model detail 3:','color:green');
                                                        navigate('/one2one',{
                                                            state:{invite:true,caller:ret.user}
                                                        })
                                                    })
                                                    
                                                })

                                                rtm._logined = true

                                                const options = {
                                                    apiUrl: WebIM.config.apiURL,
                                                    user: hostUser.hxUserName,
                                                    pwd: hostUser.hxUserName.split('_')[1],
                                                    appKey: WebIM.config.appkey
                                                };
                                                store.dispatch({type:'IM_LOGIN',payload:options});
                                                setTimeout(()=>{
                                                    console.log('start easemob connect ....');
                                                    WebIM.conn.open(options);
                                                },3000);

                                                return true;
                                              }).catch((err) => {
                                                console.error(err)
                                              })
                                        }
                                        else{
                                            Toast.info(ret.errMsg);
                                            return false;
                                        }

*/