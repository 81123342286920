import React, { useEffect ,useState} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {Image, Toast} from 'react-vant';
import Navigation from "../../../components/navigation/navigation";
import * as service from '../../../service';
import './Notice.css';
const Notice=()=>{
    // const profile = JSON.parse(localStorage.getItem('profile'));
    const [blist,setList] = useState(null);
    useEffect(()=>{
        if(!blist){
            service.getNoticeList().then(data=>{
                console.log(data);
                setList(data.notificationList);
            })
        }
    })
    const navigate = useNavigate();
    return (
        <div className="notice-comment-page hide-x fx-box fx-fd-c">
           <Navigation style={{paddingTop:0}} theme={{color:'#000',bg:'#fff'}} height={'0.5rem'} title={'官方公告'}></Navigation>
           <div className='full-r-w fx-box fx-1 fx-fd-c' style={{paddingTop:'0.8rem'}}>
                 {
                   blist?.map((item,index)=>{
                        return ( <div key={index} className='commentItem fx-box fx-jc-sb fx-ai-c'>
                        <div className='fx-box fx-1 fx-fd-c' onClick={()=>{
                            navigate('/notice/'+item.id);
                        }}>
                            
                                <div className='black-userid fx-box fx-fd-r  fx-jc-sb'>
                                    <div className='font-size15 fx-1'>{item.title}</div>
                                    
                                </div>
                                
                                <div className='font-size13'>
                                        {item.content}
                                </div>
                           
                        </div>
                       
                    </div>)
                    })
                 }
           </div>
          
        </div>
    );
}

export default Notice;