
import React,{useState} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import './tabbar.css';
// 根据屏幕高度设置菜单高度，在812时，把50改为60，看iPhoneX是否还会遮挡菜单
const getScreenAdjustHeight=()=>{
    console.log('/=/查看屏幕参数：',window.screen);
    console.log('/=/查看屏幕参数---------高：',window.screen.height);
    console.log('/=/查看屏幕参数---------宽：',window.screen.width);
    if(window.screen.height>=926) return 124;
    else if(window.screen.height==844&&window.screen.width==390) return 60;
    else if(window.screen.height==844)return 94;
    else if(window.screen.height==812) return 60;
    else if(window.screen.height==736) return 80;
    else if(window.screen.height==667&&window.screen.width==375) return 50;
    else if(window.screen.height==667)return 60;
    else return 80;
}

const TabBarRaw=function(props){
    console.log('/=/底部标题33333333333333333：',props);
        const navigate = useNavigate();
        // const [activeKey,setactiveKey]=useState('1');
        // console.log('==========>tabIndex:',props.tabIndex);
        const [tabs]=useState(
        [
            {
                icon:'iconfont icon-home-fill',
                    key:'1',
                    title:'首页',
                    path:'/app',
                
                image:'/static/images/t-home.png',
                imageInactive:'/static/images/t-home-inactive.png',
                
            },
            {
                icon:'iconfont icon-faxian',
                image:'/static/images/t-activity.png',
                imageInactive:'/static/images/t-activity-inactive.png',
                key:'2',
                title:'动态',
                path:'/app/activity'
            },
            // {
            //     icon:'iconfont icon-shipin',
            //     key:'3',
            //     title:'视频交友',
            //     path:'/app/live'
            // },
            // {
            //     icon:'iconfont icon-shipin',
            //     image:'/static/images/t-fu.png',
            //     imageInactive:'/static/images/t-fu-inactive.png',
            //     key:'3',
            //     title:'Fu圈',
            //     path:'/app/circle'
            // },
            
            {
                icon:'iconfont icon-xiaoxi1',
                image:'/static/images/t-msg.png',
                imageInactive:'/static/images/t-msg-inactive.png',
                key:'4',
                title:'消息',
                path:'/app/msg'
            }, {
                icon:'iconfont icon-wode1',
                image:'/static/images/t-my.png',
                imageInactive:'/static/images/t-my-inactive.png',
                key:'5',
                title:'我的',
                path:'/app/mine'
            }
    ]
        );
    
    let msgUnreadCount = 0;
    props.app?.conversations?.map(e=>{
        msgUnreadCount+=e.msgnum
    })

    let adjustHeight=getScreenAdjustHeight();
    
     return (
        <div className='fuapp-tababr fx-box' style={{height:adjustHeight}}>
             {
                tabs.map((item,index)=>{
                    return (
                        <div  key={item.key} data-key={item.key} 
                              onClick={()=>{
                                //   console.log(item)
                                  navigate(item.path);
                                  props.dispatch({type:'TAB_SET',payload:index});

                              }} className='tababrItem fx-box fx-fd-c fx-ai-c'>
                            {/* <i onClick={()=>{}} className={item.key===activeKey?item.icon+' font-color2':item.icon+' font-color1'}></i> */}
                            <img src={index===props.tabIndex?item.image:item.imageInactive}/>
                            <span  onClick={()=>{}} className={index==props.tabIndex?'font-color2':'font-color1'}>{item.title}</span>
                            {
                                index==2&&msgUnreadCount>0?<div className='tabbar-unread font-size13 font-color3'>{msgUnreadCount}</div>:null
                            }
                        </div>
                    );
                })
             }
            
        </div>
    )
}

export const TabBar = connect((state)=>{
    return {
        tabIndex:state.GlobalTab.tabIndex,
        app:state.app
    }
},(dispatch)=>{return {dispatch}})(TabBarRaw);


const TabBar2Raw=function(props){
    console.log('/=/底部标题33333333333333333：',props);
    const navigate = useNavigate();
    // const [activeKey,setactiveKey]=useState('1');
    const [tabs,settabs]=useState([
            {
                icon:'iconfont icon-home-fill',
                image:'/static/images/t-o2o.png',
                imageInactive:'/static/images/t-o2o-inactive.png',
                key:'1',
                title:'视频交友',
                path:'/app'
            }, 
            {
                icon:'iconfont icon-faxian',
                image:'/static/images/t-activity.png',
                imageInactive:'/static/images/t-activity-inactive.png',
                key:'2',
                title:'动态',
                path:'/app/activity'
            },
            // {
            //     icon:'iconfont icon-shipin',
            //     key:'3',
            //     title:'视频交友',
            //     path:'/app/live'
            // },
            // {
            //     icon:'iconfont icon-shipin',
            //     image:'/static/images/t-fu.png',
            //     imageInactive:'/static/images/t-fu-inactive.png',
            //     key:'3',
            //     title:'Fu圈',
            //     path:'/app/circle'
            // },
            
            {
                icon:'iconfont icon-xiaoxi1',
                image:'/static/images/t-msg.png',
                imageInactive:'/static/images/t-msg-inactive.png',
                key:'4',
                title:'消息',
                path:'/app/msg'
            }, {
                icon:'iconfont icon-wode1',
                image:'/static/images/t-my.png',
                imageInactive:'/static/images/t-my-inactive.png',
                key:'5',
                title:'我的',
                path:'/app/mine'
            }
    ]);
    
    let msgUnreadCount = 0;
    props.app?.conversations?.map(e=>{
        msgUnreadCount+=e.msgnum
    })

    let adjustHeight=getScreenAdjustHeight();
    
    
    
 return (
    <div className='fuapp-tababr fx-box' style={{height:adjustHeight}}>
         {
            tabs.map((item,index)=>{
                return (
                    <div  key={item.key} data-key={item.key} 
                          onClick={()=>{
                            //   console.log(item)
                              navigate(item.path);
                              props.dispatch({type:'TAB_SET',payload:index});

                          }} className='tababrItem fx-box fx-fd-c fx-ai-c'>
                        <img onClick={()=>{}} className='' src={index===props.tabIndex?item.image:item.imageInactive}></img>
                        <span  onClick={()=>{}} className={index===props.tabIndex?'font-color2':'font-color1'}>{item.title}</span>
                        {
                                index==2&&msgUnreadCount>0?<div className='tabbar-unread font-size13 font-color3'>{msgUnreadCount}</div>:null
                        }
                    </div>
                );
            })
         }
        
    </div>
)
}


export const TabBar2 = connect((state)=>{
    return {
        tabIndex:state.GlobalTab.tabIndex,
        app:state.app
    }
},(dispatch)=>{return {dispatch}})(TabBar2Raw);

