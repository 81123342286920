import React, { useState, useRef, useEffect } from "react";
import NavBar from "../../components/nav-bar/nav-bar";
import { useNavigate } from "react-router-dom";
import LiveListItem from "../../components/live-list-item/live-list-item";
import LiveListItem2 from "../../components/live-list-item/live-list-item2";
import "./Live.css";
import {
  OneOnOneList,
  loadOneononeMore,
  OneOnOneNewList,
  loadOneononeNewMore,
  OneOnOneOtherList,
  loadOneononeOtherMore,
  OneOnOneSameCityList,
  loadOneononeSameCityMore,
  requestLocationAuth,
  queryMyselfInfo,
} from "../../service";
import { Swiper, Image, Loading } from "react-vant";
import { connect } from "react-redux";
import { CONST_TOP_ADJUST_HEIGHT } from "../../service/config";
import MediaAuthDialog from "../../components/meida-auth-dialog/MediaAuthDialog";

let reloadTimer = null;
// 视频聊天主菜单

const Live = (props) => {
  console.log("/=/测试：", props);
  const listRef = useRef(null);
  let loading = false;
  const appstart00 = localStorage.getItem("appStart")||'{}';
  console.log('/=/JOSN出错定位19，:',appstart00);
  const appInfo = JSON.parse(appstart00);
  // const [refreshing,setRefreshing] = useState(false);
  const [locationAuthing, setLocationAuthing] = useState(false);
  const [showLocationDialog, setShowLocationDialog] = useState(false);
  const [showReturnTop, setShowReturnTop] = useState(false);
  const [activityUrl] = useState(appInfo.activityUrl);
  const [showLiveList, setshowLiveList] = useState(true); // 设置分屏
  // console.log('appInfo:',appInfo,props.liveList);
  // const [currentTab,setCurrentTab] =useState(0);
  // const [bannerList,setBannerList] =  useState([{bgUrl:'/static/images/banner.jpg'}])

  let shouldRefresh = false;
  // console.log('%c geolocation:%o','color:blue',props.geo);
  const refresh = () => {
    props.dispatch({ type: "RELOAD_LIVE_LIST_START" });
    reload(props.currentTab).finally(() => {
      props.dispatch({ type: "RELOAD_LIVE_LIST_END" });
    });
  };
  // 请求推荐模特列表
  const getModelList = (bool) => {
    const obj = {
      start: 1,
      displayMode: bool ? 3 : 4, // 3： 瀑布流 4: 大图模式
      type: 0, // 0:推荐列表,1:同城列表,2:标签列表,3:新人模特列表,5:语聊
    };

    return OneOnOneList(obj)
      .then((ret) => {
        console.log("data:", ret);
        if (ret.code == 21) {
          navigate("/login");
        } else if (ret.data.dataList) {
          console.log('/=/自住：',ret.data.dataList);
          const arr=ret.data.dataList.filter(item=>item.id!==0)
          props.dispatch({
            type: "LIVE_LOADED",
            tab: 0,
            banners: ret.data.bannerList,
            list: arr,
            modelTotal: ret.data,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        props.dispatch({
          type: "LIVE_RELOAD_FAIL",
          tab: 0,
          payload: err,
        });
      });
  };
  const reload = (tabidx, debugInfo) => {
    console.log('/=/----------:序号：',tabidx);
    console.log("%c[DEBUG]reload debug info:%o", "color:green", debugInfo);

    // setRefreshing(true);
    props.dispatch({ type: "LIVE_RELOAD", payload: 0 });

    clearTimeout(reloadTimer);

    props.dispatch({ type: "LIVE_SCROLL_POSITION", payload: 0 });
    const pageElement = document.getElementById("page");
    pageElement && (pageElement.scrollTop = 0);
    setTimeout(() => {
      return Promise.resolve()
        .then(() => {
          //tabidx： 0-推荐，1-桐城，
          if (!tabidx || tabidx == 0) {
            return getModelList(showLiveList);
          } else if (tabidx == 1) {
            console.log(
              "%c [DEBUG]load geo info:%o",
              "color:green",
              props.geo.location
            );
            if (
              props.geo.location &&
              props.geo.location.latitude &&
              props.geo.location.longitude
            ) {
              return OneOnOneSameCityList(props.geo.location)
                .then((ret) => {
                  console.log("%c [DEBUG]load geo list:%o", "color:green", ret);
                  if (ret.code == 21) {
                    navigate("/login");
                  } else if (ret.userMapList) {
                    props.dispatch({
                      type: "LIVE_LOADED",
                      tab: tabidx,
                      banners: ret.bannerList,
                      list: ret.userMapList,
                    });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              props.dispatch({
                type: "LIVE_LOADED",
                tab: tabidx,
                banners: [],
                list: [],
              });
            }
          } else {
            const tab = props.tabs[tabidx];
            if (tab.id == 3) {
              return OneOnOneNewList()
                .then((ret) => {
                  if (ret.code == 21) {
                    navigate("/login");
                  } else if (ret.userMapList) {
                    props.dispatch({
                      type: "LIVE_LOADED",
                      tab: tabidx,
                      list: ret.userMapList,
                    });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              return OneOnOneOtherList(tab.id)
                .then((ret) => {
                  if (ret.code == 21) {
                    navigate("/login");
                  } else if (ret.userMapList) {
                    props.dispatch({
                      type: "LIVE_LOADED",
                      tab: tabidx,
                      list: ret.userMapList,
                    });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }
        })
        .catch((err) => {
          props.dispatch({
            type: "LIVE_RELOAD_FAIL",
            tab: tabidx,
            payload: err,
          });
        })
        .finally(() => {
          console.log("end loading....");
          reloadTimer = setTimeout(() => {
            reload(tabidx, "timeout reload");
          }, 5 * 60 * 1000);
        });
    }, 300);
  };

  useEffect(() => {
    if (props.loading) return;

    const list = listRef.current;
    let timer;

    const handleScroll = (ev) => {
      const listElement = document.getElementById("live-list");
      const pageElement = document.getElementById("page");

      const scrollTop = pageElement.scrollTop;
      const screenHeight = window.screen.height;
      const offsetHeight = listElement.offsetHeight;

      // console.log('%c scroll monitor:%d %d %d %d','color:blue',listElement.scrollTop,pageElement.scrollTop,screenHeight,offsetHeight);

      props.dispatch({ type: "LIVE_SCROLL_POSITION", payload: scrollTop });
      if (scrollTop > 1600) {
        setShowReturnTop(true);
      } else {
        setShowReturnTop(false);
      }

      if (scrollTop < -70) {
        // console.log('%c Live reload wait touch end....:%d','color:blue',props.currentTab);
        // setShouldRefresh(true);
        shouldRefresh = true;
      } else if (scrollTop >= offsetHeight - screenHeight * 1.5) {
        clearTimeout(timer);
        if (loading) {
          //is loading more, do not repeat.
          return;
        }

        timer = setTimeout(() => {
          loading = true;

          // 不知道maxTime是干什么的
          // let maxTime = new Date().valueOf();
          // if (props.liveList?.length > 1) {
          //   maxTime = props.liveList[props.liveList.length - 1].maxTime;
          // }

          if (!props.currentTab || props.currentTab == 0) {
            // console.log('%c load more:%o %d','color:blue',maxTime,props.liveList.length);
            // 如果当前页码大于总页码，则放弃请求
            const modelTotal = props.modelTotal;

            if (modelTotal.start >= modelTotal.totalPage) {
              console.log("/=/已经到底了");
              return;
            }
            const obj = {
              start: modelTotal.start + 1,
              displayMode: showLiveList ? 3 : 4, // 3： 瀑布流 4: 大图模式
              type: 0, // 0:推荐列表
            };
            // OneOnOneList
            OneOnOneList(obj)
              .then((data) => {
                // console.log('%c data:%o','color:blue',data);
                props.dispatch({
                  type: "LIVE_LOAD_MORE",
                  payload: data.data.dataList,
                  modelTotal: data.data,
                });
              })
              .finally(() => {
                loading = false;
              });
          } else if (props.currentTab == 1) {
            //加载同城
            props.geo.location &&
              props.liveList &&
              loadOneononeSameCityMore(
                props.geo.location,
                props.liveList?.length
              )
                .then((data) => {
                  // console.log('%c data:%o','color:blue',data);
                  props.dispatch({
                    type: "LIVE_LOAD_MORE",
                    payload: data.userMapList,
                  });
                })
                .finally(() => {
                  loading = false;
                });
          } else {
            const tab = props.tabs[props.currentTab];
            if (tab.id == 3) {
              loadOneononeNewMore(props.liveList?.length)
                .then((data) => {
                  // console.log('%c data:%o','color:blue',data);
                  props.dispatch({
                    type: "LIVE_LOAD_MORE",
                    payload: data.userMapList,
                  });
                })
                .finally(() => {
                  loading = false;
                });
            } else {
              loadOneononeOtherMore(props.liveList?.length, tab.id)
                .then((data) => {
                  // console.log('%c data:%o','color:blue',data);
                  props.dispatch({
                    type: "LIVE_LOAD_MORE",
                    payload: data.userMapList,
                  });
                })
                .finally(() => {
                  loading = false;
                });
            }
          }
        }, 10);
      }
    };
    const handleTouchEnd = () => {
      if (shouldRefresh) {
        console.log("........load ......... for refresh");
        refresh().then(() => {
          shouldRefresh = true;
        });
      }
    };

    list.addEventListener("scroll", handleScroll);
    list.addEventListener("touchend", handleTouchEnd);

    return () => {
      clearTimeout(timer);
      list.removeEventListener("scroll", handleScroll);
      list.removeEventListener("touchend", handleTouchEnd);
    };
  }, [listRef, loading, props.liveList, shouldRefresh]);

  const navigate = useNavigate();

  //console.log('live tabs:',props.tabs);

  const onClickItem = (item) => {
    navigate("/person/" + item?.id, { state: { person: item } });
  };

  useEffect(() => {
    if (props.loading) return;

    if (!appInfo || appInfo.code != 0) {
      navigate("/login");
      return;
    }
    if (props.liveList == null && props.currentTab != 1) {
      console.info("try to load live list...", props.currentTab);
      reload(props.currentTab);
    } else if (
      props.currentTab == 1 &&
      props.liveList &&
      props.liveList.length < 1
    ) {
      console.info("try to load geo list...", props.currentTab);
      if (
        props.geo.location &&
        props.geo.location.latitude &&
        props.geo.location.longitude
      )
        reload(props.currentTab);
    }

    if (props.status.scrollTop) {
      try {
        document.getElementById("page").scrollTop = props.status.scrollTop;
      } catch (err) {}
    }
  }, [navigate, appInfo, props, props.liveList]);

  const openLocationAuth = () => {
    setLocationAuthing(true);
    const ot = setTimeout(() => {
      setLocationAuthing(false);
      setShowLocationDialog(true);
    }, 12000);
    requestLocationAuth(props.dispatch, () => {
      clearTimeout(ot);
    });
    // window.futrue.openLocationHandler = (isOpen)=>{
    //     if(isOpen){
    //         window.futrue.getLocationCallback=(ret)=>{
    //             console.log('%c Geolocation got:','color:blue',ret);
    //             props.dispatch({type:'GEO_LOADED',payload:ret})
    //         };
    //         window?.webkit?.messageHandlers?.callbackHandler?.postMessage('getLocation');
    //     }
    //     setLocationAuthing(false);
    // }
    // window?.webkit?.messageHandlers?.callbackHandler?.postMessage('openLocation');
  };

  return (
    <div
      className="live-page full-r-w fx-box fx-fd-c fx-ai-c"
      id="page"
      ref={listRef}
      style={{ marginTop: 38, ...props.style }}
    >
      <NavBar
        style={{
          position: "fixed",
          background: "white",
          // paddingBottom: '0.15rem',
          paddingTop: 0,
          zIndex: 100,
        }}
        activeTab={props.currentTab}
        activity={props.tabs}
        showLiveList={showLiveList}
        setshowLiveList={setshowLiveList}
        getModelList={getModelList}
        reload={(idx) => {
          if (idx != props.currentTab) {
            // setCurrentTab(idx);
            console.log("======>", idx);
            if (idx == 1) {
              requestLocationAuth(props.dispatch);
            }

            reload(idx);
          }
        }}
      />
      <div
        className="fx-box fx-ai-c fx-jc-c font-size10 font-color7"
        style={{ height: "0.24rem" }}
      >
        释放刷新
      </div>
      {props.currentTab == 1 && props.geo.location == null ? (
        <div className="fx-box fx-fd-r fx-ai-c location-auth-bar">
          <img src="/static/images/auth-notice-icon.png"></img>

          <div
            className="fx-1 font-size13 fx-jc-s fx-box fx-ai-c auth-desc"
            style={{ paddingLeft: 8 }}
          >
            {props.geo.loading
              ? "正在获取位置信息..."
              : "当前未开启位置权限，将无法获取同城列表"}
          </div>
          {props.geo.loading ? (
            <Loading size={24} style={{ marginRight: 15 }} />
          ) : locationAuthing ? (
            <Loading size={24} style={{ marginRight: 15 }} />
          ) : (
            <div
              className="btn btn-small font-size13 auth-btn"
              onClick={() => {
                openLocationAuth();
              }}
            >
              去开启
            </div>
          )}
        </div>
      ) : null}
      <div className="live-banner">
        {props.banners && props.banners.length > 0 ? (
          <Swiper autoplay={true} loop={true}>
            {props.banners.map((e, idx) => {
              return (
                <Swiper.Item key={"si_" + idx}>
                  <Image
                    src={e.bgUrl}
                    onClick={() => {
                      e.linkUrl &&
                        navigate("/web", { state: { url: e.linkUrl } });
                    }}
                  />
                </Swiper.Item>
              );
            })}
          </Swiper>
        ) : null}
        {/* <img  src='/static/images/banner.jpg'/> */}
      </div>

      {props.loading ? (
        <div style={{ padding: 32 }}>
          <Loading size={48} vertical>
            加载中...
          </Loading>
        </div>
      ) : props.error ? (
        <div
          style={{ padding: 32 }}
          className="fx-box fx-jc-c fx-ai-c fx-fd-c"
          onClick={() => {
            reload(props.currentTab, "retry");
          }}
        >
          <img
            src="/static/images/exclamation.png"
            className="live-error-icon"
          />
          <div className="live-error-hint">网络不可用，点击重试</div>
        </div>
      ) : showLiveList&&props.currentTab===0 ? (
        <LiveListItem2 onClickItem={onClickItem} liveList={props.liveList} />
      ) : (
        <LiveListItem onClickItem={onClickItem} liveList={props.liveList} />
      )}

      {activityUrl ? (
        <img
          onClick={() => {
            navigate("/web", { state: { url: activityUrl  } });
          }}
          className="promotion-logo"
          src="/static/images/promotion.gif"
        ></img>
      ) : null}
      {showReturnTop ? (
        <img
          onClick={() => {
            document.getElementById("page").scrollTop = 0;
          }}
          className="return-top"
          src="/static/images/return-top.png"
        ></img>
      ) : null}
      {/* <LiveListItem 
                 onClickItem={onClickItem}
                 liveList={props.liveList}/> */}
      <MediaAuthDialog
        title="必须开启位置权限才能使用同城功能。请进入iOS设置功能，开启FuTrue地理位置权限。"
        visible={showLocationDialog}
        onClose={() => {
          setShowLocationDialog(false);
        }}
      />
    </div>
  );
};

export default connect(
  (state) => {
    return {
      loading: state.Live.loading,
      error: state.Live.error,
      liveList: state.Live.list,
      tabs: state.Live.tabs,
      banners: state.Live.banners,
      status: state.Live.status,
      currentTab: state.Live.currentTab,
      geo: state.geo,
      modelTotal: state.Live.modelTotal,
    };
  },
  (dispatch) => {
    return { dispatch };
  }
)(Live);
